import React, { useState, useEffect, useRef } from 'react';
import { useHistory } from "react-router-dom";
import { getToken_api, getToken_user } from '../../api/auth';
import DataGrid, { Column, Grouping, FilterRow, Label, GroupItem, Editing, Lookup, Sorting, Summary, SortByGroupSummaryInfo } from 'devextreme-react/data-grid';
import { networkConfig } from '../../networkConfig';
import Form, { Item, SimpleItem } from 'devextreme-react/form';
import { useParams } from 'react-router-dom'
import { confirm } from 'devextreme/ui/dialog';
import { CheckBox, NumberBox, TextArea, TextBox } from 'devextreme-react';
import { Button } from 'devextreme-react/button';
import { Popup, Position, ToolbarItem } from 'devextreme-react/popup';
import SignatureCanvas from 'react-signature-canvas';
import { alert } from "devextreme/ui/dialog" 
export default function () {
    let { moschein_id } = useParams()
    const [popup_unterschrift_visible, setpopup_unterschrift_visible] = useState(false)
    const [daten_wartung_vdma_details, setdaten_wartung_vdma_details] = useState('')
    const history = useHistory();
    var sigPad = {};
    var maengel = {};
    useEffect(() => {

        setdaten_wartung_vdma_details(JSON.parse(localStorage.getItem("wartung_vdma_details")))

    }, []);
    const status = ["in Ordnung", "nicht in Ordnung", "nicht zutreffend"]
    const speichern = function () {

        var moscheindaten = localStorage.getItem("moschein:" + moschein_id)
        moscheindaten = JSON.parse(moscheindaten);
        var wartung_vdma = JSON.parse(localStorage.getItem('wartung_vdma_allgemein'))
        wartung_vdma.unterschrift = sigPad.toDataURL("image/svg+xml");
        wartung_vdma.maengel = maengel.current.instance.option('value')
        wartung_vdma.wartung_vdma_details = JSON.parse(localStorage.getItem('wartung_vdma_details'))
        moscheindaten.wartung_vdma.push(wartung_vdma)
        localStorage.setItem("moschein:" + moschein_id, JSON.stringify(moscheindaten))
        localStorage.removeItem("wartung_vdma_details")
        localStorage.removeItem("wartung_vdma_grid")
        localStorage.removeItem("wartung_vdma_allgemein")
        history.push("/moschein/bearbeitenmobil/"+moschein_id);
    }


    const filiale_oeffnen = function (e) {
        //history.push("/settings/filialen/bearbeiten/" + e.row.data.ein_id);
    };


    return (

        <React.Fragment>


            <div className="dx-toolbar-items-container" style={{ height: "60px" }}>
                <div className="dx-toolbar-before">
                    <h2 className={'content-block'}>Wartung nach VDMA</h2>
                </div>
                <div className="dx-toolbar-after" style={{ "paddingTop": "20px", "paddingRight": "40px" }}>
                    <Button
                        text="Unterschreiben"
                        icon="sign"
                        onClick={function (e) {
                            //prüfen ob bei nicht in Ordnung auch bemerkung vorhanden ist
                            var fehlertext = '';
                            for (var i = 0; i < daten_wartung_vdma_details.length; i++) {
                                if (daten_wartung_vdma_details[i].zustand == 'nicht in Ordnung' && (daten_wartung_vdma_details[i].bemerkung == '' || daten_wartung_vdma_details[i].bemerkung == undefined)) {
                                    fehlertext = fehlertext + "</br>" + daten_wartung_vdma_details[i].position + " " + daten_wartung_vdma_details[i].bezeichnung
                                }
                            }
                            if (fehlertext != '') {
                                alert("Fehlende Bemerkung bei:" + fehlertext, "Fehlende Eingaben");
                                return
                            }
                            //Ende Pflichtüberprüfung
                            localStorage.setItem("wartung_vdma_details" , JSON.stringify(daten_wartung_vdma_details));

                            setpopup_unterschrift_visible(true)
                        }}
                    />
                </div>
            </div>


            <div className={'content-block dx-card responsive-paddings'}>

                <DataGrid
                    dataSource={daten_wartung_vdma_details}
                    showBorders={true}
                    rowAlternationEnabled={true}
                    scrolling={{ mode: "standard" }}
                    paging={{ enabled: false }}

                    wordWrapEnabled={true}


                >
                    <Grouping autoExpandAll={true} allowCollapsing={false} expandMode={"buttonClick"} />
                    <Summary>
                        <GroupItem
                            column="sortierung_app"
                            summaryType="avg"
                            name="minSort"
                            customizeText={function () { return "" }} />
                    </Summary>
                    <SortByGroupSummaryInfo summaryItem="minSort" sortOrder="asc" />
                    <Sorting mode="none" />
                    <Editing
                        mode="cell"
                        allowUpdating={true}
                    >
                    </Editing>
                    <Column
                        dataField="hauptkapitel"
                        caption="kat1"
                        allowEditing={false}
                        groupIndex={0}
                        groupCellRender={function (data) {
                            return data.value
                            //   var formattedValue =  data.value;
                            //  container.append(formattedValue);
                        }}

                    />
                    <Column
                        dataField="kapitel"
                        caption="Kapitel"
                        groupIndex={1}
                        groupCellRender={function (data) {
                            return data.value
                            //   var formattedValue =  data.value;
                            //  container.append(formattedValue);
                        }}
                        allowEditing={false}
                    />
                    <Column
                        dataField="position"
                        caption="Pos"
                        allowEditing={false}
                        width={"60"}
                    />
                    <Column
                        dataField="bezeichnung"
                        caption="Tätigkeit"
                        allowEditing={false}
                    />
                    <Column
                        dataField="periodisch"
                        caption="Periodisch"
                        dataType="boolean"
                        allowEditing={false}
                        width={"30"}
                    />
                    <Column
                        dataField="bedarf"
                        caption="Bedarf"
                        dataType="boolean"
                        allowEditing={false}
                        width={"30"}
                    />
                    <Column
                        dataField="zustand"
                        caption="Ergebnis"

                        width={"150"}
                    >
                        <Lookup dataSource={status} />
                    </Column>
                    <Column
                        dataField="messwerte"
                        caption="Messwerte"
                        width={"200"}
                        cellRender={function (options) {
                            if (options.data.einheit != '') {
                                const numbers = [];
                                for (var i = 1; i <= options.data.anzahl; i++) {

                                    if (options.data["name" + i] == undefined || options.data["name" + i] == "") {
                                        options.data["name" + i] = "Messwert " + i
                                    }
                                    if (options.data["messwerte" + i] == undefined) {
                                        options.data["messwerte" + i] = ""
                                    }
                                    numbers.push(i)

                                }
                                const ergebnis = numbers.map((i) => <div>{options.data["name" + i]}:  {options.data["messwerte" + i]}  {options.data.einheit} {"\n"}</div>);
                                return ergebnis
                            }

                        }}
                        editCellRender={function (options) {
                            if (options.data.einheit != '') {
                                const numbers = [];
                                var editor = []
                                for (var i = 1; i <= options.data.anzahl; i++) {
                                    numbers.push(i)
                                    if (options.data["name" + i] == undefined || options.data["name" + i] == "") {
                                        options.data["name" + i] = "Messwert " + i
                                    }
                                    if (options.data["messwerte" + i] == undefined) {
                                        options.data["messwerte" + i] = ""
                                    }


                                }
                                const ergebnis = numbers.map((i) => {
                                    return <div>{options.data["name" + i]}: <TextBox
                                        defaultValue={options.data["messwerte" + i]}
                                        placeholder={"in " + options.data.einheit}
                                        onValueChanged={function (data) {
                                            if (data.event != undefined) {
                                                options.data["messwerte" + i] = data.value;
                                            }
                                        }
                                        }
                                    >
                                    </TextBox>
                                    </div>
                                });
                                console.log(ergebnis)
                                return ergebnis




                            }
                        }}
                    />
                    <Column
                        dataField="bemerkung"
                        caption="Bemerkung"
                        width={"150"}
                        editCellRender={function (options) {
                            var edit = false;
                            if (options.data.gesperrt == true) {
                                edit = true
                            }
                            return <TextBox
                                defaultValue={options.data.bemerkung}
                                disabled={edit}
                                onValueChanged={function (data) {
                                    if (data.event != undefined) {
                                        options.data.bemerkung = data.value;
                                    }
                                }
                                }
                            >
                            </TextBox>
                        }}
                    />






                </DataGrid>
            </div>
            <Popup
                visible={popup_unterschrift_visible}
                onHiding={function (e) { setpopup_unterschrift_visible(false) }}
                dragEnabled={false}
                closeOnOutsideClick={true}
                showCloseButton={true}
                showTitle={false}
                title="Unterschrift"
                width={1060}
                height={700}
            >
                <Position
                    at="center"
                    my="center"
                />
                <ToolbarItem
                    widget="dxButton"
                    toolbar="bottom"
                    location="after"
                    options={{
                        text: 'Speichern',
                        disabled: false,
                        onClick: function (e) {

                            speichern()
                            setpopup_unterschrift_visible(false)

                        }
                    }}
                />
                <table style={{ marginBottom: "30px" }} width="100%">
                    <colgroup> <col width="80%" /> <col width="20%" /> </colgroup>
                    <tr>
                        <th><TextArea ref={maengel} placeholder="Folgende Mängel sind noch zu beheben" /></th>
                        <th> <Button
                            text="Löschen"
                            icon="clear"
                            onClick={function (e) {
                                maengel.current.instance.option('value', '')
                                sigPad.clear();
                            }}

                        /></th>
                    </tr>
                </table>
                <SignatureCanvas penColor='blue' backgroundColor='rgba(244, 244, 244)' canvasProps={{ width: 1000, height: 400, className: 'sigCanvas' }} ref={(ref) => { sigPad = ref }} />
            </Popup>
        </React.Fragment>
    );


};
