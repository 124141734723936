export const msalConfig = {
  auth: {
   clientId: "65fa9d5e-bbb1-4f7d-b314-f8ecf5b3f8bc",
authority: "https://login.microsoftonline.com/csdresden.de",
//redirectUri: "http://localhost:3000",
     redirectUri: "https://eisbaer.csdresden.de",

 //      clientId: "9eb1a2df-5b98-471b-8657-e794f048545c",
 //authority: "https://login.microsoftonline.com/dussmann.de",
 // redirectUri: "https://eisbaer20-test.dussmann.com"


  },
  cache: {
    cacheLocation: "localStorage", // This configures where your cache will be stored
    storeAuthStateInCookie: false, // Set this to "true" if you are having issues on IE11 or Edge
  }
};
export const options_api = {
  authorities: [
    {
      type: 'AAD',
      audience: 'AzureADMyOrg',
      authorityUrl: "https://login.microsoftonline.com/csdresden.de",
  // authorityUrl: "https://login.microsoftonline.com/dussmann.de",
      default: true
    }
  ],
 scopes: ['api://1b2bc6d4-32c7-478d-97e1-7e4a8b8f172e/access_as_user'],
// scopes: ['api://3507b2f5-9553-4dde-9fc3-5d8f815ad891/access_as_user'],
  accountMode: "SINGLE"
}
export const options = {
  authorities: [
    {
      type: 'AAD',
      audience: 'AzureADMyOrg',
      authorityUrl: "https://login.microsoftonline.com/csdresden.de",
    //  authorityUrl: "https://login.microsoftonline.com/dussmann.de",
      default: true
    }
  ],
  scopes: ['User.Read'],
  accountMode: "SINGLE"
}
// Add scopes here for ID token to be used at Microsoft identity platform endpoints.
export const loginRequest_api = {
  scopes: ['api://1b2bc6d4-32c7-478d-97e1-7e4a8b8f172e/access_as_user']
// scopes: ['api://3507b2f5-9553-4dde-9fc3-5d8f815ad891/access_as_user']
};
export const loginRequest_user = {
  scopes: ['User.Read']
};

// Add the endpoints here for Microsoft Graph API services you'd like to use.
export const graphConfig = {
  graphMeEndpoint: "https://graph.microsoft.com/v1.0/me",
  graphUsersEndpoint: "https://graph.microsoft.com/v1.0/users"
};