import React, { useState, useEffect } from 'react';
import { getToken_api } from '../../api/auth';
import Form, { Item, TabbedItem, TabPanelOptions, Tab, Label, GroupItem } from 'devextreme-react/form';
import ODataStore from 'devextreme/data/odata/store';
import DataGrid, { Column, Editing, Lookup } from 'devextreme-react/data-grid';
import { useParams } from 'react-router-dom'
import Guid from 'devextreme/core/guid';
import { networkConfig } from '../../networkConfig';
import { SpeedDialAction } from 'devextreme-react/speed-dial-action';
import config from 'devextreme/core/config';
export default function ({ vertrag_id, admin }) {

    const [token, setToken] = useState('')
    const [formData, setformData] = useState('')
    const [vertrag_select_options, setvertrag_select_options] = useState('')
    const [debitoren_dataSource, setdebitoren_dataSource] = useState('')
    const [material_dataSource, setmaterial_dataSource] = useState('')
    const [zuordnung_dataSource, setzuordnung_dataSource] = useState('')
    const [anfahrt_dataSource, setanfahrt_dataSource] = useState('')
    const [deb_select_options, setdeb_select_options] = useState('')
    const [isAdmin, setisAdmin] = useState(false)


    config({
        floatingActionButtonConfig: {
            icon: 'rowfield',
            shading: true,
            direction: 'down',
            position: {
            // of: '#form',
            my: 'right top',
            at: 'right top',
            offset: '-50 110'
            }
        }
    });
    const speichern = function () {

        const dataSource = new ODataStore({
            type: 'odata',
            url: networkConfig.dataEndpoint + 'vertrag',
            key: 'vertrag_id',
            withCredentials: true,
            beforeSend: function (e) {
                e.headers = {
                    "Authorization": 'Bearer ' + token
                };
            }
        });
        dataSource.update(new Guid(vertrag_id), formData)

    }


    useEffect(() => {

        if (localStorage.getItem("roles").indexOf("admin;") != -1 && admin == true) {
            setisAdmin(true)
        }
        getToken_api().then(token => {
            setToken(token)

            const dataSource = new ODataStore({
                type: 'odata',
                url: networkConfig.dataEndpoint + 'vertrag',
                key: 'vertrag_id',
                withCredentials: true,
                beforeSend: function (e) {

                    e.headers = {
                        "Authorization": 'Bearer ' + token
                    };
                }
            });
            dataSource.load({ filter: ['vertrag_id', '=', new Guid(vertrag_id)] })
                .then(
                    (data) => { setformData(data[0]); },
                    (error) => { /* Handle the "error" here */ }
                );

            const store = new ODataStore({
                type: 'odata',
                url: networkConfig.dataEndpoint + 'vertrag',
                key: 'vertrag_id',
                withCredentials: true,
                beforeSend: function (e) {
                    e.headers = {
                        "Authorization": 'Bearer ' + token
                    };
                },
            });

            setvertrag_select_options({ dataSource: { store }, displayExpr: "vertrag_nr", valueExpr: "vertrag_id", searchEnabled: true, showClearButton: true })

            const store2 = new ODataStore({
                type: 'odata',
                url: networkConfig.dataEndpoint + 'debitor',
                key: 'deb_id',
                withCredentials: true,
                beforeSend: function (e) {
                    e.headers = {
                        "Authorization": 'Bearer ' + token
                    };
                },
            });

            setdeb_select_options({
                dataSource: { store: store2 }, displayExpr: function (item) {
                    // "item" can be null
                    return item && item.nr + ' (' + item.name + ')';
                }, valueExpr: "deb_id", searchEnabled: true, showClearButton: true
            })

            setmaterial_dataSource({
                store: {
                    type: 'odata',
                    url: networkConfig.dataEndpoint + 'vertrag_material',
                    key: 'vertrag_material_id',
                    withCredentials: true,
                    beforeSend: function (e) {
                        e.headers = {
                            "Authorization": 'Bearer ' + token
                        };
                    }
                },
                filter: ['vertrag_id', '=', new Guid(vertrag_id)]
            })
            setanfahrt_dataSource({
                store: {
                    type: 'odata',
                    url: networkConfig.dataEndpoint + 'vertrag_anfahrtspauschale',
                    key: 'vertrag_anfahrtspauschale_id',
                    withCredentials: true,
                    beforeSend: function (e) {
                        e.headers = {
                            "Authorization": 'Bearer ' + token
                        };
                    }
                },
                filter: ['vertrag_id', '=', new Guid(vertrag_id)]
            })
            setzuordnung_dataSource({
                store: {
                    type: 'odata',
                    url: networkConfig.dataEndpoint + 'vertrag_zuordnung',
                    key: 'vertrag_zuordnung_id',
                    withCredentials: true,
                    beforeSend: function (e) {
                        e.headers = {
                            "Authorization": 'Bearer ' + token
                        };
                    }
                },
                filter: ['vertrag_id', '=', new Guid(vertrag_id)]
            })
            setdebitoren_dataSource({
                store: {
                    type: 'odata',
                    url: networkConfig.dataEndpoint + 'debitor',
                    key: 'deb_id',
                    withCredentials: true,
                    beforeSend: function (e) {
                        e.headers = {
                            "Authorization": 'Bearer ' + token
                        };
                    }
                }

            })

        })
        
    }, [vertrag_id]);

    return (

        <React.Fragment>

            <Form
                id={'form'}
                formData={formData}
                labelLocation={'top'}
                colCount={1}
                readOnly={isAdmin?false:true}
            >

                <TabbedItem>
                    <TabPanelOptions deferRendering={false} />
                    <Tab title="Allgemein" colCount={2}>


                        <GroupItem caption="" colCount={3} colSpan={2}>
                            <Item dataField="vertrag_nr" colSpan={3} editorOptions={{}}>
                                <Label text="Nummer" />
                            </Item>
                            <Item dataField="vertrag_parent_id" colSpan={1} editorType="dxSelectBox" editorOptions={vertrag_select_options}>
                                <Label text="Hauptvertrag" />
                            </Item>
                            <Item dataField="kunde_id" colSpan={3} editorType="dxSelectBox" editorOptions={deb_select_options}>
                                <Label text="Debitor" />
                            </Item>
                            <Item dataField="svs" editorType="dxNumberBox" editorOptions={{}}>
                                <Label text="SVS" />
                            </Item>
                            <Item dataField="anfahrtszone" editorType="dxSelectBox" editorOptions={{showClearButton:true, items: ['1', '2', '3', '4', '5', '6'] }}>
                                <Label text="Anfahrtszone" />
                            </Item>
                            <Item dataField="km" editorType="dxNumberBox" editorOptions={{}}>
                                <Label text="km-Satz" />
                            </Item>
                            <Item dataField="sla" colSpan={3} editorOptions={{}}>
                                <Label text="SLA" />
                            </Item>
                            <Item dataField="vertrag_info" colSpan={2} editorOptions={{}}>
                                <Label text="Vertrag-Info" />
                            </Item>
                            <Item dataField="materal_preis_relativ" editorType="dxNumberBox" colSpan={1} editorOptions={{}}>
                                <Label text="Material-Rabatt (%)" />
                            </Item>
                            <Item dataField="rabatt_relativ"  editorType="dxNumberBox" colSpan={1} editorOptions={{}}>
                                <Label text="Gesamtrabatt (%)" />
                            </Item>
                            <Item dataField="rechnungslegung" colSpan={1} editorType="dxSelectBox" editorOptions={{ showClearButton: true, items: ['sofort', 'manuell'] }}>
                                <Label text="Rechnungslegung" />
                            </Item>
                            <Item dataField="zentralfaktura" colSpan={1} editorType="dxCheckBox" editorOptions={{}}>
                                <Label text="Zentralfaktura" />
                            </Item>


                        </GroupItem>
                    </Tab>
                    <Tab title="Material" colCount={1}>


                        <Item>
                            <DataGrid

                                dataSource={material_dataSource}
                                showBorders={true}
                                onInitNewRow={function (e) {
                                    e.data.vertrag_id = vertrag_id
                                }}
                               
                            >

                                <Editing
                                    mode="row"
                                    allowUpdating={isAdmin}
                                    allowDeleting={isAdmin}
                                    allowAdding={isAdmin}
                                >

                                </Editing>

                                <Column
                                    dataField="material_nr"
                                    caption="MaterialNr"
                                />
                                <Column
                                    dataField="preis"
                                    caption="Preis"
                                />
                                <Column
                                    dataField="rabatt_relativ"
                                    caption="Rabatt(%)"
                                />
                                <Column
                                    dataField="rabatt_absolut"
                                    caption="Rabatt(€)"
                                />

                                <Column
                                    dataField="hinweis"
                                    caption="Hinweis"
                                />
                            </DataGrid>
                        </Item>

                    </Tab>
                    <Tab title="Anfahrtspauschalen" colCount={1}>

                        <Item>
                            <DataGrid

                                dataSource={anfahrt_dataSource}
                                showBorders={true}
                                onInitNewRow={function (e) {
                                    e.data.vertrag_id = vertrag_id
                                }}
                            >

                                <Editing
                                    mode="row"
                                    allowUpdating={isAdmin}
                                    allowDeleting={isAdmin}
                                    allowAdding={isAdmin}
                                >

                                </Editing>

                                <Column
                                    dataField="betrag"
                                    caption="Betrag (€)"
                                />
                                <Column
                                    dataField="km"
                                    caption="ab km"
                                />
                                <Column
                                    dataField="bemerkung"
                                    caption="Bemerkung"
                                />

                            </DataGrid>
                        </Item>

                    </Tab>
                    <Tab title="verknüpfte Debitoren">
                        <DataGrid

                            dataSource={zuordnung_dataSource}
                            showBorders={true}
                            onInitNewRow={function (e) {
                                e.data.vertrag_id = vertrag_id
                                e.data.kunde_typ = 'debitor'
                            }}
                        >

                            <Editing
                                mode="row"
                                allowUpdating={isAdmin}
                                allowDeleting={isAdmin}
                                allowAdding={isAdmin}
                            >

                            </Editing>

                            <Column
                                dataField="kunde_id"
                                caption="Debitor"
                            >
                                <Lookup dataSource={debitoren_dataSource} displayExpr="nr" valueExpr="deb_id" />
                            </Column>


                        </DataGrid>
                    </Tab>
                </TabbedItem>
            </Form>
            <SpeedDialAction
                icon="save"
                label="Speichern"
                index={1}
                visible={isAdmin}
                onClick={speichern} />
        </React.Fragment>
    );
};
