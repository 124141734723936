import React, { useState, useEffect, useRef } from 'react';
import { useHistory } from "react-router-dom";
import { getToken_api, getToken_user } from '../../api/auth';
import DataGrid, { Column, Grouping, Sorting, Summary, TotalItem, Editing, } from 'devextreme-react/data-grid';
import { networkConfig } from '../../networkConfig';
import Form, { Item, GroupItem, Label, SimpleItem } from 'devextreme-react/form';
import { useParams } from 'react-router-dom'
import { confirm } from 'devextreme/ui/dialog';
import { CheckBox, NumberBox, TextArea, TextBox } from 'devextreme-react';
import { Button } from 'devextreme-react/button';
import { Popup, Position, ToolbarItem } from 'devextreme-react/popup';
import SignatureCanvas from 'react-signature-canvas';
import { alert } from "devextreme/ui/dialog"
export default function () {
    let { moschein_id } = useParams()
    const [popup_unterschrift_visible, setpopup_unterschrift_visible] = useState(false)
    const [formData, setformData] = useState({})
    const [formData_details, setformData_details] = useState([])
    const [moscheindaten, setmoscheindaten] = useState({})

    const history = useHistory();
    var sigPad = {};
    var maengel = {};
    useEffect(() => {
        var moschein = JSON.parse(localStorage.getItem("moschein:" + moschein_id))
        if (localStorage.getItem("gaswarn") == undefined) {

            setformData({
                filiale: moschein.moschein.kunden_name, kunde: moschein.moschein.kundennummer, strasse: moschein.moschein.kunden_strasse, plz: moschein.moschein.kunden_plz, ort: moschein.moschein.kunden_ort, datum: new Date(), aktuelleWartung: new Date(), auftragsnummer: moschein.moschein.moschein_nr
            })


        } else {
            setformData(JSON.parse(localStorage.getItem("gaswarn")))
            setformData_details(JSON.parse(localStorage.getItem("gaswarn_detail")))
        }



    }, []);



    const speichern = function () {

        var moscheindaten = localStorage.getItem("moschein:" + moschein_id)
        moscheindaten = JSON.parse(moscheindaten);
        var data = formData
        data.unterschrift_monteur = sigPad.toDataURL("image/svg+xml")
        data.gaswarn_detail = formData_details
        data.datum = new Date()
        moscheindaten.gaswarn.push(data)
        console.log(formData_details)
        console.log(data)
        localStorage.setItem("moschein:" + moschein_id, JSON.stringify(moscheindaten))
        localStorage.removeItem("gaswarn")
        localStorage.removeItem("gaswarn_detail")
        history.push("/moschein/bearbeitenmobil/" + moschein_id);
    }

    const form = useRef(null);

    return (

        <React.Fragment>


            <div className="dx-toolbar-items-container" style={{ height: "60px" }}>
                <div className="dx-toolbar-before">
                    <h2 className={'content-block'}>gaswarn</h2>
                </div>
                <div className="dx-toolbar-after" style={{ "paddingTop": "20px", "paddingRight": "40px" }}>
                    <Button
                        text="Unterschreiben"
                        icon="sign"
                        onClick={function (e) {
                            //prüfen ob bei nicht in Ordnung auch bemerkung vorhanden ist
                            if (formData.instandsetzungen == true && (formData.instandsetzungen_beschreibung == "" ||formData.instandsetzungen_beschreibung == undefined)) {
                                alert('Notwendige Instandsetzungen wurden nicht benannt', 'Wert benötigt');
                                return
                            }
                            if (formData.sicherheitarbeiten == true && (formData.sicherheitarbeiten_beschreibung == "" ||formData.sicherheitarbeiten_beschreibung == undefined)) {
                                alert('Notwendige Arbeiten wurden nicht benannt', 'Wert benötigt');
                                return
                            }

                            if ((formData.instandsetzungen == false && formData.sicherheitarbeiten == false && formData.ohnemaengel == false) || formData.ohnemaengel == null) {
                                alert('Zustand der Anlage nicht angegeben', 'Wert benötigt');
                                return
                            }
                            if (formData.aktuelleWartung == null) {
                                alert('Datum für aktuelle Wartung nicht angegeben', 'Wert benötigt');
                                return
                            }
                            if (formData.naechsteWartung == null) {
                                alert('Datum für nächste Wartung nicht angegeben', 'Wert benötigt');
                                return
                            }

                            setpopup_unterschrift_visible(true)
                        }}
                    />
                </div>
            </div>


            <div className={'content-block dx-card responsive-paddings'}>
                <Form
                    id={'form'}
                    formData={formData}
                    labelLocation={'left'}
                    colCount={3}
                    ref={form}
                    alignItemLabelsInAllGroups={false}
                >

                    <GroupItem caption="Angaben zum Kunden" colCount={3} colSpan={3}>
                        <Item dataField="filiale" editorOptions={{}}>
                            <Label text="Kunde/Filiale" />
                        </Item>
                        <Item dataField="kunde" colSpan={2} editorOptions={{}}>
                            <Label text="Kundennummer" />
                        </Item>
                        <Item dataField="strasse" editorOptions={{}}>
                            <Label text="Straße" />
                        </Item>
                        <Item dataField="plz" editorOptions={{}}>
                            <Label text="PLZ" />
                        </Item>
                        <Item dataField="ort" editorOptions={{}}>
                            <Label text="Ort" />
                        </Item>
                        <Item dataField="auftragsnummer" editorOptions={{}}>
                            <Label text="Aufrragsnummer" />
                        </Item>
                        <Item dataField="anlage" editorOptions={{}}>
                            <Label text="Anlage/Gerät" />
                        </Item>
                        <Item dataField="gaswarngeraet" editorOptions={{}}>
                            <Label text="Gaswarngerät" />
                        </Item>
                        <Item dataField="messfuehler" editorOptions={{}}>
                            <Label text="Messfühler" />
                        </Item>
                    </GroupItem>

                    <GroupItem caption="Optischer Signalgeber" colCount={12} colSpan={3}>
                        <Item dataField="optisch_alarm" editorType="dxCheckBox" editorOptions={{}}>
                            <Label text="Alarm" />
                        </Item>
                        <Item dataField="optisch_einbauort" colSpan={5} editorOptions={{}}>
                            <Label text="Einbauort" />
                        </Item>
                        <Item dataField="optisch_funktion" editorType="dxCheckBox" editorOptions={{}}>
                            <Label text="Funktion" />
                        </Item>
                        <Item dataField="optisch_bezeichnung" colSpan={5} editorOptions={{}}>
                            <Label text="Bezeichnung/Bemerkung" />
                        </Item>
                    </GroupItem>

                    <GroupItem caption="Akustischer Signalgeber" colCount={12} colSpan={3}>
                        <Item dataField="akustisch_alarm" editorType="dxCheckBox" editorOptions={{}}>
                            <Label text="Alarm" />
                        </Item>
                        <Item dataField="akustisch_einbauort" colSpan={5} editorOptions={{}}>
                            <Label text="Einbauort" />
                        </Item>
                        <Item dataField="akustisch_funktion" editorType="dxCheckBox" editorOptions={{}}>
                            <Label text="Funktion" />
                        </Item>
                        <Item dataField="akustisch_bezeichnung" colSpan={5} editorOptions={{}}>
                            <Label text="Bezeichnung/Bemerkung" />
                        </Item>
                    </GroupItem>

                    <GroupItem caption="Stellglied (Ventil)" colCount={12} colSpan={3}>
                        <Item dataField="stellglied_alarm" editorType="dxCheckBox" editorOptions={{}}>
                            <Label text="Alarm" />
                        </Item>
                        <Item dataField="stellglied_einbauort" colSpan={5} editorOptions={{}}>
                            <Label text="Einbauort" />
                        </Item>
                        <Item dataField="stellglied_funktion" editorType="dxCheckBox" editorOptions={{}}>
                            <Label text="Funktion" />
                        </Item>
                        <Item dataField="stellglied_bezeichnung" colSpan={5} editorOptions={{}}>
                            <Label text="Bezeichnung/Bemerkung" />
                        </Item>
                    </GroupItem>
                    <GroupItem caption="Sonstiges" colCount={12} colSpan={3}>
                        <Item dataField="sonstiges_alarm" editorType="dxCheckBox" editorOptions={{}}>
                            <Label text="Alarm" />
                        </Item>
                        <Item dataField="sonstiges_einbauort" colSpan={5} editorOptions={{}}>
                            <Label text="Einbauort" />
                        </Item>
                        <Item dataField="sonstiges_funktion" editorType="dxCheckBox" editorOptions={{}}>
                            <Label text="Funktion" />
                        </Item>
                        <Item dataField="sonstiges_bezeichnung" colSpan={5} editorOptions={{}}>
                            <Label text="Bezeichnung/Bemerkung" />
                        </Item>
                    </GroupItem>

                    <GroupItem caption="Notstrom / AH / Akku getauscht" colCount={11} colSpan={3}>

                        <Item dataField="notstrom_einbauort" colSpan={5} editorOptions={{}}>
                            <Label text="Einbauort" />
                        </Item>
                        <Item dataField="notstrom_funktion" editorType="dxCheckBox" editorOptions={{}}>
                            <Label text="Funktion" />
                        </Item>
                        <Item dataField="notstrom_bezeichnung" colSpan={5} editorOptions={{}}>
                            <Label text="Bezeichnung/Bemerkung" />
                        </Item>
                    </GroupItem>
                    <GroupItem caption="Bestätigungselement / LED" colSpan={1}>
                        <Item dataField="bestaetigungselement_funktion" editorType="dxCheckBox" editorOptions={{}}>
                            <Label text="Funktion" />
                        </Item>
                    </GroupItem>
                    <GroupItem caption="Elektr. Verbindungen" colSpan={1}>
                        <Item dataField="elektrVerbindung_funktion" editorType="dxCheckBox" editorOptions={{}}>
                            <Label text="Funktion" />
                        </Item>
                    </GroupItem>
                    <GroupItem caption="DK-Fehlerkreis / Error / Relais" colSpan={1}>
                        <Item dataField="dkFehlerkreis_funktion" editorType="dxCheckBox" editorOptions={{}}>
                            <Label text="Funktion" />
                        </Item>
                    </GroupItem>
                    <GroupItem caption="Gesamtzustand / Baujahr" colSpan={3}>
                        <Item dataField="gesamtzustand_bezeichnung" editorOptions={{}}>
                            <Label text="Bemerkung" />
                        </Item>
                    </GroupItem>

                    <GroupItem caption="MF"colSpan={3} >

                        <DataGrid

                            dataSource={formData_details}
                            showBorders={true}
                            onInitNewRow={function (info) {
                                info.data.test = false;
                                info.data.position = info.component.getVisibleRows().length + 1;
                            }}
                        >

                            <Editing
                                mode="row"
                                allowUpdating={false}
                                allowDeleting={false}
                                allowAdding={true}
                            >

                            </Editing>

                            <Column
                                dataField="position"
                                caption="MF"
                                dataType="number"
                                disabled={true}
                                format="decimal fixedPoint"
                            />
                            <Column
                                dataField="Alarm1"
                                headerCellRender={function (e) {
                                    return (<div>Alarm_1/<br />Kalibrierung/<br />Justierung/<br />pH-Grenzwert</div>)
                                }}
                            />
                            <Column
                                dataField="Alarm2"
                                headerCellRender={function (e) {
                                    return (<div>Alarm_2/<br />Kalibrierung/<br />Justierung/<br />pH-Grenzwert</div>)
                                }}
                            />
                            <Column
                                dataField="Alarm3"
                                headerCellRender={function (e) {
                                    return (<div>Alarm_33/<br />Kalibrierung/<br />Justierung/<br />pH-Grenzwert</div>)
                                }}
                            />
                            <Column
                                dataField="Alarm4"
                                headerCellRender={function (e) {
                                    return (<div>Alarm_4/<br />Kalibrierung/<br />Justierung/<br />pH-Grenzwert</div>)
                                }}
                            />
                            <Column
                                dataField="Messbereich"
                                caption="Messbereich"
                            />
                            <Column
                                dataField="Pruefgas"
                                caption="Prüfgas"
                            />
                            <Column
                                dataField="sensortyp"
                                caption="Sensortyp"
                            />
                            <Column
                                dataField="test"
                                editorType="dxCheckBox"
                                caption="Sensor getestet"
                            />

                        </DataGrid>

                    </GroupItem>


                    <GroupItem caption="keine Mängel" colSpan={3} >

                        <Item dataField="ohnemaengel" editorType="dxCheckBox" editorOptions={{
                            onValueChanged: function (e) {
                                if (e.value == true) {
                                    setformData({ ...formData, sicherheitarbeiten: false, instandsetzungen: false, sicherheitarbeiten_beschreibung: "", instandsetzungen_beschreibung: "" })
                                }
                            }
                        }}>
                            <Label text="ohne sicherheitsrelevante Mängel" />
                        </Item>
                    </GroupItem>
                    <GroupItem caption="eingeschränkt funktionsfähig" colSpan={3} >

                        <Item dataField="sicherheitarbeiten" editorType="dxCheckBox" editorOptions={{
                            onValueChanged: function (e) {
                                if (e.value == true) {
                                    setformData({ ...formData, ohnemaengel: false, instandsetzungen: false, instandsetzungen_beschreibung: "" })
                                }
                            }
                        }}>
                            <Label text="Funktionsfähig, aber Arbeiten sind auszuführen" />
                        </Item>
                        <Item dataField="sicherheitarbeiten_beschreibung" editorType="dxTextArea" editorOptions={{
                            "autoResizeEnabled": true

                        }}>
                            <Label text="notwendige Arbeiten" />
                        </Item>
                    </GroupItem>

                    <GroupItem caption="nicht funktionsfähig" colSpan={3} >

                        <Item dataField="instandsetzungen" editorType="dxCheckBox" editorOptions={{
                            onValueChanged: function (e) {
                                if (e.value == true) {
                                    setformData({ ...formData, ohnemaengel: false, sicherheitarbeiten: false, sicherheitarbeiten_beschreibung: "" })
                                }
                            }
                        }}>
                            <Label text="Instandsetzungen sind durchzuführen" />
                        </Item>
                        <Item dataField="instandsetzungen_beschreibung" editorType="dxTextArea" editorOptions={{
                            "autoResizeEnabled": true

                        }}>
                            <Label text="notwendige Instandsetzungen" />
                        </Item>
                    </GroupItem>
                    <GroupItem caption="Wartung" colSpan={3} colCount={3}>

                    <Item dataField="letzteWartung" editorType="dxDateBox" editorOptions={{
                             format: "date",

                             displayFormat: 'monthAndYear',
                             maxZoomLevel: 'year',
                             minZoomLevel: 'century'
                        }}>
                            <Label text="letzte Wartung" />
                        </Item>
                        
                        <Item dataField="aktuelleWartung" editorType="dxDateBox" editorOptions={{
                             format: "date",

                             displayFormat: 'monthAndYear',
                             maxZoomLevel: 'year',
                             minZoomLevel: 'century'
                        }}>
                            <Label text="aktuelle Wartung" />
                        </Item>
                        <Item dataField="naechsteWartung" editorType="dxDateBox" editorOptions={{
                             format: "date",

                             displayFormat: 'monthAndYear',
                             maxZoomLevel: 'year',
                             minZoomLevel: 'century'
                        }}>
                            <Label text="nächste Wartung" />
                        </Item>
                     
                    </GroupItem>
                </Form>
            </div>
            <Popup
                visible={popup_unterschrift_visible}
                onHiding={function (e) { setpopup_unterschrift_visible(false) }}
                dragEnabled={false}
                closeOnOutsideClick={true}
                showCloseButton={true}
                showTitle={false}
                title="Unterschrift"
                width={1060}
                height={700}
            >
                <Position
                    at="center"
                    my="center"
                />
                <ToolbarItem
                    widget="dxButton"
                    toolbar="bottom"
                    location="after"
                    options={{
                        text: 'Speichern',
                        disabled: false,
                        onClick: function (e) {

                            speichern()
                            setpopup_unterschrift_visible(false)

                        }
                    }}
                />
                <table style={{ marginBottom: "30px" }} width="100%">
                    <tbody>

                        <tr>
                            <th></th>
                            <th> <Button
                                text="Löschen"
                                icon="clear"
                                onClick={function (e) {

                                    sigPad.clear();
                                }}

                            /></th>
                        </tr>
                    </tbody>
                </table>
                <SignatureCanvas penColor='blue' backgroundColor='rgba(244, 244, 244)' canvasProps={{ width: 1000, height: 400, className: 'sigCanvas' }} ref={(ref) => { sigPad = ref }} />
            </Popup>
        </React.Fragment>
    );


};
