import React, { useState, useEffect } from 'react';
import PublicClientApplication from 'react-native-msal';
import {msalConfig} from '../../authConfig';
import { useAuth } from '../../contexts/auth';
export default function () {

  const { user, signOut } = useAuth();
  useEffect(() => {

    async function logOut() {
        const pca = new PublicClientApplication(msalConfig);
        const accounts = await pca.getAccounts();
        signOut(accounts[0])
    
      };
    logOut();
    
    
  }, []);

    

  return (

    <React.Fragment>
    
    </React.Fragment>
  );


};
