import React, { useState, useEffect } from 'react';
import { useHistory } from "react-router-dom";
import { getToken_api ,getToken_user } from '../../api/auth';
import DataGrid, { Column, Button, Grouping, FilterRow, SearchPanel, HeaderFilter, Editing,  Form, Lookup, Sorting  } from 'devextreme-react/data-grid';
import { networkConfig } from '../../networkConfig';
import { Item, SimpleItem } from 'devextreme-react/form';
export default function () {

  const [token, setToken] = useState('')
  const [dataSource, setdataSource] = useState('')

  useEffect(() => {

    getToken_api().then(token => {
      setdataSource({
        store: {
          type: 'odata',
          url: networkConfig.dataEndpoint + 'stoercode_liste',
          key: 'stoercode_liste_id',
          withCredentials: true,
          beforeSend: function (e) {
            e.headers = {
              "Authorization": 'Bearer ' + token
            };
          },
         
        },
      })

      
    })
    
  }, []);

    

  return (

    <React.Fragment>
      <h2 className={'content-block'}>Störcode-Vorlagenliste</h2>
      <DataGrid
       
        dataSource={dataSource}
        showBorders={true}
      >
           <Grouping autoExpandAll={false} />
           <Sorting mode="multiple" />
        <Editing
        mode="row"
        allowUpdating={true}
        allowDeleting={true}
        allowAdding={true}
      >
          
        </Editing>
        <FilterRow visible={true}
          applyFilter="auto" />
        <HeaderFilter visible={false} />
        <SearchPanel visible={true}
          width={240}
          placeholder="Suchen..." />
         <Column
        dataField="kundennummer"
        caption="Kundenummer"
        defaultSortOrder="asc"
      />
               <Column
        dataField="bezeichnung"
        caption="Bezeichnung"
      />
                     <Column
        dataField="stoercodes"
        caption="Störcodes (;)"
      />
                    
                    
        
        
      </DataGrid>
    </React.Fragment>
  );


};
