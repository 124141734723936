import React, { useState, useEffect, useRef } from 'react';
import { useHistory } from "react-router-dom";
import { getToken_api } from '../../api/auth';
import DataGrid, { Column, Button, Summary, TotalItem, FilterRow, SearchPanel, HeaderFilter, Editing, Form, Lookup, Sorting } from 'devextreme-react/data-grid';
import { networkConfig } from '../../networkConfig';
import { Item, SimpleItem } from 'devextreme-react/form';
import { useParams } from 'react-router-dom'
import { isMobile } from "react-device-detect";
export default function () {
let { typ } = useParams();
  const [token, setToken] = useState('')
  const [dataSource, setdataSource] = useState('')
  const [dataSource_Buero, setdataSource_Buero] = useState('')
  const [dataSource_auftrag, setdataSource_auftrag] = useState('')
  const history = useHistory();
  const inputRef = useRef();
  var text = "";
  if (typ == "abschluss"){
text = "abzuschließende MoScheine"
  }else{
    text = "freizugebende MoScheine"
  }
  useEffect(() => {
    console.log('list')
    getToken_api().then(token => {
       
            setdataSource({
                store: {
                  type: 'odata',
                  url: networkConfig.dataEndpoint + 'molohn_view',
                  key: 'moschein_id',
                  withCredentials: true,
                  beforeSend: function (e) {
                    e.headers = {
                      "Authorization": 'Bearer ' + token
                    };
                  },
                  onInserted: function(values, key){
                   // history.push("/settings/debitoren/bearbeiten/" + values.deb_id);
                  }
        
                }
              })
        
     
     

    })

  }, [typ]);

  const moschein_oeffnen = function (e) {

    if (isMobile) {
        //history.push("/pdf/GetAW/" + e.row.data.dokumentenname.toString());
    } else {
        getToken_api().then(token => {
            setToken(token)
            const url = networkConfig.serverEndpoint + "api/moschein/GetMoschein?file=" +  e.row.data.moschein_nr.toString()
            const options2 = {
                headers: {
                    'Authorization': `Bearer ${token}`
                }
            };
            fetch(url, options2)
                .then(res => res.blob())
                .then(blob => {
                    var file = window.URL.createObjectURL(blob);
                    //  window.location.assign(file);
                   // Browser.open({ url: file });
                    window.open(file, '_blank').focus();
                });
        })
    }
  };
       

  return (

    <React.Fragment>
      <h2 className={'content-block'}>Wochenübersicht</h2>
      <DataGrid
            
        dataSource={dataSource}
        showBorders={true}
      >

        <Sorting mode="multiple" />
        <Editing
        
        allowUpdating={false}
        allowDeleting={false}
        allowAdding={false}
      >
      
        </Editing>
        <Summary>
            <TotalItem
              column="ReiseMinuten"
              summaryType="sum" />
            <TotalItem
              column="Reisekilometer"
              summaryType="sum"
              />
            <TotalItem
              column="arbeitsstunden"
              summaryType="sum"
          />
          </Summary>
        <FilterRow visible={true}
          applyFilter="auto" />
        <HeaderFilter visible={false} />
        <SearchPanel visible={true}
          width={240}
          placeholder="Suchen..." />
        <Column
          dataField="moschein_nr"
          caption="Moschein-Nr"

        />

         <Column
          dataField="startdate"
          caption="Beginn"
          dataType="datetime"
          sortIndex={1}
          defaultSortOrder="desc"
        />
                 <Column
          dataField="enddate"
          caption="Ende"
          dataType="datetime"
        />
          <Column
          dataField="ReiseMinuten"
          caption="Wegzeit"
          format={"#0 min"}
        />
          <Column
          dataField="Reisekilometer"
          caption="Kilometer"
          format={"#0 km"}
        />
          <Column
          dataField="arbeitsstunden"
          caption="Arbeitsstunden"
          format={"#0.## min"}
        />

<Column type="buttons">
          <Button
            text="öffnen"
            onClick={moschein_oeffnen}
          />
        </Column>




      </DataGrid>
    </React.Fragment>
  );


};
