import React, { useState, useEffect } from 'react';
import { getToken_api } from '../../api/auth';
import ODataStore from 'devextreme/data/odata/store';
import { useParams } from 'react-router-dom'
import Guid from 'devextreme/core/guid';
import { networkConfig } from '../../networkConfig';
import { Vertrag_edit_comp } from '../../components';

import config from 'devextreme/core/config';
export default function () {


    let { vertrag_id } = useParams()


    return (

        <React.Fragment>
            <h2 className={'content-block'}>Vertrag anzeigen/bearbeiten</h2>

            <div className={'content-block dx-card responsive-paddings'}>
                <Vertrag_edit_comp vertrag_id={vertrag_id} admin={true}></Vertrag_edit_comp>
            </div>




        </React.Fragment>
    );
};
