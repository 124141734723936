import React, { useState, useEffect, useRef } from 'react';
import { useHistory } from "react-router-dom";
import { getToken_api } from '../../api/auth';
import DataGrid, { Column, Button, Grouping, FilterRow, SearchPanel, HeaderFilter, Editing, Form, Lookup, Sorting } from 'devextreme-react/data-grid';
import { networkConfig } from '../../networkConfig';
import { Item, SimpleItem } from 'devextreme-react/form';

export default function () {

  const [token, setToken] = useState('')
  const [dataSource, setdataSource] = useState('')
  const [dataSource_Buero, setdataSource_Buero] = useState('')
  const history = useHistory();
  const inputRef = useRef();
  useEffect(() => {
    console.log('list')
    getToken_api().then(token => {
      setdataSource({
        store: {
          type: 'odata',
          url: networkConfig.dataEndpoint + 'auftrag_aksn',
          key: 'auftrag_id',
          withCredentials: true,
          beforeSend: function (e) {
            e.headers = {
              "Authorization": 'Bearer ' + token
            };
          },
          onInserted: function(values, key){
           // history.push("/settings/debitoren/bearbeiten/" + values.deb_id);
          }

        },
      })
      setdataSource_Buero({
        store: {
          type: 'odata',
          url: networkConfig.dataEndpoint + 'bueros_aksn',
          key: 'buero_id',
          withCredentials: true,
          beforeSend: function (e) {
            e.headers = {
              "Authorization": 'Bearer ' + token
            };
          }
        },
        sort: ["Name"]
      })

    })

  }, [inputRef]);
  const auftrag_anlegen = function (e) {
    history.push("/auftrag/erstellen");
  };
  const auftrag_oeffnen = function (e) {
    history.push("/auftrag/bearbeiten/" + e.row.data.auftrag_id);
  };
       

  return (

    <React.Fragment>
      <h2 className={'content-block'}>Aufträge anzeigen</h2>
      <DataGrid
        ref={inputRef}
                onInitNewRow={auftrag_anlegen}
        dataSource={dataSource}
        showBorders={true}
      >
        <Grouping autoExpandAll={false} />
        <Sorting mode="multiple" />
        <Editing
        mode="form"
        allowUpdating={false}
        allowDeleting={false}
        allowAdding={true}
      >
          <Form title="Debitor" showTitle={true}>

            <Item dataField="Name"/>
            
          </Form>
        </Editing>
        <FilterRow visible={true}
          applyFilter="auto" />
        <HeaderFilter visible={false} />
        <SearchPanel visible={true}
          width={240}
          placeholder="Suchen..." />
        <Column
          dataField="auftrag_nr"
          caption="Auftrag-Nr"
          defaultSortOrder="asc"
        />
        <Column
          dataField="projekt_nr"
          caption="Projekt-Nr"
        />
        <Column
          dataField="auftrageingangsdatum"
          caption="Auftrageingangsdatum"
        />
                <Column
          dataField="typ"
          caption="Typ"
        />
                        <Column
          dataField="faelligkeit"
          caption="Fälligkeit"
        />
                                <Column
          dataField="title"
          caption="Titel"
        />


<Column
          dataField="kostentraeger"
          caption="Kostenträger"
        />
                                <Column
          dataField="deb_name"
          caption="Debitor"
        />
                                <Column
          dataField="ein_name"
          caption="Einsatzort"
        />
                                <Column
          dataField="ein_str"
          caption="Straße"
        />
                                <Column
          dataField="ein_plz"
          caption="PLZ"
        />
                             <Column
          dataField="ein_ort"
          caption="Ort"
        />
                       <Column
          dataField="status_text"
          caption="Status"
        />

        <Column
          dataField="buero_id"
          dataType="Guid"
          caption="Büro"
        >
   <Lookup dataSource={dataSource_Buero} displayExpr="Name" valueExpr="buero_id" />
        </Column>

<Column type="buttons">
          <Button
            text="bearbeiten"
            onClick={auftrag_oeffnen}
          />
        </Column>




      </DataGrid>
    </React.Fragment>
  );


};
