import React, { useState, useEffect,useRef } from 'react';
import { useHistory } from "react-router-dom";
import { getToken_api ,getToken_user } from '../../../api/auth';
import DataGrid, { Column, Button, Lookup, FilterRow, SearchPanel, HeaderFilter, Editing, Popup, Form, Selection, Paging, Scrolling } from 'devextreme-react/data-grid';
import { networkConfig } from '../../../networkConfig';
import { Item, SimpleItem } from 'devextreme-react/form';
import { Lookup as Lookup2, DropDownOptions } from 'devextreme-react/lookup';
import DropDownBox from 'devextreme-react/drop-down-box';
import TextBox from 'devextreme-react/text-box';
import Guid from 'devextreme/core/guid';
import { callMsGraph_Users } from '../../../api/graph';
import { Popup as Popup2, Position, ToolbarItem } from 'devextreme-react/popup';
export default function () {

  const [token, setToken] = useState('')
  const history = useHistory();
  const [dataSource, setdataSource] = useState('')
  const [dataSource_Buero, setdataSource_Buero] = useState('')
  const [userdaten, setuserdaten] = useState([])
  const [formData, setformData] = useState({
    vorname: "",
    nachname:"",
    mailadresse:"",
    telefon:"",
    title:"",
    name:""
  })

  
  const [gridBoxValue, setgridBoxValue] = useState('')
  const [vorname, setvorname] = useState('')
  const [isGridBoxOpened, setisGridBoxOpened] = useState(false)
  const [popup_visible, setpopup_visible] = useState(false)
  const gridColumns = ['displayName', 'mail'];
  const textbox = useRef();

  useEffect(() => {

    getToken_api().then(token => {
      setToken(token)
      setdataSource({
        store: {
          type: 'odata',
          url: networkConfig.dataEndpoint + 'benutzer',
          key: 'benutzer_id',
          withCredentials: true,
          beforeSend: function (e) {
            e.headers = {
              "Authorization": 'Bearer ' + token
            };
          },
          onInserted: function(values, key){
            history.push("/user/bearbeiten/" + values.benutzer_id);
          }
        },
      })

      setdataSource_Buero({
        store: {
          type: 'odata',
          url: networkConfig.dataEndpoint + 'bueros',
          key: 'buero_id',
          withCredentials: true,
          beforeSend: function (e) {
            e.headers = {
              "Authorization": 'Bearer ' + token
            };
          }
        },
      })
    })
    

  }, []);

  const benutzer_oeffnen = function (e) {
    history.push("/user/bearbeiten/" + e.row.data.benutzer_id);
  };
  function nutzerladen(e) {
    console.log(e)
  };
      
  function dataGridRender() {
    return (
      <DataGrid
        dataSource={userdaten}
        columns={gridColumns}
        hoverStateEnabled={true}
        selectedRowKeys={gridBoxValue}
        onSelectionChanged={dataGrid_onSelectionChanged}
        height="100%">
        <Selection mode="single" />
        <Scrolling mode="virtual" />
        <FilterRow visible={true} />
      </DataGrid>
    );
  };
  function dataGrid_onSelectionChanged(e) {
  if (e.selectedRowKeys.length > 0){
    setgridBoxValue(new Guid(e.selectedRowKeys[0].id))
    setformData({
      vorname: e.selectedRowKeys[0].givenName,
      nachname:e.selectedRowKeys[0].surname,
      mailadresse:e.selectedRowKeys[0].mail,
      telefon:e.selectedRowKeys[0].mobilePhone,
      title:e.selectedRowKeys[0].jobTitle,
      name:e.selectedRowKeys[0].displayName
    })
    setisGridBoxOpened(false)
  }
 
 

  

  };
  function onGridBoxOpened(e) {
    if (e.name === 'opened') {
      setisGridBoxOpened(e.value)
      setvorname('false') 
    }
  }
  function syncDataGridSelection(e) {
    setgridBoxValue(new Guid(e.value))

  }
  function setCellValue(newData, value) {
    let Column = this;
    Column.defaultSetCellValue(newData, value);
}
function onEditorPreparing(e) {
  if(e.dataField === "name" && e.parentType ==="dataRow"){
    e.row.data.benutzer_id = gridBoxValue
    e.row.data.mailadresse = formData.mailadresse
    e.row.data.vorname = formData.vorname
    e.row.data.nachname = formData.nachname
    e.row.data.telefon = formData.telefon
    e.row.data.title = formData.title
    e.row.data.name = formData.name
  }
}    

  return (

    <React.Fragment>
      <h2 className={'content-block'}>Benutzer anzeigen</h2>
      <DataGrid
     onEditorPreparing={onEditorPreparing}
        dataSource={dataSource}
        showBorders={true}
        onInitNewRow={function(e){setpopup_visible(true)}}
      >
        <Editing
        mode="form"
        allowUpdating={false}
        allowDeleting={false}
        allowAdding={true}
      >
          <Form title="Mitarbeiter" showTitle={true} colCount="2">
            <Item dataField='benutzer_id' colSpan='2'>
              <DropDownBox
                value={gridBoxValue}
                opened={isGridBoxOpened}
                valueExpr="id"
                deferRendering={false}
                displayExpr="displayName"
                placeholder="Mitarbeiter auswählen."
                showClearButton={true}
                dataSource={userdaten}
                onValueChanged={syncDataGridSelection}
                onOptionChanged={onGridBoxOpened}
                contentRender={dataGridRender}
              />
            </Item>
            <Item dataField="vorname" disabled={true}/>
            <Item dataField="nachname" disabled={true}  />
            <Item dataField="name"disabled={true} />
            <Item dataField="mailadresse"disabled={true} />
            <Item dataField="title" disabled={true}/>
            <Item dataField="telefon" disabled={true}/>


          </Form>
        </Editing>
        <FilterRow visible={true}
          applyFilter="auto" />
        <HeaderFilter visible={false} />
        <SearchPanel visible={true}
          width={240}
          placeholder="Suchen..." />
        <Column
          dataField="benutzer_id"
          caption="Benutzer"
          setCellValue={setCellValue}
          visible={false}
        />        <Column
        dataField="name"
        caption="Name"
       
      />
        <Column
          dataField="vorname"
          visible={false}
        />
         <Column
          dataField="nachname"
          visible={false}
        />
         <Column
          dataField="telefon"
          visible={false}
        />
        <Column
          dataField="mailadresse"
          caption="Benutzername"
        />
        <Column
          dataField="title"
          caption="Stellenbezeichnung"
        />
        <Column
          dataField="buero_id"
          caption="Standort"
        >
          <Lookup dataSource={dataSource_Buero} displayExpr="Name" valueExpr="buero_id" />
        </Column>
        <Column
          dataField="aksn"
          caption="Mitarbeiter-Nr"
        />
        <Column
          dataField="isDisabled"
          caption="deaktiviert"
          dataType="boolean"
        ></Column>
        <Column type="buttons">
          <Button
            text="bearbeiten"
            onClick={benutzer_oeffnen}
          />
        </Column>
      </DataGrid>
      <Popup2
                visible={popup_visible}
                onHiding={function (e) { setpopup_visible(false) }}
                dragEnabled={false}
                closeOnOutsideClick={true}
                showCloseButton={true}
                showTitle={false}

                width={400}
                height={200}
            >
                <Position
                    at="center"
                    my="center"

                />

                <ToolbarItem
                    widget="dxButton"
                    toolbar="bottom"
                    location="befor"
                    options={{
                        text: 'Suchen',
                        disabled: false,
                        onClick: function(e){
                      
                          var name = textbox.current.instance.option("value")
                          var msGraphResult = getToken_user().then( token2 => {
                         
                            var msGraphResult = callMsGraph_Users(token2,name).then(value => {
                              debugger;
                              setuserdaten(value.value)
                              setpopup_visible(false)
                             return
                             })
                             return
                           })
                        }
                    }}
                />
              <TextBox ref={textbox} placeholder="Beginn des Namens" />
                
            </Popup2>
    </React.Fragment>
  );


};
