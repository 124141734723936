import React, { useState, useEffect } from 'react';
import { getToken_api } from '../../../api/auth';
import Form, { Item, SimpleItem, Label } from 'devextreme-react/form';
import ODataStore from 'devextreme/data/odata/store';
import DataGrid, { Column, Editing, Lookup } from 'devextreme-react/data-grid';
import { useParams } from 'react-router-dom'
import Guid from 'devextreme/core/guid';
import { Vertrag_uebersicht } from '../../../components';
import ResponsiveBox, {
    Row,
    Col,
    Item as Resitem,
    Location
} from 'devextreme-react/responsive-box';
import { networkConfig } from '../../../networkConfig';
export default function () {

    const [token, setToken] = useState('')
    const [formData, setformData] = useState('')
    const [buero_select_options, setbuero_select_options] = useState('')
    const [vertrag_dataSource, setvertrag_dataSource] = useState('')
    const bereich_select_options = { dataSource: ["OST", "WEST"] }
    let { deb_id } = useParams()
    const saveButton = {
        icon: 'save',
        text: 'Speichern',
        onClick: () => {

            const dataSource = new ODataStore({
                type: 'odata',
                url: networkConfig.dataEndpoint + 'debitor',
                key: 'deb_id',
                withCredentials: true,
                beforeSend: function (e) {
                    e.headers = {
                        "Authorization": 'Bearer ' + token
                    };
                }
            });
            dataSource.update(new Guid(deb_id), formData)

        }
    };

    useEffect(() => {
        getToken_api().then(token => {
            setToken(token)

            const dataSource = new ODataStore({
                type: 'odata',
                url: networkConfig.dataEndpoint + 'debitor',
                key: 'deb_id',
                withCredentials: true,
                beforeSend: function (e) {

                    e.headers = {
                        "Authorization": 'Bearer ' + token
                    };
                }
            });
            dataSource.load({ filter: ['deb_id', '=', new Guid(deb_id)] })
                .then(
                    (data) => { setformData(data[0]); },
                    (error) => { /* Handle the "error" here */ }
                );

            const store = new ODataStore({
                type: 'odata',
                url: networkConfig.dataEndpoint + 'bueros',
                key: 'buero_id',
                withCredentials: true,
                beforeSend: function (e) {
                    e.headers = {
                        "Authorization": 'Bearer ' + token
                    };
                },
            });

            setbuero_select_options({ dataSource: { store }, displayExpr: "Name", valueExpr: "buero_id", searchEnabled: true })
           
            setvertrag_dataSource({
                store: {
                  type: 'odata',
                  url: networkConfig.dataEndpoint + 'bueros',
                  key: 'buero_id',
                  withCredentials: true,
                  beforeSend: function (e) {
                    e.headers = {
                      "Authorization": 'Bearer ' + token
                    };
                  }
                },
              })
        })
    }, []);


    return (

        <React.Fragment>
            <h2 className={'content-block'}>Debitor bearbeiten</h2>

            <div className={'content-block dx-card responsive-paddings'}>
                <Form
                    id={'form'}
                    formData={formData}
                    labelLocation={'top'}
                    colCount={4}
                >
                    <Item dataField="nr" colSpan={4} editorOptions={{}}>
                        <Label text="Nummer" />
                    </Item>
                    <Item dataField="name" colSpan={2} editorOptions={{}}>
                        <Label text="Name" />
                    </Item>
                    <Item dataField="name2" editorOptions={{}}>
                        <Label text="Name2" />
                    </Item>
                    <Item dataField="name3" editorOptions={{}}>
                        <Label text="Name3" />
                    </Item>
                    <Item dataField="strasse" colSpan={2} editorOptions={{}}>
                        <Label text="Straße" />
                    </Item>
                    <Item dataField="plz" editorOptions={{}}>
                        <Label text="PLZ" />
                    </Item>
                    <Item dataField="ort" editorOptions={{}}>
                        <Label text="Ort" />
                    </Item>
                    <Item dataField="buero_id" colSpan={2} editorType="dxSelectBox" editorOptions={buero_select_options}>
                        <Label text="Büro" />
                    </Item>
                    <Item dataField="bereich" colSpan={2} editorType="dxSelectBox" editorOptions={bereich_select_options}>
                        <Label text="Bereich" />
                    </Item>
                    <Item dataField="fsz" colSpan={4} editorOptions={{}}>
                        <Label text="FSZ" />
                    </Item>
                    <Item dataField="callcenter" colSpan={4} editorOptions={{}}>
                        <Label text="Callcenter" />
                    </Item>
                    <Item dataField="infos" colSpan={4} editorOptions={{}}>
                        <Label text="Infos" />
                    </Item>

                    <Item dataField="kontakt_tel1" editorOptions={{}}>
                        <Label text="Telefon1" />
                    </Item>
                    <Item dataField="kontakt_tel2" editorOptions={{}}>
                        <Label text="Telefon2" />
                    </Item>
                    <Item dataField="kontakt_ansprechpartner" editorOptions={{}}>
                        <Label text="Ansprechpartner" />
                    </Item>
                    <Item dataField="kontakt_mail" editorOptions={{}}>
                        <Label text="Mail" />
                    </Item>

                    <Item dataField="sperrkennzeichen" editorType="dxCheckBox" editorOptions={{}}>
                        <Label text="Sperrkennzeichen" />
                    </Item>
                    <SimpleItem itemType="button"
                        horizontalAlignment="left"
                        buttonOptions={saveButton}>
                    </SimpleItem>
                </Form>
            </div>

            <div className={'content-block dx-card responsive-paddings'}>
                <Vertrag_uebersicht kunde_id={deb_id}></Vertrag_uebersicht>
            </div>



        </React.Fragment>
    );
};
