import React, { useState, useEffect, useRef } from 'react';
import { getToken_api } from '../../api/auth';
import Form, { Item, ButtonItem, Label, RequiredRule, GroupItem, TabbedItem, TabPanelOptions, Tab } from 'devextreme-react/form';
import SelectBox from 'devextreme-react/select-box';
import ODataStore from 'devextreme/data/odata/store';
import 'devextreme-react/text-area';
import { TextBox, Button as TextBoxButton } from 'devextreme-react/text-box';
import DataGrid, { Column, Grouping, Sorting, FilterRow, HeaderFilter, SearchPanel, Editing, Lookup, Button } from 'devextreme-react/data-grid';
import { Popup, Position, ToolbarItem } from 'devextreme-react/popup';
import { useParams } from 'react-router-dom'
import Guid from 'devextreme/core/guid';
import notify from 'devextreme/ui/notify';
import { networkConfig } from '../../networkConfig';
import { Vertrag_edit_comp } from '../../components';
import { useHistory } from "react-router-dom";
import DropDownButton from 'devextreme-react/drop-down-button';
import { isMobile } from "react-device-detect";
import DataSource from 'devextreme/data/data_source';

export default function () {
    let { auftrag_id } = useParams()
    const [token, setToken] = useState('')
    const [dataSource_Bueros, setdataSource_Bueros] = useState('')
    const [formData, setformData] = useState({})
    const [buero_select_options, setbuero_select_options] = useState('')
    const [kostentraeger_select_options, setkostentraeger_select_options] = useState('')
    const [vertrag_popup_visible, setvertrag_popup_visible] = useState(false)
    const [popup_kundeninfo_visible, setpopup_kundeninfo_visible] = useState(false)
    const [popup_kundeninfos_data, setpopup_kundeninfos_data] = useState([])
    const [deb_popup_visible, setdeb_popup_visible] = useState(false)
    const [ein_popup_visible, setein_popup_visible] = useState(false)
    const [vertrag_dataSource, setvertrag_dataSource] = useState({})
    const [debdaten, setdebdaten] = useState('')
    const [deb_nr, setdeb_nr] = useState('')
    const [deb_ausgewaehlt, setdeb_ausgewaehlt] = useState(false)
    const [eindaten, seteindaten] = useState('')
    const [ein_nr, setein_nr] = useState('')
    const [ein_ausgewaehlt, setein_ausgewaehlt] = useState(false)
    const [vertrag_nr, setvertrag_nr] = useState('')
    const [vertrag_id, setvertrag_id] = useState('')
    const [dataSource_Tourauftrag, setdataSource_Tourauftrag] = useState('')
    const [dataSource_MoSchein, setdataSource_MoSchein] = useState('')
    const [dataSource_user, setdataSource_user] = useState('')
    const [dataSource_statecode, setdataSource_statecode] = useState('')
    const [aw_data, setaw_data] = useState([])
    const [editable, seteditable] = useState(false)
    const history = useHistory();
    const [isAdmin, setisAdmin] = useState(false)
    const [aw_vorhanden, setaw_vorhanden] = useState(false)
    const [popup_aw_visible, setpopup_aw_visible] = useState(false)
    const [status_auswahl, setstatus_auswahl] = useState(["offen", "in Abarbeitung", "geschlossen", "Abgebrochen"])

    const form = useRef(null);

    useEffect(() => {

        if (ein_ausgewaehlt == true) {
            getToken_api().then(token => {
                setToken(token)


                const dataSource = new ODataStore({
                    type: 'odata',
                    url: networkConfig.dataEndpoint + 'filiale_view',
                    key: 'filiale_id',
                    withCredentials: true,
                    beforeSend: function (e) {
                        e.headers = {
                            "Authorization": 'Bearer ' + token
                        };
                    }
                });
                dataSource.load({ filter: ["filiale_id", "=", formData.ein_id] })
                    .then(
                        (data) => {
                            setpopup_kundeninfos_data(data[0])
                        })


                const headers = new Headers();
                const bearer = `Bearer ${token}`;
                headers.append("Authorization", bearer);
                headers.append("Content-Type", "application/json;charset=UTF-8")

                const options = {
                    method: "GET",
                    headers: headers,

                };

                return fetch(networkConfig.serverEndpoint + "api/auftrag/GetArbeitsanweisungen_Kunde?kundennummer=" + ein_nr, options)
                    .then(response => response.json())
                    .then(data => {
                        if (data.success == true) {
                            setaw_data(data.result_set)
                            if (data.result_set.length > 0) {
                                setaw_vorhanden(true)
                            } else {
                                setaw_vorhanden(false)
                            }
                        } else {
                            setaw_vorhanden(false)
                        }

                    }
                    )
                    .catch(error => console.log(error));




            })
        } else {
            setaw_vorhanden(false)
        }
    }, [ein_ausgewaehlt]);

    useEffect(() => {
        getToken_api().then(token => {
            setToken(token)
            const dataSource = new ODataStore({
                type: 'odata',
                url: networkConfig.dataEndpoint + 'auftrag',
                key: 'auftrag_id',
                withCredentials: true,
                beforeSend: function (e) {
                    e.headers = {
                        "Authorization": 'Bearer ' + token
                    };
                }
            });
            dataSource.load({ filter: ['auftrag_id', '=', new Guid(auftrag_id)] })
                .then(
                    (data) => {
                        var edit_temp = false;
                        setformData(data[0]);
                        console.log(data[0])
                        setvertrag_nr(data[0].vertrag_nr)
                        if (data[0].deb_nr != null) {
                            setdeb_nr(data[0].deb_nr)
                            setdeb_ausgewaehlt(true)
                        }
                        console.log(data[0].status_text)
                        if (data[0].status_text == 'in Abarbeitung' || data[0].status_text == 'offen') {
                            seteditable(true)
                            console.log(editable)
                            edit_temp = true;
                        }
                        if (data[0].ein_nr != null) {

                            setein_nr(data[0].ein_nr)
                            setein_ausgewaehlt(true)

                            setvertrag_dataSource({
                                store: {
                                    type: 'odata',
                                    url: networkConfig.dataEndpoint + 'vertrag_view',
                                    key: 'filiale_id',
                                    withCredentials: true,
                                    beforeSend: function (e) {
                                        e.headers = {
                                            "Authorization": 'Bearer ' + token
                                        };
                                    },
                                },
                                filter: ["kunde_id", "=", data[0].ein_id]

                            })
                            setdataSource_user({
                                store: {
                                    type: 'odata',
                                    url: networkConfig.dataEndpoint + 'dispo_monteur_view',
                                    key: 'tourauftrag_id',
                                    withCredentials: true,
                                    beforeSend: function (e) {
                                        e.headers = {
                                            "Authorization": 'Bearer ' + token
                                        };
                                    },
                                }, filter: ["buero_id", "=", new Guid(data[0].buero_id)]

                            })
                        }
                        if (data[0].status_text == 'geschlossen') {
                            setstatus_auswahl(["geschlossen", "Storniert"])
                        }
                        if (data[0].status_text == 'Storniert') {
                            setstatus_auswahl(["Storniert"])
                        }

                        const store1 = new ODataStore({
                            type: 'odata',
                            url: networkConfig.dataEndpoint + 'bueros_aksn',
                            key: 'buero_id',
                            withCredentials: true,
                            beforeSend: function (e) {
                                e.headers = {
                                    "Authorization": 'Bearer ' + token
                                };
                            },
                        });

                        setbuero_select_options({ dataSource: { store: store1, sort: ["Name"] }, displayExpr: "Name", valueExpr: "buero_id", searchEnabled: true, readOnly: edit_temp ? false : true })

                        const store_data = new DataSource({


                            store: {
                                type: 'odata',
                                url: networkConfig.dataEndpoint + 'kostentraeger',
                                key: 'kostentraeger_id',
                                withCredentials: true,
                                beforeSend: function (e) {
                                    e.headers = {
                                        "Authorization": 'Bearer ' + token
                                    };
                                }, sort: ["nr"]
                            }
                        });
                        store_data.load();
                        setkostentraeger_select_options({ dataSource: store_data, displayExpr: "bezeichnung", valueExpr: "kostentraeger_id", searchEnabled: true, readOnly: edit_temp ? false : true })


                        const headers = new Headers();
                        const bearer = `Bearer ${token}`;
                        headers.append("Authorization", bearer);
                        headers.append("Content-Type", "application/json;charset=UTF-8")

                        const options = {
                            method: "POST",
                            headers: headers,
                        };

                        return fetch(networkConfig.serverEndpoint + "api/auftrag/GetProjekt?auftrag=" + auftrag_id, options)
                            .then(response => response.json())
                            .then(data2 => {
                                if (data2.success == true) {
                                    if (data[0].projekt_nr != data2.result_set) {
                                        data[0].projekt_nr = data2.result_set;
                                        console.log(data[0])
                                        setformData(data[0])
                                    }

                                }
                            }
                            )
                            .catch(error => console.log(error));


                    },
                    (error) => { /* Handle the "error" here */ }
                );

            const store2 = new ODataStore({
                type: 'odata',
                url: networkConfig.dataEndpoint + 'kostentraeger',
                key: 'kostentraeger_id',
                withCredentials: true,
                beforeSend: function (e) {
                    e.headers = {
                        "Authorization": 'Bearer ' + token
                    };
                },
            });


            setdebdaten({
                store: {
                    type: 'odata',
                    url: networkConfig.dataEndpoint + 'debitor',
                    key: 'deb_id',
                    withCredentials: true,
                    beforeSend: function (e) {
                        e.headers = {
                            "Authorization": 'Bearer ' + token
                        };
                    },
                },

            })
            seteindaten({
                store: {
                    type: 'odata',
                    url: networkConfig.dataEndpoint + 'filiale_view',
                    key: 'filiale_id',
                    withCredentials: true,
                    beforeSend: function (e) {
                        e.headers = {
                            "Authorization": 'Bearer ' + token
                        };
                    },
                },

            })
            setdataSource_MoSchein({
                store: {
                    type: 'odata',
                    url: networkConfig.dataEndpoint + 'moschein',
                    key: 'moschein_id',
                    withCredentials: true,
                    beforeSend: function (e) {
                        e.headers = {
                            "Authorization": 'Bearer ' + token
                        };
                    },
                }, filter: ["auftrag_id", "=", new Guid(auftrag_id)]

            })
            setdataSource_Tourauftrag({
                store: {
                    type: 'odata',
                    url: networkConfig.dataEndpoint + 'tourauftrag',
                    key: 'tourauftrag_id',
                    withCredentials: true,
                    beforeSend: function (e) {
                        e.headers = {
                            "Authorization": 'Bearer ' + token
                        };
                    },
                }, filter: ["auftrag_id", "=", new Guid(auftrag_id)]

            })
            setdataSource_statecode({
                store: {
                    type: 'odata',
                    url: networkConfig.dataEndpoint + 'statecodes',
                    key: 'tourauftrag_id',
                    withCredentials: true,
                    beforeSend: function (e) {
                        e.headers = {
                            "Authorization": 'Bearer ' + token
                        };
                    },
                }
            })

            if (localStorage.getItem("roles").indexOf(";admin;") != -1) {
                setisAdmin(true)
                console.log(true)
            }


        }, (error) => { console.log("error: " + error) });

        console.log("ende")
    }, [form]);

    let deb_uebernehmen = function (e) {
        setformData({ ...formData, deb_id: e.selectedRowsData[0].deb_id, deb_nr: e.selectedRowsData[0].nr, deb_name: e.selectedRowsData[0].name, deb_name2: e.selectedRowsData[0].name2, deb_name3: e.selectedRowsData[0].name3, deb_str: e.selectedRowsData[0].strasse, deb_plz: e.selectedRowsData[0].plz, deb_ort: e.selectedRowsData[0].ort })
        setdeb_nr(e.selectedRowsData[0].nr)
        setdeb_ausgewaehlt(true)
        setdeb_popup_visible(false)


    }
    let ein_uebernehmen = function (e) {

        setformData({ ...formData, ein_id: e.selectedRowsData[0].filiale_id, ein_nr: e.selectedRowsData[0].filiale_nr, ein_name: e.selectedRowsData[0].name, ein_name2: e.selectedRowsData[0].name2, ein_name3: e.selectedRowsData[0].name3, ein_str: e.selectedRowsData[0].strasse, ein_plz: e.selectedRowsData[0].plz, ein_ort: e.selectedRowsData[0].ort, buero_id: e.selectedRowsData[0].buero_id })
        setein_nr(e.selectedRowsData[0].filiale_nr)
        setein_ausgewaehlt(true)
        setein_popup_visible(false)

        setvertrag_dataSource({
            store: {
                type: 'odata',
                url: networkConfig.dataEndpoint + 'vertrag_view',
                key: 'filiale_id',
                withCredentials: true,
                beforeSend: function (e) {
                    e.headers = {
                        "Authorization": 'Bearer ' + token
                    };
                },
            },
            filter: ["kunde_id", "=", e.selectedRowsData[0].filiale_id]

        })

    }
    let tourauftrag_anlegen = function (e) {
        const headers = new Headers();
        const bearer = `Bearer ${token}`;
        headers.append("Authorization", bearer);
        headers.append("Content-Type", "application/json;charset=UTF-8")
        console.log(formData)
        console.log(JSON.stringify(formData))
        const options = {
            method: "POST",
            headers: headers,
            body: JSON.stringify(formData)
        };

        return fetch(networkConfig.serverEndpoint + "api/auftrag/AddTourauftrag", options)
            .then(response => response.json())
            .then(data => {
                if (data.success == true) {
                    notify({
                        message: 'Tourauftrag wurde erfolgreich gespeichert',

                    }, 'success', 5000);

                    history.push("/tourauftrag/bearbeiten/" + data.result_set.tourauftrag_id);

                } else {
                    notify({
                        message: 'Fehler :(',

                    }, 'error', 5000);
                }

            }
            )
            .catch(error => console.log(error));

    }
    let tourauftrag_oeffnen = function (e) {
        history.push("/tourauftrag/bearbeiten/" + e.row.data.tourauftrag_id);
    }
    let moschein_pdf_anzeigen = function (e) {

        if (isMobile) {
            //history.push("/pdf/GetAW/" + e.row.data.dokumentenname.toString());
        } else {
            getToken_api().then(token => {
                setToken(token)
                const url = networkConfig.serverEndpoint + "api/moschein/GetMoschein?file=" + e.row.data.moschein_nr.toString()
                const options2 = {
                    headers: {
                        'Authorization': `Bearer ${token}`
                    }
                };
                fetch(url, options2)
                    .then(res => res.blob())
                    .then(blob => {
                        var file = window.URL.createObjectURL(blob);
                        //  window.location.assign(file);
                        // Browser.open({ url: file });
                        window.open(file, '_blank').focus();
                    });
            })
        }

    }
    let moschein_stornieren = function (e) {
        const headers = new Headers();
        const bearer = `Bearer ${token}`;
        headers.append("Authorization", bearer);
        headers.append("Content-Type", "application/json;charset=UTF-8")
        const options = {
            method: "POST",
            headers: headers,
            body: JSON.stringify(e.row.data)
        };

        return fetch(networkConfig.serverEndpoint + "api/moschein/StornoMoSchein", options)
            .then(response => response.json())
            .then(data => {
                if (data.success == true) {
                    notify({
                        message: 'Moschein wurde erfolgreich storniert',

                    }, 'success', 5000);

                    history.push("/auftrag/bearbeiten/" + auftrag_id);

                } else {
                    notify({
                        message: 'Fehler :(',

                    }, 'error', 5000);
                }

            }
            )
            .catch(error => console.log(error));
    }
    let moschein_anlegen = function (e) {
        const headers = new Headers();
        const bearer = `Bearer ${token}`;
        headers.append("Authorization", bearer);
        headers.append("Content-Type", "application/json;charset=UTF-8")
        console.log(formData)
        console.log(JSON.stringify(formData))
        const options = {
            method: "POST",
            headers: headers,
            body: JSON.stringify(formData)
        };

        return fetch(networkConfig.serverEndpoint + "api/moschein/AddMoschein", options)
            .then(response => response.json())
            .then(data => {
                if (data.success == true) {
                    notify({
                        message: 'Moschein wurde erfolgreich gespeichert',

                    }, 'success', 5000);

                    history.push("/moschein/bearbeiten/" + data.result_set.moschein_id + '/0');

                } else {
                    notify({
                        message: 'Fehler :(',

                    }, 'error', 5000);
                }

            }
            )
            .catch(error => console.log(error));


    }
    let moschein_oeffnen = function (e) {
        history.push("/moschein/bearbeiten/" + e.row.data.moschein_id + '/0');
    }
    const visible_storno = function (e) {
        if (e.row.data.abschluss && e.row.data.moschein_nr.startsWith("S") == false) {
            return true
        } else {
            return false
        }

    };
    let speichern = function (e) {


        const headers = new Headers();
        const bearer = `Bearer ${token}`;
        headers.append("Authorization", bearer);
        headers.append("Content-Type", "application/json;charset=UTF-8")
        console.log(formData)
        console.log(JSON.stringify(formData))
        const options = {
            method: "POST",
            headers: headers,
            body: JSON.stringify(formData)
        };

        return fetch(networkConfig.serverEndpoint + "api/auftrag/UpdateAuftrag", options)
            .then(response => response.json())
            .then(data => {
                if (data.success == true) {
                    if (data.userMessage != '') {
                        setformData({ ...formData, projekt_nr: "" })
                        notify({
                            message: data.userMessage,

                        }, 'success', 5000);
                    } else {
                        notify({
                            message: 'Auftrag wurde erfolgreich gespeichert',

                        }, 'success', 5000);
                    }

                    if (e.itemData == "Speichern und schließen") {
                        history.push("/auftrag/anzeigen");
                    }
                } else {
                    notify({
                        message: 'Fehler :(',

                    }, 'error', 5000);
                }

            }
            )
            .catch(error => console.log(error));


    };



    return (

        <React.Fragment>

            <div className="dx-toolbar-items-container" style={{ height: "60px" }}>
                <div className="dx-toolbar-before">
                    <h2 className={'content-block'}>Auftrag {formData.auftrag_nr}</h2>
                </div>
                <div className="dx-toolbar-after" style={{ "paddingTop": "20px", "paddingRight": "40px" }}>
                    <DropDownButton
                        text="Speichern"
                        icon="save"
                        type='success'
                        dropDownOptions={{ width: 230 }}
                        items={["Speichern", "Speichern und schließen"]}
                        onItemClick={speichern}
                    />
                </div>
            </div>
            <div className={'content-block dx-card responsive-paddings'}>
                <Form
                    id={'form'}
                    formData={formData}
                    labelLocation={'left'}
                    ref={form}
                    alignItemLabelsInAllGroups={false}
                    validationGroup="AuftragData"

                >
                    <TabbedItem>
                        <TabPanelOptions deferRendering={false} />
                        <Tab title="Auftrag" colCount={3}>
                            <GroupItem caption="Allgemein" colCount={4} colSpan={3}>
                                <Item dataField="auftrag_nr" editorOptions={{ disabled: true }}>
                                    <Label text="Auftrags-Nr" />
                                </Item>
                                <Item dataField="projekt_nr" editorOptions={{ readOnly: editable ? false : true }} colSpan={1}>
                                    <Label text="Projekt-Nr" />
                                </Item>
                                <Item itemType="button" buttonOptions={{
                                    disabled: editable ? false : true,
                                    text: "neues Projekt in ERP erzeugen",
                                    onClick: function (e) {
                                        debugger;
                                        const headers = new Headers();
                                        const bearer = `Bearer ${token}`;
                                        headers.append("Authorization", bearer);
                                        headers.append("Content-Type", "application/json;charset=UTF-8")
                                        console.log(formData)
                                        console.log(JSON.stringify(formData))
                                        const options = {
                                            method: "POST",
                                            headers: headers,
                                            body: JSON.stringify(formData)
                                        };

                                        return fetch(networkConfig.serverEndpoint + "api/auftrag/SendAuftragtoERP", options)
                                            .then(response => response.json())
                                            .then(data => {
                                                if (data.success == true) {
                                                    if (data.userMessage != '') {
                                                        notify({
                                                            message: data.userMessage,

                                                        }, 'success', 5000);
                                                    } else {
                                                        notify({
                                                            message: 'Auftrag wurde erfolgreich übergeben',

                                                        }, 'success', 5000);
                                                    }
                                                } else {
                                                    notify({
                                                        message: 'Fehler :(',

                                                    }, 'error', 5000);
                                                }

                                            }
                                            )
                                            .catch(error => console.log(error));
                                    }
                                }} />
                                <Item dataField="buero_id" editorType="dxSelectBox" editorOptions={buero_select_options}>
                                    <Label text="Büro" />
                                    <RequiredRule message="Büro wird benötigt" />
                                </Item>
                                <Item dataField="auftrageingangsdatum" editorType="dxDateBox" editorOptions={{ readOnly: editable ? false : true }}>
                                    <Label text="Auftrageingangsdatum" />
                                    <RequiredRule message="Auftrageingangsdatum wird benötigt" />
                                </Item>
                                <Item dataField="typ" editorType="dxSelectBox" editorOptions={{
                                    readOnly: editable ? false : true,
                                    items: ["Reparatur", "Wartung", "Neubau"]
                                }}
                                >
                                    <Label text="Typ" />
                                    <RequiredRule message="Typ wird benötigt" />
                                </Item>
                                <Item dataField="faelligkeit" editorType="dxDateBox" editorOptions={{ readOnly: editable ? false : true }} colSpan={2}>
                                    <Label text="Fälligkeit" />
                                </Item>
                                <Item dataField="fehlerbeschreibung" editorType="dxTextArea" editorOptions={{
                                    maxLength: 250,
                                    readOnly: editable ? false : true


                                }} colSpan={2}>
                                    <Label text="Fehlerbeschreibung" />
                                    <RequiredRule message="Fehlerbeschreibung wird benötigt" />
                                </Item>
                                <Item dataField="callcenter" editorType="dxTextArea" editorOptions={{ disabled: isAdmin ? false : true, readOnly: isAdmin ? false : true }} colSpan={2}>
                                    <Label text="Callcenter-Info" />
                                </Item>
                                <Item dataField="title" editorOptions={{ readOnly: editable ? false : true }} colSpan={2}>
                                    <Label text="Titel" />
                                </Item>
                                <Item dataField="extTicketnr" editorOptions={{ readOnly: editable ? false : true }}>
                                    <Label text="ext. Ticket-Nr" />
                                </Item>
                                <Item dataField="extAuftragsnr" editorOptions={{ readOnly: editable ? false : true }}>
                                    <Label text="ext. Auftrag-Nr" />
                                </Item>
                                <Item dataField="stoermelder_name" editorOptions={{ readOnly: editable ? false : true }}>
                                    <Label text="Störmelder Name" />
                                    <RequiredRule message="Störmelder wird benötigt" />
                                </Item>
                                <Item dataField="stoermelder_tel" editorOptions={{ readOnly: editable ? false : true }}>
                                    <Label text="Störmelder Tel." />
                                </Item>
                                <Item dataField="stoermelder_mail" editorOptions={{ readOnly: editable ? false : true }} colSpan={2}>
                                    <Label text="Störmelder EMail" />
                                </Item>
                            </GroupItem>

                            <GroupItem caption="ERP-Kunde/Debitor" colCount={3} colSpan={1}>
                                <Item dataField="deb_id" dataTyp="Guid" visible={false} colSpan={3}>
                                    <Label text="Auswahl" />
                                </Item>
                                <Item dataField="deb_nr" colSpan={3}>
                                    <TextBox
                                        placeholder="kein Debitor ausgewählt"
                                        value={deb_nr}
                                        showClearButton={true}
                                    >
                                        <TextBoxButton
                                            name="deb_button"
                                            location="after"

                                            options={{
                                                visible: editable,
                                                icon: 'find',
                                                type: 'default',
                                                onClick: () => {
                                                    setdeb_popup_visible(true)
                                                }
                                            }}
                                        />
                                        <TextBoxButton
                                            name="deb_clear_button"
                                            location="after"

                                            options={{
                                                visible: ein_ausgewaehlt && editable,
                                                icon: 'clear',
                                                type: 'default',
                                                onClick: () => {
                                                    setdeb_nr('')
                                                    setformData({ ...formData, deb_nr: "", deb_id: null, deb_nr: null })
                                                    setdeb_ausgewaehlt(false)
                                                }
                                            }}
                                        />
                                    </TextBox>
                                    <Label text="Nummer" />
                                </Item>
                                <Item dataField="deb_name" editorOptions={{ disabled: deb_ausgewaehlt, readOnly: editable ? false : true }} colSpan={3}>
                                    <Label text="Name" />
                                </Item>
                                <Item dataField="deb_name2" editorOptions={{ disabled: deb_ausgewaehlt, readOnly: editable ? false : true }} colSpan={3}>
                                    <Label text="Name2" />
                                </Item>
                                <Item dataField="deb_name3" editorOptions={{ disabled: deb_ausgewaehlt, readOnly: editable ? false : true }} colSpan={3}>
                                    <Label text="Name3" />
                                </Item>
                                <Item dataField="deb_str" editorOptions={{ disabled: deb_ausgewaehlt, readOnly: editable ? false : true }} colSpan={3}>
                                    <Label text="Straße" />
                                </Item>
                                <Item dataField="deb_plz" editorOptions={{ disabled: deb_ausgewaehlt, readOnly: editable ? false : true }} colSpan={1}>
                                    <Label text="PLZ" />
                                </Item>
                                <Item dataField="deb_ort" editorOptions={{ disabled: deb_ausgewaehlt, readOnly: editable ? false : true }} colSpan={2}>
                                    <Label text="Ort" />
                                </Item>


                            </GroupItem>
                            <GroupItem caption="Einsatzort" colCount={3} colSpan={1}>
                                <Item dataField="ein_id" dataTyp="Guid" visible={false} colSpan={3}>

                                    <Label text="Auswahl" />
                                </Item>
                                <Item dataField="ein_nr" editorOptions={{}} colSpan={3}>
                                    <TextBox
                                        placeholder="kein Einsatzort ausgewählt"
                                        value={ein_nr}
                                    >
                                        <TextBoxButton
                                            name="ein_button"
                                            location="after"

                                            options={{
                                                visible: editable,
                                                icon: 'find',
                                                type: 'default',
                                                onClick: () => {
                                                    setein_popup_visible(true)
                                                }
                                            }}
                                        />
                                        <TextBoxButton
                                            name="ein_clear_button"
                                            location="after"

                                            options={{
                                                visible: ein_ausgewaehlt && editable,
                                                icon: 'clear',
                                                type: 'default',
                                                onClick: () => {
                                                    setein_nr('')
                                                    setformData({ ...formData, ein_nr: "", ein_id: null, ein_nr: null })
                                                    setein_ausgewaehlt(false)
                                                }
                                            }}
                                        />
                                    </TextBox>
                                    <Label text="Nummer" />
                                </Item>
                                <Item dataField="ein_name" editorOptions={{ disabled: ein_ausgewaehlt, readOnly: editable ? false : true }} colSpan={3}>
                                    <Label text="Name" />
                                    <RequiredRule message="Name wird benötigt" />
                                </Item>
                                <Item dataField="ein_name2" editorOptions={{ disabled: ein_ausgewaehlt, readOnly: editable ? false : true }} colSpan={3}>
                                    <Label text="Name2" />
                                </Item>
                                <Item dataField="ein_name3" editorOptions={{ disabled: ein_ausgewaehlt, readOnly: editable ? false : true }} colSpan={3}>
                                    <Label text="Name3" />
                                </Item>
                                <Item dataField="ein_str" editorOptions={{ disabled: ein_ausgewaehlt, readOnly: editable ? false : true }} colSpan={3}>
                                    <Label text="Straße" />
                                    <RequiredRule message="Straße wird benötigt" />
                                </Item>
                                <Item dataField="ein_plz" editorOptions={{
                                    disabled: ein_ausgewaehlt, readOnly: editable ? false : true

                                }} colSpan={1}>
                                    <Label text="PLZ" />
                                    <RequiredRule message="PLZ wird benötigt" />
                                </Item>
                                <Item dataField="ein_ort" editorOptions={{ disabled: ein_ausgewaehlt, readOnly: editable ? false : true }} colSpan={2}>
                                    <Label text="Ort" />
                                    <RequiredRule message="Ort wird benötigt" />
                                </Item>
                                <Item itemType="button" colSpan={3} buttonOptions={{
                                    visible: ein_ausgewaehlt,

                                    text: "Kundeninfos",
                                    onClick: function (e) {
                                        setpopup_kundeninfo_visible(true)
                                    }
                                }} />
                                <Item itemType="button" colSpan={3} buttonOptions={{
                                    visible: aw_vorhanden,
                                    text: "Arbeitsanweisungen",

                                    onClick: function (e) {
                                        setpopup_aw_visible(true)
                                    }
                                }} />

                            </GroupItem>
                            <GroupItem caption="Sonstiges" colCount={3} colSpan={1}>

                                <Item dataField="kostentraeger" editorType="dxSelectBox" editorOptions={kostentraeger_select_options} colSpan={3}>
                                    <Label text="Kostenträger" />
                                </Item>
                                <Item dataField="vertrag" colSpan={3}>
                                    <TextBox
                                        placeholder="kein Vertrag ausgewählt"
                                        value={vertrag_nr}
                                    >

                                        <TextBoxButton
                                            name="vertrag_button"
                                            location="after"

                                            options={{
                                                visible: editable,
                                                icon: 'find',
                                                type: 'default',
                                                onClick: () => {
                                                    setvertrag_popup_visible(true)
                                                }
                                            }}
                                        />
                                        <TextBoxButton
                                            name="vertrag_button_loeschen"
                                            location="after"

                                            options={{
                                                visible: editable,
                                                icon: 'clear',
                                                type: 'default',
                                                onClick: () => {
                                                    setvertrag_id()
                                                    setvertrag_nr()
                                                    setformData({ ...formData, vertrag: null, vertrag_nr: null })
                                                }
                                            }}
                                        />
                                    </TextBox>
                                    <Label text="Vertrag" />
                                </Item>
                                <Item dataField="rechnungslegung" editorType="dxSelectBox" editorOptions={{ items: ["manuell", "sofort"], readOnly: editable ? false : true }} colSpan={3}>
                                    <Label text="Rechnungslegung" />
                                </Item>
                                <Item dataField="fakturatext" editorType="dxTextArea" editorOptions={{ readOnly: editable ? false : true }} colSpan={3}>
                                    <Label text="Fakturatext" />
                                </Item>
                                <Item dataField="zentralfaktura" editorType="dxCheckBox" editorOptions={{ disabled: isAdmin ? false : true, readOnly: isAdmin ? false : true }} colSpan={3}>
                                    <Label text="Zentralfaktura" />
                                </Item>
                                <Item dataField="festpreisbetrag" editorType="dxNumberBox" editorOptions={{ readOnly: editable ? false : true }} colSpan={3}>
                                    <Label text="Festpreisbetrag" />
                                </Item>
                                <Item dataField="status_text" editorType="dxSelectBox" colSpan={3} editorOptions={{
                                    items: status_auswahl, onValueChanged: function (e) {

                                        if (e.previousValue != null && (e.value == "geschlossen" || e.value == "Abgebrochen" || e.value == "Storniert")) {
                                            console.log(e)
                                            setformData({ ...formData, status_text: e.value })
                                            const headers = new Headers();
                                            const bearer = `Bearer ${token}`;
                                            headers.append("Authorization", bearer);
                                            headers.append("Content-Type", "application/json;charset=UTF-8")
                                            console.log(formData)
                                            console.log(JSON.stringify(formData))
                                            const options = {
                                                method: "POST",
                                                headers: headers,
                                                body: JSON.stringify(formData)
                                            };

                                            return fetch(networkConfig.serverEndpoint + "api/auftrag/SetStatus", options)
                                                .then(response => response.json())
                                                .then(data => {
                                                    console.log(data)

                                                    if (data.success == true) {
                                                        notify({
                                                            message: 'Status wurde erfolgreich geändert',

                                                        }, 'success', 5000);
                                                        history.push("/auftrag/anzeigen");
                                                    } else {
                                                        setformData({ ...formData, status_text: e.previousValue })
                                                        notify({
                                                            message: data.userMessage,

                                                        }, 'error', 5000);
                                                    }
                                                }
                                                )
                                                .catch(error => console.log(error));


                                        }

                                    }
                                }}
                                >
                                    <Label text="Status" />

                                </Item>



                            </GroupItem>
                        </Tab>
                        <Tab title="Tourauftrag" colCount={1}>
                            <DataGrid
                                onInitNewRow={tourauftrag_anlegen}
                                dataSource={dataSource_Tourauftrag}
                                showBorders={true}
                            >
                                <Grouping autoExpandAll={false} />
                                <Sorting mode="multiple" />
                                <Editing
                                    mode="form"
                                    allowUpdating={false}
                                    allowDeleting={false}
                                    allowAdding={editable}
                                >
                                </Editing>
                                <FilterRow visible={true}
                                    applyFilter="auto" />
                                <HeaderFilter visible={false} />
                                <SearchPanel visible={true}
                                    width={240}
                                    placeholder="Suchen..." />
                                <Column
                                    dataField="tourauftrag_nr"
                                    caption="Tourauftrag"
                                />
                                <Column
                                    dataField="user_id"
                                    caption="Monteur"
                                >
                                    <Lookup dataSource={dataSource_user} displayExpr="name" valueExpr="benutzer_id" />
                                </Column>
                                <Column
                                    dataField="statecode"
                                    caption="Status"
                                >
                                    <Lookup dataSource={dataSource_statecode} displayExpr="bezeichnung" valueExpr="statecode_id" />
                                </Column>
                                <Column
                                    dataField="erwBeginn"
                                    caption="Beginn"
                                />
                                <Column
                                    dataField="active"
                                    caption="Aktiv"
                                />


                                <Column type="buttons">
                                    <Button
                                        text="bearbeiten"

                                        onClick={tourauftrag_oeffnen}
                                    />
                                </Column>




                            </DataGrid>
                        </Tab>
                        <Tab title="MoSchein" colCount={1}>
                            <DataGrid
                                onInitNewRow={moschein_anlegen}
                                dataSource={dataSource_MoSchein}
                                showBorders={true}

                            >
                                <Grouping autoExpandAll={false} />
                                <Sorting mode="multiple" />
                                <Editing
                                    mode="form"
                                    allowUpdating={false}
                                    allowDeleting={false}
                                    allowAdding={editable}
                                >
                                </Editing>
                                <FilterRow visible={true}
                                    applyFilter="auto" />
                                <HeaderFilter visible={false} />
                                <SearchPanel visible={true}
                                    width={240}
                                    placeholder="Suchen..." />
                                <Column
                                    dataField="moschein_nr"
                                    caption="Moschein"
                                />
                                <Column
                                    dataField="user_id"
                                    caption="Monteur"
                                >
                                    <Lookup dataSource={dataSource_user} displayExpr="name" valueExpr="benutzer_id" />
                                </Column>
                                <Column
                                    dataField="auftragsdatum"
                                    caption="Einsatzdatum"
                                />
                                <Column
                                    dataField="datum_unterschrift"
                                    caption="Datum Unterschrift"
                                />
                                <Column
                                    dataField="unterschrieben"
                                    caption="Unterschrieben"
                                />
                                <Column
                                    dataField="freigabe"
                                    caption="Freigabe"
                                />
                                <Column
                                    dataField="abschluss"
                                    caption="Abschluss"
                                />


                                <Column type="buttons">
                                    <Button
                                        text="bearbeiten"
                                        onClick={moschein_oeffnen}
                                    />
                                </Column>
                                <Column type="buttons">
                                    <Button
                                        text="PDF anzeigen"
                                        onClick={moschein_pdf_anzeigen}
                                    />
                                </Column>
                                <Column type="buttons" >
                                    <Button
                                        text="stornieren"
                                        onClick={moschein_stornieren}
                                        visible={visible_storno}
                                    />
                                </Column>




                            </DataGrid>
                        </Tab>
                    </TabbedItem>
                </Form>

            </div>

            <Popup
                visible={deb_popup_visible}

                dragEnabled={false}
                closeOnOutsideClick={false}
                showCloseButton={false}
                showTitle={true}
                title="Debitor"

                width={1200}
                height={800}
            >
                <Position
                    at="center"
                    my="center"

                />

                <ToolbarItem
                    widget="dxButton"
                    toolbar="bottom"
                    location="after"
                    options={{
                        text: 'Abbrechen',
                        disabled: false,
                        onClick: function (e) {
                            setdeb_popup_visible(false)

                        }
                    }}
                />
                <DataGrid
                    selection={{ mode: 'single' }}
                    dataSource={debdaten}
                    showBorders={true}
                    onSelectionChanged={deb_uebernehmen}
                    height={600}
                >
                    <Grouping autoExpandAll={false} />
                    <Sorting mode="multiple" />

                    <FilterRow visible={true}
                        applyFilter="auto" />
                    <HeaderFilter visible={false} />
                    <SearchPanel visible={true}
                        width={240}
                        placeholder="Suchen..." />
                    <Column
                        dataField="nr"
                        caption="Debitor-Nr"
                        defaultSortOrder="asc"
                    />
                    <Column
                        dataField="name"
                        caption="Name"
                    />
                    <Column
                        dataField="name2"
                        caption="Name2"
                    />
                    <Column
                        dataField="name3"
                        caption="Name3"
                    />
                    <Column
                        dataField="strasse"
                        caption="Straße"
                    />
                    <Column
                        dataField="plz"
                        caption="PLZ"
                    />
                    <Column
                        dataField="ort"
                        caption="Ort"
                    />

                </DataGrid>
            </Popup>
            <Popup
                visible={ein_popup_visible}

                dragEnabled={false}
                closeOnOutsideClick={false}
                showCloseButton={false}
                showTitle={true}
                title="Einsatzort"

                width={1200}
                height={800}
            >
                <Position
                    at="center"
                    my="center"

                />

                <ToolbarItem
                    widget="dxButton"
                    toolbar="bottom"
                    location="after"
                    options={{
                        text: 'Abbrechen',
                        disabled: false,
                        onClick: function (e) {
                            setein_popup_visible(false)

                        }
                    }}
                />
                <DataGrid
                    selection={{ mode: 'single' }}
                    dataSource={eindaten}
                    showBorders={true}
                    onSelectionChanged={ein_uebernehmen}
                    height={600}
                >
                    <Grouping autoExpandAll={false} />
                    <Sorting mode="multiple" />

                    <FilterRow visible={true}
                        applyFilter="auto" />
                    <HeaderFilter visible={false} />
                    <SearchPanel visible={true}
                        width={240}
                        placeholder="Suchen..." />
                    <Column
                        dataField="filiale_nr"
                        caption="Nr"
                        defaultSortOrder="asc"
                    />
                    <Column
                        dataField="name"
                        caption="Name"
                    />
                    <Column
                        dataField="name2"
                        caption="Name2"
                    />
                    <Column
                        dataField="name3"
                        caption="Name3"
                    />
                    <Column
                        dataField="strasse"
                        caption="Straße"
                    />
                    <Column
                        dataField="plz"
                        caption="PLZ"
                    />
                    <Column
                        dataField="ort"
                        caption="Ort"
                    />

                </DataGrid>
            </Popup>
            <Popup
                visible={vertrag_popup_visible}

                dragEnabled={false}
                closeOnOutsideClick={false}
                showCloseButton={false}
                showTitle={true}
                title="Vertrag"

                width={1200}
                height={800}
            >
                <Position
                    at="center"
                    my="center"

                />
                <ToolbarItem
                    widget="dxButton"
                    toolbar="bottom"
                    location="after"
                    options={{
                        text: 'Übernehmen',
                        disabled: false,
                        onClick: function (e) {
                            setformData({ ...formData, vertrag: vertrag_id, vertrag_nr: vertrag_nr })
                            setvertrag_popup_visible(false)

                        }
                    }}
                />
                <ToolbarItem
                    widget="dxButton"
                    toolbar="bottom"
                    location="after"
                    options={{
                        text: 'Abbrechen',
                        disabled: false,
                        onClick: function (e) {

                            setvertrag_popup_visible(false)
                        }
                    }}
                />
                <SelectBox dataSource={vertrag_dataSource}

                    displayExpr="vertrag_nr"
                    valueExpr="vertrag_id"

                    searchEnabled={true}
                    onValueChanged={function (e) {
                        setvertrag_id(e.value)
                        setvertrag_nr(e.component.option("text"))

                    }}
                />
                <Vertrag_edit_comp vertrag_id={vertrag_id} admin={false}></Vertrag_edit_comp>



            </Popup>



            <Popup
                visible={popup_aw_visible}
                onHiding={function (e) { setpopup_aw_visible(false) }}
                dragEnabled={false}
                closeOnOutsideClick={true}
                showCloseButton={true}
                showTitle={true}
                title="Arbeitsanweisungen"

                width={1200}
                height={800}
            >
                <Position
                    at="center"
                    my="center"

                />

                <ToolbarItem
                    widget="dxButton"
                    toolbar="bottom"
                    location="after"
                    options={{
                        text: 'Schließen',
                        disabled: false,
                        onClick: function (e) {
                            setpopup_aw_visible(false)
                        }
                    }}
                />
                <DataGrid
                    dataSource={aw_data}
                    showBorders={true}
                >
                    <Editing
                        mode="row"
                        allowAdding={false}
                        allowUpdating={false}
                        allowDeleting={false}
                    />
                    <Column
                        dataField="bezeichnung"
                        caption="Bezeichnung"
                    />

                    <Column type="buttons">
                        <Button
                            text="öffnen"
                            onClick={function (e) {
                                speichern();
                                //  e.component.deleteRow(e.row.rowIndex)
                                //  history.push("/pdf/GetAW/" + e.row.data.dokumentenname.toString());
                                if (isMobile) {
                                    //history.push("/pdf/GetAW/" + e.row.data.dokumentenname.toString());
                                } else {
                                    getToken_api().then(token => {
                                        setToken(token)
                                        const url = networkConfig.serverEndpoint + "api/moschein/GetAW?file=" + e.row.data.dokumentenname.toString()
                                        const options2 = {
                                            headers: {
                                                'Authorization': `Bearer ${token}`
                                            }
                                        };
                                        fetch(url, options2)
                                            .then(res => res.blob())
                                            .then(blob => {
                                                var file = window.URL.createObjectURL(blob);
                                                //  window.location.assign(file);
                                                // Browser.open({ url: file });
                                                window.open(file, '_blank').focus();
                                            });
                                    })


                                }
                            }}
                        />
                    </Column>
                </DataGrid>


            </Popup>
            <Popup
                visible={popup_kundeninfo_visible}
                onHiding={function (e) { setpopup_kundeninfo_visible(false) }}
                dragEnabled={false}
                closeOnOutsideClick={true}
                showCloseButton={true}
                showTitle={true}
                title="Kundeninfo"

                width={1200}
                height={800}
            >
                <Position
                    at="center"
                    my="center"

                />

                <ToolbarItem
                    widget="dxButton"
                    toolbar="bottom"
                    location="after"
                    options={{
                        text: 'Schließen',
                        disabled: false,
                        onClick: function (e) {
                            setpopup_kundeninfo_visible(false)
                        }
                    }}
                />

                <Form

                    formData={popup_kundeninfos_data}
                    labelLocation={'left'}
                    alignItemLabelsInAllGroups={false}
                    readOnly={true}
                >
                    <GroupItem caption="Allgemein" colCount={3}>
                        <Item dataField="filiale_nr" colSpan={3}>
                            <Label text="Filiale-Nr" />
                        </Item>
                        <Item dataField="name" colSpan={1}>
                            <Label text="Name" />
                        </Item>
                        <Item dataField="name2" colSpan={1}>
                            <Label text="Name2" />
                        </Item>
                        <Item dataField="name3" colSpan={1}>
                            <Label text="Name3" />
                        </Item>
                        <Item dataField="plz" colSpan={1}>
                            <Label text="PLZ" />
                        </Item>
                        <Item dataField="ort" colSpan={1}>
                            <Label text="Ort" />
                        </Item>
                        <Item dataField="bereich" colSpan={1}>
                            <Label text="OST/WEST" />
                        </Item>
                        <Item dataField="fsz" colSpan={3}>
                            <Label text="FSZ" />
                        </Item>
                        <Item dataField="callcenter" colSpan={3}>
                            <Label text="Callcenter" />
                        </Item>
                        <Item dataField="infos" colSpan={3}>
                            <Label text="Infos" />
                        </Item>
                        <Item dataField="kontakt_tel1" colSpan={1}>
                            <Label text="Telefon1" />
                        </Item>
                        <Item dataField="kontakt_tel2" colSpan={1}>
                            <Label text="Telefon2" />
                        </Item>
                        <Item dataField="kontakt_ansprechpartner" colSpan={1}>
                            <Label text="Ansprechpartner" />
                        </Item>
                    </GroupItem>
                </Form>

            </Popup>


        </React.Fragment>
    );
};
