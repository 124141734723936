import React, { useState, useEffect } from 'react';
import { getToken_api } from '../../api/auth';
import Form, { Item, ButtonItem, Label, RequiredRule, GroupItem, TabbedItem, TabPanelOptions, Tab } from 'devextreme-react/form';
import SelectBox from 'devextreme-react/select-box';
import ODataStore from 'devextreme/data/odata/store';
import 'devextreme-react/text-area';
import { TextBox, Button as TextBoxButton } from 'devextreme-react/text-box';
import DataGrid, { Column, Grouping, Sorting, FilterRow, HeaderFilter, SearchPanel, Editing, Lookup, Button, Form as GridForm } from 'devextreme-react/data-grid';
import { Popup, Position, ToolbarItem } from 'devextreme-react/popup';
import { useParams } from 'react-router-dom'
import Guid from 'devextreme/core/guid';
import notify from 'devextreme/ui/notify';
import { networkConfig } from '../../networkConfig';
import { Vertrag_edit_comp } from '../../components';
import { useHistory } from "react-router-dom";
import DateBox from 'devextreme-react/date-box';
import { NumberBox } from 'devextreme-react/number-box';
import { wegzeiten } from '../../api/wegzeit'
import SignatureCanvas from 'react-signature-canvas'
import DropDownButton from 'devextreme-react/drop-down-button';
import DataSource from 'devextreme/data/data_source';
export default function () {
    let { moschein_id } = useParams()
    let { typ } = useParams()
    const [token, setToken] = useState('')
    const [formData, setformData] = useState({})
    const [benutzer_select_options, setbenutzer_select_options] = useState({})
    const [entfernungszone_select_options, setentfernungszone_select_options] = useState('')
    const [auftrag_select_options, setauftrag_select_options] = useState('')
    const [dataSource_molohn, setdataSource_molohn] = useState('')
    const [dataSource_momat, setdataSource_momat] = useState('')
    const [dataSource_material_namen, setdataSource_material_namen] = useState([
        {
            "artikelnummer": "0068100593",
            "preis": 791,
            "bezeichnung": "Bördelgerät S-MM kompl. im Holzkoffer",
            "einheit": "Stck"
        },
        {
            "artikelnummer": "0112361770",
            "preis": 515,
            "bezeichnung": "Küba -Luftkühler SPB 23-F21 4462.23-21",
            "einheit": "Stck"
        },
        {
            "artikelnummer": "0130000699",
            "preis": 0,
            "bezeichnung": "kalkulat.Gewährleistungsrückstellung",
            "einheit": "EURO"
        },
        {
            "artikelnummer": "0130001998",
            "preis": 0,
            "bezeichnung": "Material Elektro",
            "einheit": "Stck"
        }])
    const [endeMin, setendeMin] = useState()
    const [begin_value, setbegin_value] = useState()
    const [ende_value, setende_value] = useState()
    const [formData_Mat, setformData_Mat] = useState({})
    const [arbeitsstunden_value, setarbeitsstunden_value] = useState(0)
    const [mat_disabled, setmat_disabled] = useState(false)
    const [datebox_options, setdatebox_options] = useState({})
    const [artikelnummer, setartikelnummer] = useState('')
    const [wartung, setWartung] = useState(false)
    const [freigegeben, setfreigegeben] = useState(false)
    const [abschluss, setabschluss] = useState(false)
    const [isAdmin, setisAdmin] = useState(false)

    const history = useHistory();

    const moscheinaenderung = function (e) {

        var data = { "user_id": formData.user_id, "tagdate": e }
        const headers = new Headers();
        const bearer = `Bearer ${token}`;
        headers.append("Authorization", bearer);
        headers.append("Content-Type", "application/json;charset=UTF-8")

        const options = {
            method: "POST",
            headers: headers,
            body: JSON.stringify(data)
        };


        return fetch(networkConfig.serverEndpoint + "api/wb/AenderungMoschein", options)
            .then(response => response.json())
            .then(data => {

                if (data.success == true) {


                } else {
                    notify({
                        message: data.userMessage,
                    }, 'error', 5000);
                }
            }
            )
            .catch(error => console.log(error));

    }
    const kundendatenabrufen = function (e) {

        const headers = new Headers();
        const bearer = `Bearer ${token}`;
        headers.append("Authorization", bearer);
        headers.append("Content-Type", "application/json;charset=UTF-8")

        const options = {
            method: "GET",
            headers: headers,

        };
        return fetch(networkConfig.serverEndpoint + "api/moschein/GetKunde?auftrag_id=" + formData.auftrag_id, options)
            .then(response => response.json())
            .then(data => {
                if (data.success == true) {
                    setformData({ ...formData, kundennummer: data.result_set.filiale_nr, kunden_name: data.result_set.name, kunden_strasse: data.result_set.strasse, kunden_plz: data.result_set.plz, kunden_ort: data.result_set.ort })
                } else {
                    notify({
                        message: 'Fehler :(',
                    }, 'error', 5000);
                }
            }
            )
            .catch(error => console.log(error));
    };

    useEffect(() => {

        getToken_api().then(token => {
            setToken(token)
            if (localStorage.getItem("roles").indexOf("admin;") != -1) {
                console.log(localStorage.getItem("roles"))
                setisAdmin(true)
            }
            const dataSource = new ODataStore({
                type: 'odata',
                url: networkConfig.dataEndpoint + 'moschein',
                key: 'moschein_id',
                withCredentials: true,
                beforeSend: function (e) {
                    e.headers = {
                        "Authorization": 'Bearer ' + token
                    };
                }
            });
            dataSource.load({ filter: ['moschein_id', '=', new Guid(moschein_id)] })
                .then(
                    (data) => {
                        console.log(data)
                        setformData(data[0]);
                        if (data[0].freigabe) {
                            setfreigegeben(true)
                        }
                        if (data[0].abschluss) {
                            setabschluss(true)
                        }
                        const store_user = new DataSource({
                            store: new ODataStore({
                                type: 'odata',
                                url: networkConfig.dataEndpoint + 'dispo_monteur_view',
                                key: 'benutzerbuero_id',
                                withCredentials: true,
                                beforeSend: function (e) {
                                    e.headers = {
                                        "Authorization": 'Bearer ' + token
                                    };
                                }
                            }), filter: ["buero_id", "=", data[0].buero_id], sort: ["name"]
                        });
                        //     store_user.load()
                        setbenutzer_select_options({ dataSource: store_user, displayExpr: "name", valueExpr: "benutzer_id", searchEnabled: true })

                    },
                    (error) => { /* Handle the "error" here */ }
                );


            const store1 = new ODataStore({
                type: 'odata',
                url: networkConfig.dataEndpoint + 'benutzer',
                key: 'benutzer_id',
                withCredentials: true,
                beforeSend: function (e) {
                    e.headers = {
                        "Authorization": 'Bearer ' + token
                    };
                },
            });


            const store2 = new ODataStore({
                type: 'odata',
                url: networkConfig.dataEndpoint + 'anfahrtszone',
                key: 'anfahrtszone_id',
                withCredentials: true,
                beforeSend: function (e) {
                    e.headers = {
                        "Authorization": 'Bearer ' + token
                    };
                },
            });

            setentfernungszone_select_options({ dataSource: { store: store2, sort: ["zone"] }, displayExpr: "zone", valueExpr: "zone" })

            const store3 = new ODataStore({
                type: 'odata',
                url: networkConfig.dataEndpoint + 'auftrag_aksn',
                key: 'auftrag_id',
                withCredentials: true,
                beforeSend: function (e) {
                    e.headers = {
                        "Authorization": 'Bearer ' + token
                    };
                },
            });

            setauftrag_select_options({ dataSource: { store: store3 }, displayExpr: "auftrag_nr", valueExpr: "auftrag_id" })

            setdataSource_molohn({
                store: {
                    type: 'odata',
                    url: networkConfig.dataEndpoint + 'molohn',
                    key: 'molohn_id',
                    withCredentials: true,
                    beforeSend: function (e) {
                        e.headers = {
                            "Authorization": 'Bearer ' + token
                        };
                    }
                }, filter: ["moschein_id", "=", new Guid(moschein_id)]

            })
            setdataSource_momat({
                store: {
                    type: 'odata',
                    url: networkConfig.dataEndpoint + 'momat',
                    key: 'momat_id',
                    withCredentials: true,
                    beforeSend: function (e) {
                        e.headers = {
                            "Authorization": 'Bearer ' + token
                        };
                    },
                }, filter: ["moschein_id", "=", new Guid(moschein_id)]

            })


            const headers = new Headers();
            const bearer = `Bearer ${token}`;
            headers.append("Authorization", bearer);
            headers.append("Content-Type", "application/json;charset=UTF-8")
            const options = {
                method: "POST",
                headers: headers,
            };


            return fetch(networkConfig.serverEndpoint + "api/moschein/getAllArtikel", options)
                .then(response => response.json())
                .then(data => {
                    if (data.success == true) {
                        setdataSource_material_namen(data.result_set)
                    }
                }
                )
                .catch(error => console.log(error));
        })

    }, []);

    let menge_change = function (newData, value, currentRowData) {
        newData.menge = value
        newData.preis_gesamt = (currentRowData.preis_einzel_ohneRabatt * (1 - currentRowData.rabatt / 100)) * value
        newData.preis_gesamt_ohneRabatt = (currentRowData.preis_einzel_ohneRabatt) * value
    };
    let einzel_preis_change = function (newData, value, currentRowData) {
        newData.preis_einzel_ohneRabatt = value
        newData.preis_gesamt_ohneRabatt = (currentRowData.menge) * value
        newData.preis_einzel = (value * (1 - currentRowData.rabatt / 100))
        newData.preis_gesamt = (value * (1 - currentRowData.rabatt / 100)) * currentRowData.menge
    };
    let gesamt_preis_change = function (newData, value, currentRowData) {
        newData.preis_gesamt_ohneRabatt = value
        newData.preis_einzel_ohneRabatt = value / (currentRowData.menge)
        newData.preis_einzel = ((value / (currentRowData.menge)) * (currentRowData.rabatt / 100))
        newData.preis_gesamt = (value * (1 - currentRowData.rabatt / 100))
    };
    let rabatt_change = function (newData, value, currentRowData) {
        newData.rabatt = value
        newData.preis_einzel = ((currentRowData.preis_einzel_ohneRabatt) * (1 - value / 100))
        newData.preis_gesamt = ((currentRowData.preis_einzel_ohneRabatt) * (1 - value / 100)) * currentRowData.menge

    };

    let freigeben_abschliessen = function (e) {
        const headers = new Headers();
        const bearer = `Bearer ${token}`;
        headers.append("Authorization", bearer);
        headers.append("Content-Type", "application/json;charset=UTF-8")

        const options = {
            method: "POST",
            headers: headers,
            body: JSON.stringify(formData)
        };

        if (e.itemData == "Freigeben") {
            return fetch(networkConfig.serverEndpoint + "api/moschein/SetFreigabe", options)
                .then(response => response.json())
                .then(data => {
                    if (data.success == true) {
                        notify({
                            message: 'MoSchein wurde erfolgreich freigegeben',
                        }, 'success', 5000);
                        if (typ == "abschluss") {
                            history.push("/moschein/abschliessen/abschluss");
                        } else if (typ == "freigabe") {
                            history.push("/moschein/abschliessen/freigabe");
                        } else {
                            history.push("/auftrag/bearbeiten/" + data.result_set.auftrag_id);
                        }

                    } else {
                        notify({
                            message: data.userMessage,
                        }, 'error', 5000);
                    }
                }
                )
                .catch(error => console.log(error));
        }


        if (e.itemData == "Abschließen") {
            return fetch(networkConfig.serverEndpoint + "api/moschein/SetAbschluss", options)
                .then(response => response.json())
                .then(data => {
                    if (data.success == true) {
                        notify({
                            message: 'MoSchein wurde erfolgreich abgeschlossen',
                        }, 'success', 5000);
                        if (typ == "abschluss") {
                            history.push("/moschein/abschliessen/abschluss");
                        } else if (typ == "freigabe") {
                            history.push("/moschein/abschliessen/freigabe");
                        } else {
                            history.push("/auftrag/bearbeiten/" + data.result_set.auftrag_id);
                        }

                    } else {
                        notify({
                            message: data.userMessage,
                        }, 'error', 5000);
                    }
                }
                )
                .catch(error => console.log(error));
        }
    }
    let speichern = function (e) {

        const headers = new Headers();
        const bearer = `Bearer ${token}`;
        headers.append("Authorization", bearer);
        headers.append("Content-Type", "application/json;charset=UTF-8")
        const options = {
            method: "POST",
            headers: headers,
            body: JSON.stringify(formData)
        };
        return fetch(networkConfig.serverEndpoint + "api/moschein/UpdateMoschein", options)
            .then(response => response.json())
            .then(data => {
                if (data.success == true) {
                    notify({
                        message: 'MoSchein wurde erfolgreich gespeichert',
                    }, 'success', 5000);
                    if (e.itemData == "Speichern und schließen") {
                        if (typ == "abschluss") {
                            history.push("/moschein/abschliessen/abschluss");
                        } else if (typ == "freigabe") {
                            history.push("/moschein/abschliessen/freigabe");
                        } else {
                            history.push("/auftrag/bearbeiten/" + data.result_set.auftrag_id);
                        }

                    }
                } else {
                    notify({
                        message: 'Fehler :(',
                    }, 'error', 5000);
                }
            }
            )
            .catch(error => console.log(error));
    };
    const begin_aendern = function (e) {
        const zeiteintrag = new Date(e.value);
        if (zeiteintrag != undefined) {
            if (zeiteintrag.getMinutes() != 0 && zeiteintrag.getMinutes() != 15 && zeiteintrag.getMinutes() != 30 && zeiteintrag.getMinutes() != 45) {
                if (zeiteintrag.getMinutes() != 59 || zeiteintrag.getHours() != 23) {
                    if (Math.round((zeiteintrag.getMinutes() / 15)) == 4) {
                        zeiteintrag.setMinutes(0);
                        zeiteintrag.setHours(zeiteintrag.getHours() + 1);
                        e.component.option("value", zeiteintrag);
                    };
                    if (Math.round((zeiteintrag.getMinutes() / 15)) == 3) {
                        zeiteintrag.setMinutes(45);
                        e.component.option("value", zeiteintrag);
                    };
                    if (Math.round((zeiteintrag.getMinutes() / 15)) == 2) {
                        zeiteintrag.setMinutes(30);
                        e.component.option("value", zeiteintrag);

                    };

                    if (Math.round((zeiteintrag.getMinutes() / 15)) == 1) {
                        zeiteintrag.setMinutes(15);
                        e.component.option("value", zeiteintrag);

                    };
                    if (Math.round((zeiteintrag.getMinutes() / 15)) == 0) {
                        zeiteintrag.setMinutes(0);
                        e.component.option("value", zeiteintrag);

                    };
                };

            };
        };
        if (ende_value != null) {
            var diffInhour = (((ende_value - zeiteintrag) / (60 * 60 * 1000)));
            setarbeitsstunden_value((Math.round((diffInhour) * 4)) / 4);
        }

        setbegin_value(zeiteintrag);
        setendeMin(zeiteintrag);

    };
    const ende_aendern = function (e) {
        const zeiteintrag = new Date(e.value);
        if (zeiteintrag != undefined) {
            if (zeiteintrag.getMinutes() != 0 && zeiteintrag.getMinutes() != 15 && zeiteintrag.getMinutes() != 30 && zeiteintrag.getMinutes() != 45) {
                if (zeiteintrag.getMinutes() != 59 || zeiteintrag.getHours() != 23) {
                    if (Math.round((zeiteintrag.getMinutes() / 15)) == 4) {
                        zeiteintrag.setMinutes(0);
                        zeiteintrag.setHours(zeiteintrag.getHours() + 1);
                        e.component.option("value", zeiteintrag);
                    };
                    if (Math.round((zeiteintrag.getMinutes() / 15)) == 3) {
                        zeiteintrag.setMinutes(45);
                        e.component.option("value", zeiteintrag);
                    };
                    if (Math.round((zeiteintrag.getMinutes() / 15)) == 2) {
                        zeiteintrag.setMinutes(30);
                        e.component.option("value", zeiteintrag);

                    };

                    if (Math.round((zeiteintrag.getMinutes() / 15)) == 1) {
                        zeiteintrag.setMinutes(15);
                        e.component.option("value", zeiteintrag);

                    };
                    if (Math.round((zeiteintrag.getMinutes() / 15)) == 0) {
                        zeiteintrag.setMinutes(0);
                        e.component.option("value", zeiteintrag);

                    };
                };

            };
        };
        if (begin_value != null) {
            var diffInhour = (((zeiteintrag - begin_value) / (60 * 60 * 1000)));
            setarbeitsstunden_value((Math.round((diffInhour) * 4)) / 4);
        }

        setende_value(zeiteintrag);


    };
    var sigPad = {};
    return (

        <React.Fragment>
            <div className="dx-toolbar-items-container" style={{ height: "60px" }}>

                <div className="dx-toolbar-before">
                    <h2 className={'content-block'}>MoSchein {formData.moschein_nr}</h2>
                </div>
                <div className="dx-toolbar-after" style={{ "paddingTop": "20px", "paddingRight": "40px" }}>

                    <DropDownButton
                        text="Freigeben/Abschließen"
                        icon="todo"
                        dropDownOptions={{ width: 230 }}
                        items={["Freigeben", "Abschließen"]}
                        onItemClick={freigeben_abschliessen}

                    />


                    <DropDownButton
                        text="Speichern"
                        icon="save"
                        type='success'
                        dropDownOptions={{ width: 230 }}
                        items={["Speichern", "Speichern und schließen"]}
                        onItemClick={speichern}

                    />

                </div>




            </div>

            <div className={'content-block dx-card responsive-paddings'}>
                <Form
                    id={'form'}
                    formData={formData}
                    labelLocation={'left'}
                    readOnly={abschluss}
                    alignItemLabelsInAllGroups={false}
                    validationGroup="AuftragData"
                >
                    <TabbedItem>
                        <TabPanelOptions deferRendering={false} />
                        <Tab title="Allgemein" colCount={2}>
                            <GroupItem caption="Allgemeine Informationen">
                                <Item dataField="moschein_nr" editorOptions={{ disabled: true }}>
                                    <Label text="MoSchein-Nr" />
                                </Item>
                                <Item dataField="user_id" editorType="dxSelectBox" editorOptions={benutzer_select_options}>
                                    <Label text="Monteur" />
                                </Item>
                                <Item dataField="auftragsdatum" editorType="dxDateBox" editorOptions={{}}>
                                    <Label text="Einsatzdatum" />
                                    <RequiredRule message="Einsatzdatum wird benötigt" />
                                </Item>
                                <Item dataField="festpreis" editorType="dxCheckBox" editorOptions={{}}>
                                    <Label text="Festpreisauftrag" />
                                </Item>
                                <Item dataField="wartung" editorType="dxCheckBox" editorOptions={{
                                    onValueChanged: function (e) {
                                        if (e.value != null || e.value == '') {
                                            setWartung(e.value)
                                        }
                                    }
                                }}>
                                    <Label text="Wartung" />
                                </Item>
                                <Item dataField="wartung_vdma" visible={wartung} editorType="dxCheckBox" editorOptions={{}}>
                                    <Label text="Wartung nach VDMA 24186" />
                                </Item>
                                <Item dataField="wartungsdatun" visible={wartung} editorType="dxDateBox" editorOptions={{ value: new Date() }}>
                                    <Label text="Wartungsdatum" />

                                </Item>
                                <Item dataField="objektbez" visible={wartung} >
                                    <Label text="Objektbezeichnung" />
                                </Item>
                            </GroupItem>

                            <GroupItem caption="Sonstige Informationen" >
                                <Item dataField="kostentraeger" >
                                    <Label text="Kostenträger" />
                                </Item>
                                <Item dataField="vertragsnummer" >
                                    <Label text="Vertragsnummer" />
                                </Item>
                                <Item dataField="stoercode" >
                                    <Label text="Störcode" />
                                </Item>
                                <Item dataField="kkl_objekt_nummer" visible={wartung ? false : true} >
                                    <Label text="KKL Objekt-Nummer" />
                                </Item>
                                <Item dataField="kuehlstellen_standortnummer" visible={wartung ? false : true}>
                                    <Label text="Kühlstellenstandortnummer" />
                                </Item>
                                <Item dataField="akg" visible={wartung ? false : true}>
                                    <Label text="AKG" />
                                </Item>
                                <Item dataField="lieferant_dka" visible={wartung ? false : true}>
                                    <Label text="Lieferant DKA" />
                                </Item>
                                <Item dataField="hersteller" visible={wartung ? false : true}>
                                    <Label text="Hersteller" />
                                </Item>
                                <Item dataField="anlagen_moebel_type" visible={wartung ? false : true}>
                                    <Label text="Anlagen-Möbel-Typ" />
                                </Item>


                            </GroupItem>

                        </Tab>
                        <Tab title="Kunde" colCount={3}>
                            <GroupItem caption="Kunde" >
                                <Item dataField="kundennummer" >
                                    <Label text="Kundennummer" />
                                    <TextBox
                                        value={formData.kundennummer}
                                        onValueChanged={function (e) { setformData({ ...formData, kundennummer: e.value }) }}
                                    >
                                        <TextBoxButton
                                            name="kundendatenabrufen_button"
                                            location="after"
                                            options={{
                                                text: 'Abrufen',
                                                type: 'default',
                                                onClick: kundendatenabrufen
                                            }}
                                        />
                                    </TextBox>

                                </Item>
                                <Item dataField="kunden_name" >
                                    <Label text="Name" />
                                </Item>

                            </GroupItem>
                            <GroupItem caption="Anschrift" >
                                <Item dataField="kunden_strasse" >
                                    <Label text="Straße" />
                                </Item>
                                <Item dataField="kunden_plz" >
                                    <Label text="PLZ" />
                                </Item>
                                <Item dataField="kunden_ort" >
                                    <Label text="ORT" />
                                </Item>

                            </GroupItem>
                            <GroupItem caption="Sonstiges" >
                                <Item dataField="kundeninformation" editorType="dxTextArea" editorOptions={{ height: '200' }} >
                                    <Label text="Kundeninformation" />
                                </Item>
                                <Item dataField="entfernungszone" editorType="dxSelectBox" editorOptions={entfernungszone_select_options}>
                                    <Label text="Entfernungszone" />
                                </Item>

                            </GroupItem>
                        </Tab>
                        <Tab title="Zeiten" colCount={1}>
                            <DataGrid
                                dataSource={dataSource_molohn}
                                showBorders={true}

                                onEditingStart={function (e) {
                                
                                    setbegin_value(new Date(e.data.beginn))
                                    setende_value(new Date(e.data.ende))
                                    var diffInhour = (((new Date(e.data.ende) - new Date(e.data.beginn)) / (60 * 60 * 1000)));
                                    setarbeitsstunden_value((Math.round((diffInhour) * 4)) / 4);
                                }}
                                onEditorPreparing={function (e) {
                                    if (e.dataField == "datum" && e.parentType === "dataRow") {
                                      
                                        if (e.component.getVisibleRows().length > 1) {
                                            if (e.value == undefined) {
                                                e.setValue(e.component.getVisibleRows()[1].data.datum)

                                                var jetzt = new Date(e.component.getVisibleRows()[1].data.datum);
                                                var jetzt1 = new Date(e.component.getVisibleRows()[1].data.datum);
                                                var jetzt2 = new Date(e.component.getVisibleRows()[1].data.datum);

                                                e.editorOptions.min = jetzt
                                                e.editorOptions.max = new Date(jetzt1.setDate(jetzt2.getDate() + 1))
                                                e.editorOptions.value = jetzt
                                            }



                                            ;
                                        }
                                        else {
                                            if (e.value == undefined) {
                                            e.setValue(formData.auftragsdatum)
                                            //Beginn nach letztem Moschein festlegen
                                            e.editorOptions.value = formData.auftragsdatum;
                                            }
                                        };


                                    };


                                }}

                                onInitNewRow={function (info) {
                                    info.data.moschein_id = moschein_id;
                                    info.data.wegzeit = 0;
                                    info.data.kilometer = 0;

                                    setbegin_value(null)
                                    setende_value(null)
                                }}
                                onRowRemoving={function (e) {
                                    moscheinaenderung(e.data.beginn)
                                }}

                                onRowInserting={function (e) {

                                    e.data.beginn = new Date(e.data.datum.getFullYear(), e.data.datum.getMonth(), e.data.datum.getDate(), begin_value.getHours(), begin_value.getMinutes())
                                    e.data.ende = new Date(e.data.datum.getFullYear(), e.data.datum.getMonth(), e.data.datum.getDate(), ende_value.getHours(), ende_value.getMinutes())
                                    e.data.arbeitsstunden = arbeitsstunden_value

                                    moscheinaenderung(e.data.beginn)
                                }}
                                onRowUpdating={function (e) {

                                    console.log(e)
                                    var veraenderung = false;
                                    if (e.newData.datum != undefined) {
                                        e.newData.beginn = new Date(e.newData.datum.getFullYear(), e.newData.datum.getMonth(), e.newData.datum.getDate(), begin_value.getHours(), begin_value.getMinutes())
                                        e.newData.ende = new Date(e.newData.datum.getFullYear(), e.newData.datum.getMonth(), e.newData.datum.getDate(), ende_value.getHours(), ende_value.getMinutes())
                                        if (e.newData.datum.toDateString() != e.oldData.datum.toDateString()) { veraenderung = true }
                                        if (e.newData.beginn.toTimeString() != e.oldData.beginn.toTimeString()) { veraenderung = true }
                                        if (e.newData.ende.toTimeString() != e.oldData.ende.toTimeString()) { veraenderung = true }

                                    } else {
                                        e.newData.beginn = new Date(e.oldData.datum.getFullYear(), e.oldData.datum.getMonth(), e.oldData.datum.getDate(), begin_value.getHours(), begin_value.getMinutes())
                                        e.newData.ende = new Date(e.oldData.datum.getFullYear(), e.oldData.datum.getMonth(), e.oldData.datum.getDate(), ende_value.getHours(), ende_value.getMinutes())
                                        if (e.newData.beginn.toTimeString() != e.oldData.beginn.toTimeString()) { veraenderung = true }
                                        if (e.newData.ende.toTimeString() != e.oldData.ende.toTimeString()) { veraenderung = true }
                                    }
                                    if (e.newData.kilometer != undefined) {
                                        if (e.newData.kilometer != e.oldData.kilometer) {
                                            veraenderung = true;
                                        }
                                    }
                                    if (e.newData.wegzeit != undefined) {
                                        if (e.newData.wegzeit != e.oldData.wegzeit) {
                                            veraenderung = true;
                                        }
                                    }
                                    if (veraenderung == true) {

                                        moscheinaenderung(e.newData.beginn)
                                        console.log(veraenderung)
                                    }

                                    e.newData.arbeitsstunden = arbeitsstunden_value
                                }}


                            >

                                <Sorting mode="multiple" />
                                <Editing
                                    mode="form"
                                    allowUpdating={freigegeben ? false : true}
                                    allowDeleting={freigegeben ? false : true}
                                    allowAdding={freigegeben ? false : true}
                                >
                                    <GridForm title="Zeiten" showTitle={false} colCount={3} >
                                        <GroupItem caption="Zeit" >
                                            <Item dataField="datum" editorType="dxDateBox">
                                                <Label text="Datum" />
                                                <RequiredRule message="Datum wird benötigt" />
                                            </Item>
                                            <Item dataField="beginn" >
                                                <Label text="Beginn" />
                                                <DateBox type="time"
                                                    value={begin_value}
                                                    interval={15} onValueChanged={begin_aendern}>
                                                </DateBox>
                                                <RequiredRule message="Beginn wird benötigt" />
                                            </Item>
                                            <Item dataField="ende" value={ende_value}>
                                                <DateBox type="time"
                                                    value={ende_value}
                                                    interval={15} min={endeMin} onValueChanged={ende_aendern}>
                                                </DateBox>
                                                <Label text="Ende" />
                                                <RequiredRule message="Ende wird benötigt" />
                                            </Item>
                                        </GroupItem>
                                        <GroupItem caption="Anfahrt" >
                                            <Item dataField="wegzeit" editorType="dxSelectBox" editorOptions={{
                                                dataSource: wegzeiten,
                                                valueExpr: "min",
                                                displayExpr: "anzeige"
                                            }} >
                                                <Label text="Wegzeit" />
                                            </Item>
                                            <Item dataField="kilometer" editorType="dxNumberBox" >
                                                <Label text="Kilometer" />
                                            </Item>
                                        </GroupItem>
                                        <GroupItem caption="Summen" >
                                            <Item dataField="arbeitsstunden" value={arbeitsstunden_value}>
                                                <NumberBox value={arbeitsstunden_value}>
                                                </NumberBox>
                                                <Label text="Arbeitsstunden" />
                                            </Item>
                                            <Item dataField="ueberstunden" editorType="dxNumberBox" >
                                                <Label text="dav. Überstunden" />
                                            </Item>
                                            <Item dataField="erschaernis_h" editorType="dxNumberBox" >
                                                <Label text="Erschwernis-h" />
                                            </Item>
                                            <Item dataField="erschwaernis_preis" editorType="dxNumberBox">
                                                <Label text="Erschwernis-Preis" />
                                            </Item>
                                            <Item dataField="eilzuschlag" editorType="dxNumberBox">
                                                <Label text="Eilzuschlag" />
                                            </Item>
                                            <Item dataField="preis" editorType="dxNumberBox">
                                                <Label text="Gesamtpreis" />
                                            </Item>
                                        </GroupItem>
                                    </GridForm>
                                </Editing>

                                <Column
                                    dataField="datum"
                                    caption="Datum"
                                    dataType="date"
                                    format="shortdate"
                                    caption="Datum"

                                />
                                <Column
                                    dataField="beginn"
                                    caption="Beginn"
                                    dataType="datetime"
                                    format="HH:mm 'Uhr'"
                                    defaultSortOrder="asc"
                                />

                                <Column
                                    dataField="ende"
                                    caption="Ende"
                                    dataType="datetime"
                                    format="HH:mm 'Uhr'"
                                />
                                <Column
                                    dataField="wegzeit"
                                    caption="Wegzeit"
                                    dataType="number"
                                    format="#0.## min"
                                />
                                <Column
                                    dataField="kilometer"
                                    caption="Kilometer"
                                    dataType="number"
                                    format="#0.## km"
                                />
                                <Column
                                    dataField="arbeitsstunden"
                                    caption="Arbeitsstunden"
                                    dataType="number"
                                    format="#0.## h"
                                />
                                <Column
                                    dataField="ueberstanden"
                                    caption="davon Überst."
                                    dataType="number"
                                    format="#0.## h"
                                />
                                <Column
                                    dataField="erschwaernis_h"
                                    caption="Erschwer.-h"
                                    dataType="number"
                                    format="#0.## h"
                                />
                                <Column
                                    dataField="erschwaernis_preis"
                                    caption="Erschwer.-€"
                                    dataType="number"
                                    format="#0.## €"
                                />
                                <Column
                                    dataField="eilzuschlag"
                                    caption="Eilzuschlag"
                                    dataType="number"
                                    format="#0.## h"
                                />
                                <Column
                                    dataField="preis"
                                    caption="Gesamt-Preis"
                                    dataType="number"
                                    format="#0.## €"
                                />

                            </DataGrid>
                        </Tab>
                        <Tab title="Material" colCount={1}>
                            <DataGrid
                                dataSource={dataSource_momat}
                                showBorders={true}
                                onEditingStart={function (e) {
                                    debugger;
                                    if (e.data.artikelnummer != '0130019999' && e.data.artikelnummer != '' && e.data.artikelnummer != null) {
                                        setmat_disabled(true)
                                    } else {
                                        setmat_disabled(false)
                                    }

                                }}
                                onInitNewRow={function (info) {
                                    setmat_disabled(false)
                                    info.data.moschein_id = new Guid(moschein_id);
                                    info.data.rabatt = 0;
                                    info.data.preis_einzel_ohneRabatt = 0;
                                    info.data.preis_einzel = 0;
                                    info.data.preis_gesamt = 0;
                                    info.data.preis_gesamt_ohneRabatt = 0;
                                    info.data.position = info.component.getVisibleRows().length + 1;
                                }}
                            >

                                <Editing
                                    mode="form"
                                    allowUpdating={abschluss ? false : true}
                                    allowDeleting={abschluss ? false : true}
                                    allowAdding={abschluss ? false : true}
                                >
                                    <GridForm title="Artikel" showTitle={false} colCount={2} >
                                        <GroupItem caption="Artikel" >
                                            <Item dataField="position" dataType="number" >
                                                <Label text="Posistion" />
                                            </Item>
                                            <Item dataField="artikelnummer" >
                                                <Label text="Artikelnummer" />
                                            </Item>
                                            <Item dataField="kurzbezeichnung" editorType="dxAutocomplete" editorOptions={{ dataSource: dataSource_material_namen, displayExpr: "bezeichnung", valueExpr: "bezeichnung", disabled: mat_disabled, readOnly: mat_disabled }} >
                                                <Label text="Kurzbezeichnung" />
                                                <RequiredRule message="Kurzbezeichnung wird benötigt" />
                                            </Item>
                                        </GroupItem>
                                        <GroupItem caption="Menge/Preis" >
                                            <Item dataField="menge" editorOptions={{
                                            }} >
                                                <Label text="Menge" />
                                            </Item>
                                            <Item dataField="einheit" editorType="dxSelectBox" editorOptions={{
                                                dataSource: ['Kg', 'm', 'Pack', 'Pal', 'Stck'], disabled: mat_disabled, readOnly: mat_disabled
                                            }} >
                                                <Label text="Einheit" />
                                            </Item>

                                            <Item dataField="preis_einzel_ohneRabatt" editorType="dxNumberBox">
                                                <Label text="Einzel-Preis o.Rabatt" />
                                            </Item>

                                            <Item dataField="preis_gesamt_ohneRabatt" editorType="dxNumberBox" >
                                                <Label text="Gesamt-Preis o. Rabatt" />
                                            </Item>
                                            <Item dataField="rabatt" editorType="dxNumberBox" >
                                                <Label text="Rabatt" />
                                            </Item>
                                            <Item dataField="preis_einzel" editorType="dxNumberBox" editorOptions={{ readOnly: true, disabled: true }}>
                                                <Label text="Einzel-Preis" />
                                            </Item>
                                            <Item dataField="preis_gesamt" editorType="dxNumberBox" editorOptions={{ readOnly: true, disabled: true }}>
                                                <Label text="Gesamt-Preis" />
                                            </Item>

                                        </GroupItem>
                                    </GridForm>
                                </Editing>

                                <Column
                                    dataField="position"
                                    caption="Position"
                                    defaultSortOrder="asc"
                                />
                                <Column
                                    dataField="kurzbezeichnung"
                                    caption="Kurzbezeichnung"
                                    setCellValue={function (newData, value, currentRowData) {
                                        newData.kurzbezeichnung = value
                                        if (newData.kurzbezeichnung != null) {

                                            const headers = new Headers();
                                            const bearer = `Bearer ${token}`;
                                            headers.append("Authorization", bearer);
                                            headers.append("Content-Type", "application/json;charset=UTF-8")

                                            const options = {
                                                method: "POST",
                                                headers: headers,

                                            };


                                            return fetch(networkConfig.serverEndpoint + "api/moschein/getArtikelbyName?artikelname=" + newData.kurzbezeichnung, options)
                                                .then(response => response.json())
                                                .then(data => {

                                                    newData.artikelnummer = data.result_set.artikelnummer;
                                                    newData.einheit = data.result_set.einheit;

                                                    if (data.result_set.artikelnummer != undefined && data.result_set.artikelnummer != '0130019999' ) {
                                                        setmat_disabled(true)
                                                    }
                                                }
                                                )
                                                .catch(error => console.log(error));


                                        }

                                    }}
                                />

                                <Column
                                    dataField="artikelnummer"
                                    caption="Artikelnummer"
                                    setCellValue={function (newData, value, currentRowData) {
                                        newData.artikelnummer = value
                                        if (value != '0130019999' && value != '') {
                                            setmat_disabled(true)

                                            if (newData.artikelnummer.length >= 10) {

                                                const headers = new Headers();
                                                const bearer = `Bearer ${token}`;
                                                headers.append("Authorization", bearer);
                                                headers.append("Content-Type", "application/json;charset=UTF-8")

                                                const options = {
                                                    method: "POST",
                                                    headers: headers,

                                                };


                                                return fetch(networkConfig.serverEndpoint + "api/moschein/getArtikel?artikelnummer=" + newData.artikelnummer, options)
                                                    .then(response => response.json())
                                                    .then(data => {

                                                        newData.kurzbezeichnung = data.result_set.bezeichnung;
                                                        newData.einheit = data.result_set.einheit;
                                                    }
                                                    )
                                                    .catch(error => console.log(error));


                                            }
                                        } else {
                                            setmat_disabled(false)
                                        }



                                    }}
                                />
                                <Column
                                    dataField="menge"
                                    caption="menge"
                                    setCellValue={menge_change}
                                />
                                <Column
                                    dataField="einheit"
                                    caption="Einheit"
                                />
                                <Column
                                    dataField="preis_einzel_ohneRabatt"
                                    caption="Einzel-Preis o. Rabatt"
                                    setCellValue={einzel_preis_change}
                                />
                                <Column
                                    dataField="preis_gesamt_ohneRabatt"
                                    caption="Einzel-Preis o. Rabatt"
                                    setCellValue={gesamt_preis_change}
                                    visible={false}
                                />
                                <Column
                                    dataField="rabatt"
                                    caption="Rabatt"
                                    setCellValue={rabatt_change}
                                />
                                <Column
                                    dataField="preis_einzel"
                                    caption="Einzel-Preis"
                                />
                                <Column
                                    dataField="preis_gesamt"
                                    caption="Gesamt-Preis"
                                />


                            </DataGrid>
                        </Tab>
                        <Tab title="Arbeitsbericht" colCount={3}>

                            <Item dataField="arbeitsbericht" colSpan={3}>
                                <Label text="Arbeitsbericht" />
                            </Item>
                            <Item dataField="auslagen" colSpan={3}>
                                <Label text="Auslagen" />
                            </Item>


                            <GroupItem caption="Dichtheitsprüfung" >
                                <Item dataField="dichtheitspruefung_erfolgt" editorType="dxCheckBox"  >
                                    <Label text="ausgeführt" />
                                </Item>
                                <Item dataField="dichtheitspruefung_undicht" >
                                    <Label text="undichte Stellen" />
                                </Item>

                            </GroupItem>
                            <GroupItem caption="Material" >
                                <Item dataField="material_bestellungen_erfolgt" editorType="dxCheckBox" >
                                    <Label text="bestellt" />
                                </Item>
                                <Item dataField="material_bestellungen_bezeichnung" >
                                    <Label text="bestelltes Material" />
                                </Item>

                            </GroupItem>
                            <GroupItem caption="Nachfolgeauftrag/Vertrieb"  >
                                <Item dataField="leistung_beendet" editorType="dxCheckBox" >
                                    <Label text="Leistung beendet (B-Schein)" />
                                </Item>
                                <Item dataField="nachfolgeauftrag_notwendig" editorType="dxCheckBox" >
                                    <Label text="Nachfolgeauftrag notwendig (A-Schein)" />
                                </Item>
                                <Item dataField="vertrieb_gewuenscht" editorType="dxCheckBox" >
                                    <Label text="Vertrieb gewünscht" />
                                </Item>

                            </GroupItem>
                        </Tab>
                        <Tab title="Barzahler" colCount={1}>

                            <Item dataField="preis_summe_netto">
                                <Label text="Summe Netto" />
                            </Item>
                            <Item dataField="preis_summe_mwst">
                                <Label text="Summe MwSt." />
                            </Item>
                            <Item dataField="preis_summe_brutto">
                                <Label text="Summe Brutto" />
                            </Item>
                            <Item dataField="preis_summe_brutto">
                                <Label text="Summe Brutto" />
                            </Item>
                            <Item dataField="barzahlung" editorType="dxCheckBox" >
                                <Label text="Barzahlung" />
                            </Item>

                            <Item dataField="barzahlung_summe">
                                <Label text="erhaltener Betrag" />
                            </Item>


                        </Tab>
                        <Tab title="Faktura" colCount={1}>

                            <Item dataField="anfahrt_pauschale" editorType="dxNumberBox">
                                <Label text="Anfahrtspauschale" />
                            </Item>
                            <Item dataField="anfahrt_zone" editorType="dxNumberBox">
                                <Label text="Anfahrtszone" />
                            </Item>
                            <Item dataField="molohn_abrechnen" editorType="dxCheckBox" >
                                <Label text="Arbeitszeiten fakturieren" />
                            </Item>
                            <Item dataField="anfahrt_abrechnen" editorType="dxCheckBox" >
                                <Label text="Anfahrt fakturieren" />
                            </Item>



                        </Tab>
                        <Tab title="Admin" colCount={1} visible={isAdmin}>

                            <Item dataField="auftrag_id" editorType="dxSelectBox" editorOptions={auftrag_select_options}>
                                <Label text="Auftragsnummer" />
                            </Item>
                            <Item dataField="freigabe" editorType="dxCheckBox" >
                                <Label text="Meisterfreigabe" />
                            </Item>
                            <Item dataField="abschluss" editorType="dxCheckBox" >
                                <Label text="Abgeschlossen" />
                            </Item>



                        </Tab>
                    </TabbedItem>
                </Form>

            </div>
            <SignatureCanvas penColor='blue' canvasProps={{ width: 500, height: 200, className: 'sigCanvas' }} ref={(ref) => { sigPad = ref }} />



        </React.Fragment>
    );
};
