import PublicClientApplication from 'react-native-msal';
import { msalConfig, loginRequest_api, loginRequest_user, options_api, options } from '../authConfig';
import { Msal } from 'ionic-msal-native';
import { isMobile } from "react-device-detect";
import { EmailRule } from 'devextreme-react/data-grid';
import { callMsGraph } from '../api/graph';

const pca = new PublicClientApplication(msalConfig);
const msal = new Msal()


export async function getToken_user() {
  try {
    if (isMobile) {
    
      let msalInit = msal.msalInit(options)
      let msalInit_Result = await msalInit;
      var signinResult = msal.signInSilent().then((signinResult) => {
        return signinResult;
      }, (err) => {
        return []
      })

      return signinResult


    } else {
  
      const account = await getAccount()
      const params = loginRequest_user;
      params.account = account.data
      const result = await pca.acquireTokenSilent(params);
      return result.accessToken;



    };
  }
  catch {
  await signIn();
    return [];
  }
}

export async function getToken_api() {
  try {
    if (isMobile) {

      var initresult = await msal.msalInit(options_api);
      if (initresult.toString() == 'OK') {
        var signinResult = await msal.signInSilent()

        localStorage.setItem('token',signinResult)
        return signinResult.toString();
      }
      else {
        console.log('ERROR AUTH')
        return ''
      }
    
    } else {
      const account = await getAccount()
      const params = loginRequest_api;
      params.account = account.data
      const result = await pca.acquireTokenSilent(params);
      // Send request
  
      return result.accessToken;



    };
  }
  catch {
    await signIn();
   return [];
  }
}

export async function signIn() {
  try {

    if (isMobile) {



      let msalInit = msal.msalInit(options)
      let msalInit_Result = await msalInit;
      var signinResult = msal.signInSilent().then((signinResult) => {
        return {
          isOk: true,
          data: signinResult
        };
      }, (err) => {
        var signinResult_interacctive = msal.signInInteractive().then((signinResult_interacctive) => {
          return {
            isOk: true,
            data: signinResult_interacctive
          };
        }, (err) => {
          return {
            isOk: false
          };
        }
        )
        return signinResult_interacctive
      })

      return signinResult


    } else {
      const result = await pca.acquireToken(loginRequest_user);
      // Send request

      return {
        isOk: true,
        data: result.account
      };
    };

  }
  catch {
    return {
      isOk: false,
      message: "Authentication failed"
    };
  }
}

export async function signOut(user) {
  try {
    if (isMobile) {

  
      let msalInit = msal.msalInit(options)
      let msalInit_Result = await msalInit;
      let signOut = msal.signOut(user);
      let signoutResult = await signOut;
      return {
        isOk: true
      };

    } else {
      const res = await pca.removeAccount(user);

      return {
        isOk: true
      };
    };
  }
  catch {
    return {
      isOk: false,
      message: "Authentication failed"
    };
  }
}

export async function getUser() {
  try {
    // Send request
    if (isMobile) {
 

      let msalInit = msal.msalInit(options)
      let msalInit_Result = await msalInit;

      var accountsResult = msal.getAccounts().then((accountsResult) => {
        var msGraphResult = getToken_user().then(token => {
          var msGraphResult = callMsGraph(token).then(value => {
            return {
              isOk: true,
              data: value
            };
          })
          return msGraphResult
        })
        return msGraphResult
      }, (err) => {
        return {
          isOk: false,
          data: []
        };
      })

      return accountsResult

    } else {
      var accounts = pca.getAccounts().then(value => {

        if (value.length != 0) {
          var msGraphResult = getToken_user().then(token => {
            var msGraphResult = callMsGraph(token).then(value => {
             localStorage.setItem('id', value.id)
              return {
                isOk: true,
                data: value
              };
            })
            return msGraphResult
          })
          return msGraphResult
        } else {
          return {
            isOk: false,
            data: []
          };
        }

      })
      return accounts



    }

  }
  catch {
    return {
      isOk: false
    };
  }
}
async function getAccount() {
  try {
    // Send request
    if (isMobile) {
     

      let msalInit = msal.msalInit(options)
      let msalInit_Result = await msalInit;

      var accountsResult = msal.getAccounts().then((accountsResult) => {
        return {
          isOk: true,
          data: accountsResult
        };
      }, (err) => {
        return {
          isOk: false,
          data: []
        };
      })

      return accountsResult

    } else {
      const accounts = await pca.getAccounts();
      return {
        isOk: true,
        data: accounts[0]
      };

    }

  }
  catch {
    return {
      isOk: false
    };
  }
}

