import React, { useState, useEffect, useRef } from 'react';
import { useHistory } from "react-router-dom";
import { getToken_api } from '../../api/auth';
import DataGrid, { Column, Button, Grouping, FilterRow, SearchPanel, HeaderFilter, Editing, Form, Lookup, Sorting } from 'devextreme-react/data-grid';
import { networkConfig } from '../../networkConfig';
import { Item, SimpleItem } from 'devextreme-react/form';
import { useParams } from 'react-router-dom'

export default function () {
let { typ } = useParams();
  const [token, setToken] = useState('')
  const [dataSource, setdataSource] = useState('')
  const [dataSource_Buero, setdataSource_Buero] = useState('')
  const [dataSource_auftrag, setdataSource_auftrag] = useState('')
  const history = useHistory();
  const inputRef = useRef();
  var text = "";
  if (typ == "abschluss"){
text = "abzuschließende MoScheine"
  }else{
    text = "freizugebende MoScheine"
  }
  useEffect(() => {
    console.log('list')
    getToken_api().then(token => {
        if (typ == "abschluss"){
            setdataSource({
                store: {
                  type: 'odata',
                  url: networkConfig.dataEndpoint + 'moschein_aksn',
                  key: 'moschein_id',
                  withCredentials: true,
                  beforeSend: function (e) {
                    e.headers = {
                      "Authorization": 'Bearer ' + token
                    };
                  },
                  onInserted: function(values, key){
                   // history.push("/settings/debitoren/bearbeiten/" + values.deb_id);
                  }
        
                }, filter:[["abschluss", "=", false],["freigabe","=",true]]
              }) 
        }else
        {
            setdataSource({
                store: {
                  type: 'odata',
                  url: networkConfig.dataEndpoint + 'moschein_aksn',
                  key: 'moschein_id',
                  withCredentials: true,
                  beforeSend: function (e) {
                    e.headers = {
                      "Authorization": 'Bearer ' + token
                    };
                  },
                  onInserted: function(values, key){
                   // history.push("/settings/debitoren/bearbeiten/" + values.deb_id);
                  }
        
                }, filter:[["unterschrieben", "=", true],["freigabe","=",false]]
              })
        }
     
        setdataSource_Buero({
            store: {
              type: 'odata',
              url: networkConfig.dataEndpoint + 'bueros_aksn',
              key: 'buero_id',
              withCredentials: true,
              beforeSend: function (e) {
                e.headers = {
                  "Authorization": 'Bearer ' + token
                };
              }
            },
            sort: ["Name"]
          })
          setdataSource_auftrag({
            store: {
              type: 'odata',
              url: networkConfig.dataEndpoint + 'auftrag_aksn',
              key: 'aufrag_id',
              withCredentials: true,
              beforeSend: function (e) {
                e.headers = {
                  "Authorization": 'Bearer ' + token
                };
              }
            }
          })

    })

  }, [typ]);

  const moschein_oeffnen = function (e) {
    history.push("/moschein/bearbeiten/" + e.row.data.moschein_id + '/' + typ);
  };
       

  return (

    <React.Fragment>
      <h2 className={'content-block'}>{text}</h2>
      <DataGrid
        ref={inputRef}
            
        dataSource={dataSource}
        showBorders={true}
      >
        <Grouping autoExpandAll={false} />
        <Sorting mode="multiple" />
        <Editing
        
        allowUpdating={false}
        allowDeleting={false}
        allowAdding={false}
      >
      
        </Editing>
        <FilterRow visible={true}
          applyFilter="auto" />
        <HeaderFilter visible={false} />
        <SearchPanel visible={true}
          width={240}
          placeholder="Suchen..." />
        <Column
          dataField="moschein_nr"
          caption="Moschein-Nr"
          defaultSortOrder="asc"
        />
         <Column
          dataField="buero_id"
          dataType="Guid"
          caption="Büro"
        >
   <Lookup dataSource={dataSource_Buero} displayExpr="Name" valueExpr="buero_id" />
        </Column>

        <Column
          dataField="auftragsdatum"
          caption="Auftragsdatum"
        />
        
         <Column
          dataField="auftrag_id"
          caption="Auftragnr"
          
        >
             <Lookup dataSource={dataSource_auftrag} displayExpr="auftrag_nr" valueExpr="auftrag_id" />
            </Column>
         <Column
          dataField="kunden_name"
          caption="Kunde"
        />
           <Column
          dataField="aksn"
          caption="AKSN"
        />
          <Column
          dataField="name_monteur"
          caption="Monteur Name"
        />
          <Column
          dataField="datum_unterschrift"
          caption="Unterschrift"
        />
       

       

<Column type="buttons">
          <Button
            text="bearbeiten"
            onClick={moschein_oeffnen}
          />
        </Column>




      </DataGrid>
    </React.Fragment>
  );


};
