import React, { useState, useEffect, useRef } from 'react';
import { useHistory } from "react-router-dom";
import { getToken_api, getToken_user } from '../../api/auth';

import { networkConfig } from '../../networkConfig';
import Form, { Item, GroupItem, Label, SimpleItem } from 'devextreme-react/form';
import { useParams } from 'react-router-dom'
import { confirm } from 'devextreme/ui/dialog';
import { CheckBox, NumberBox, TextArea, TextBox } from 'devextreme-react';
import { Button } from 'devextreme-react/button';
import { Popup, Position, ToolbarItem } from 'devextreme-react/popup';
import SignatureCanvas from 'react-signature-canvas';
import { alert } from "devextreme/ui/dialog"
export default function () {
    let { moschein_id } = useParams()
    const [popup_unterschrift_visible, setpopup_unterschrift_visible] = useState(false)
    const [formData, setformData] = useState({})
    const [moscheindaten, setmoscheindaten] = useState({})

    const history = useHistory();
    var sigPad = {};
    var maengel = {};
    useEffect(() => {
        var moschein = JSON.parse(localStorage.getItem("moschein:" + moschein_id))
        if (localStorage.getItem("splitklima") == undefined) {

            setformData({
                kunde: moschein.moschein.kunden_name, auftragsnummer: moschein.moschein.auftragsnummer, kundennr: moschein.moschein.kundennummer, strasse: moschein.moschein.kunden_strasse, plz: moschein.moschein.kunden_plz, ort: moschein.moschein.kunden_ort, kkl: moschein.moschein.kkl_objekt_nummer, datum: new Date(), "innen_filter": "in Ordnung",
                "innen_tauwanne": "in Ordnung",
                "innen_tauablauf": "in Ordnung",
                "innen_taupumpe": "in Ordnung",
                "innen_schalt": "in Ordnung",
                "innen_kabel": "in Ordnung",
                "innen_verschraubung": "in Ordnung",
                "aussen_verluessiger": "in Ordnung",
                "aussen_schalt": "in Ordnung",
                "aussen_kabel": "in Ordnung",
                "aussen_winter": "in Ordnung",
                "aussen_schutz": "in Ordnung",
                "aussen_dichtheit": "in Ordnung",
                "aussen_kaeltemittel": "in Ordnung"
            })


        } else {
            setformData(JSON.parse(localStorage.getItem("splitklima")))
        }



    }, []);

    var auswahl3 = [
        "Anlage ist in Ordnung",
        "Anlage ist funktionstüchtig, jedoch mit Mängel ",
        "Anlage ist nicht funktionstüchtig"

    ];
    var auswahl4 = [
        "in Ordnung",
        "nicht in Ordnung",
        "trifft nicht zu"

    ];
  
    const speichern = function () {

        var moscheindaten = localStorage.getItem("moschein:" + moschein_id)
        moscheindaten = JSON.parse(moscheindaten);
        var data = formData
        data.unterschrift_monteur = sigPad.toDataURL("image/svg+xml")
        data.datum = new Date();

        moscheindaten.splitklima.push(data)
        localStorage.setItem("moschein:" + moschein_id, JSON.stringify(moscheindaten))
        localStorage.removeItem("splitklima")
        history.push("/moschein/bearbeitenmobil/" + moschein_id);
    }

    const form = useRef(null);

    return (

        <React.Fragment>


            <div className="dx-toolbar-items-container" style={{ height: "60px" }}>
                <div className="dx-toolbar-before">
                    <h2 className={'content-block'}>Splitklima</h2>
                </div>
                <div className="dx-toolbar-after" style={{ "paddingTop": "20px", "paddingRight": "40px" }}>
                    <Button
                        text="Unterschreiben"
                        icon="sign"
                        onClick={function (e) {
                            //prüfen ob bei nicht in Ordnung auch bemerkung vorhanden ist
                            if (formData.gesamtzustand == undefined || formData.gesamtzustand == '') {
                                alert('Gesamtzustand nicht befüllt!', 'Wert benötigt');
                                return;
                            }
                            if (formData.gesamtzustand != 'Anlage ist in Ordnung' && (formData.maengel == undefined || formData.maengel == '')) {
                                // console.log(form.getEditor('maengel').option('value'))
                                alert('Sichtbare Mängel/Bemerkung nicht befüllt!', 'Wert benötigt');
                                return;
                            }

                            setpopup_unterschrift_visible(true)
                        }}
                    />
                </div>
            </div>


            <div className={'content-block dx-card responsive-paddings'}>
                <Form
                    id={'form'}
                    formData={formData}
                    labelLocation={'left'}
                    colCount={2}
                    ref={form}
                >

                    <GroupItem caption="Angaben zum Kunden" >
                        <Item dataField="kunde" editorOptions={{}}>
                            <Label text="Kunde/Filiale" />
                        </Item>
                        <Item dataField="kundennr" editorOptions={{}}>
                            <Label text="Kundennummer" />
                        </Item>
                        <Item dataField="strasse" editorOptions={{}}>
                            <Label text="Straße" />
                        </Item>
                        <Item dataField="plz" editorOptions={{}}>
                            <Label text="PLZ" />
                        </Item>
                        <Item dataField="ort" editorOptions={{}}>
                            <Label text="Ort" />
                        </Item>

                    </GroupItem>
                    <GroupItem caption="Angaben zum Projekt" >
                        <Item dataField="auftragsnummer" editorOptions={{}}>
                            <Label text="Auftragsnummer" />
                        </Item>
                        <Item dataField="anlage" editorOptions={{}}>
                            <Label text="Anlage/Gerät" />
                        </Item>
                        <Item dataField="kkl" editorOptions={{}}>
                            <Label text="KKL-Nr." />
                        </Item>
                        <Item dataField="mobilec" editorOptions={{}}>
                            <Label text="MobiLec/VDKFLec-Nr." />
                        </Item>
                    </GroupItem>
                    <GroupItem caption="Wartungsarbeiten Inneneinheit" >

                        <Item editorType="dxSelectBox" editorOptions={{
                            items: auswahl4,

                            onValueChanged: function (e) {
                                setformData({
                                    ...formData, "innen_filter": e.value,
                                    "innen_tauwanne": e.value,
                                    "innen_tauablauf": e.value,
                                    "innen_taupumpe": e.value,
                                    "innen_schalt": e.value,
                                    "innen_kabel": e.value,
                                    "innen_verschraubung": e.value
                                })

                            },

                        }}>
                            <Label text="für alle übernehmen" />
                        </Item>

                        <Item dataField="innen_filter" editorType="dxSelectBox" editorOptions={{
                            items: auswahl4,
                        }}>
                            <Label text="Filter gereinigt" />
                        </Item>

                        <Item dataField="innen_tauwanne" editorType="dxSelectBox" editorOptions={{
                            items: auswahl4,
                        }}>
                            <Label text="Tauwasserwanne gereinigt" />
                        </Item>

                        <Item dataField="innen_tauablauf" editorType="dxSelectBox" editorOptions={{
                            items: auswahl4,
                        }}>
                            <Label text="Tauwasserablauf geprüft" />
                        </Item>

                        <Item dataField="innen_taupumpe" editorType="dxSelectBox" editorOptions={{
                            items: auswahl4,
                        }}>
                            <Label text="Tauwasserpumpe geprüft" />
                        </Item>

                        <Item dataField="innen_schalt" editorType="dxSelectBox" editorOptions={{
                            items: auswahl4,
                        }}>
                            <Label text="Schaltfunktionen geprüft" />
                        </Item>

                        <Item dataField="innen_kabel" editorType="dxSelectBox" editorOptions={{
                            items: auswahl4,
                        }}>
                            <Label text="Kabelverbindungen geprüft" />
                        </Item>

                        <Item dataField="innen_verschraubung" editorType="dxSelectBox" editorOptions={{
                            items: auswahl4,
                        }}>
                            <Label text="Verschraubung auf Dichtheit geprüft" />
                        </Item>

                    </GroupItem>
                    <GroupItem caption="Wartungsarbeiten Außeneinheit" >

                        <Item editorType="dxSelectBox" editorOptions={{
                            items: auswahl4,

                            onValueChanged: function (e) {
                                setformData({
                                    ...formData, "aussen_verluessiger": e.value,
                                    "aussen_schalt": e.value,
                                    "aussen_kabel": e.value,
                                    "aussen_winter": e.value,
                                    "aussen_schutz": e.value,
                                    "aussen_dichtheit": e.value,
                                    "aussen_kaeltemittel": e.value
                                })

                            },

                        }}>
                            <Label text="für alle übernehmen" />
                        </Item>

                        <Item dataField="aussen_verluessiger" editorType="dxSelectBox" editorOptions={{
                            items: auswahl4,
                        }}>
                            <Label text="Verflüssiger gereinigt" />
                        </Item>

                        <Item dataField="aussen_schalt" editorType="dxSelectBox" editorOptions={{
                            items: auswahl4,
                        }}>
                            <Label text="Schaltfunktionen geprüft" />
                        </Item>

                        <Item dataField="aussen_kabel" editorType="dxSelectBox" editorOptions={{
                            items: auswahl4,
                        }}>
                            <Label text="Kabelverbindungen geprüft" />
                        </Item>

                        <Item dataField="aussen_winter" editorType="dxSelectBox" editorOptions={{
                            items: auswahl4,
                        }}>
                            <Label text="Winterregelung geprüft" />
                        </Item>

                        <Item dataField="aussen_schutz" editorType="dxSelectBox" editorOptions={{
                            items: auswahl4,
                        }}>
                            <Label text="Schutzeinrichtungen geprüft" />
                        </Item>

                        <Item dataField="aussen_dichtheit" editorType="dxSelectBox" editorOptions={{
                            items: auswahl4,
                        }}>
                            <Label text="Verschraubung auf Dichtheit geprüft" />
                        </Item>
                        <Item dataField="aussen_kaeltemittel" editorType="dxSelectBox" editorOptions={{
                            items: auswahl4,
                        }}>
                            <Label text="Kältemittelfüllung geprüft" />
                        </Item>

                    </GroupItem>
                    <GroupItem caption="Temperaturen" colSpan={2} colCount={2} >

                        <Item dataField="raumtemp" editorOptions={{
                            placeholder: "in °C"
                        }}>
                            <Label text="Raumtemperatur" />
                        </Item>
                        <Item dataField="aussentemp" editorOptions={{
                            placeholder: "in °C"
                        }}>
                            <Label text="Außentemperatur" />
                        </Item>
                        <Item dataField="ansaugtemp" editorOptions={{
                            placeholder: "in °C"
                        }}>
                            <Label text="Ansaugtemperatur" />
                        </Item>
                        <Item dataField="verdampftemp" editorOptions={{
                            placeholder: "in °C"
                        }}>
                            <Label text="Verdampfungstemperatur" />
                        </Item>
                        <Item dataField="ausbladtemp" editorOptions={{
                            placeholder: "in °C"
                        }}>
                            <Label text="Ausblastemperatur" />
                        </Item>
                        <Item dataField="verfluessigertemp" editorOptions={{
                            placeholder: "in °C"
                        }}>
                            <Label text="Verflüssigungstemperatur" />
                        </Item>
                    </GroupItem>
                    <GroupItem caption="Prüfung" colSpan={2} >

                    <Item dataField="gesamtzustand" editorType="dxSelectBox" editorOptions={{
                            items: auswahl3,
                        }}>
                            <Label text="Gesamtzustand" />
                        </Item>
                        
                    <Item dataField="maengel" editorType="dxTextArea" editorOptions={{
                            
                        }}>
                            <Label text="Sichtbare Mängel/Bemerkung" />
                        </Item>
                    </GroupItem>

                </Form>
            </div>
            <Popup
                visible={popup_unterschrift_visible}
                onHiding={function (e) { setpopup_unterschrift_visible(false) }}
                dragEnabled={false}
                closeOnOutsideClick={true}
                showCloseButton={true}
                showTitle={false}
                title="Unterschrift"
                width={1060}
                height={700}
            >
                <Position
                    at="center"
                    my="center"
                />
                <ToolbarItem
                    widget="dxButton"
                    toolbar="bottom"
                    location="after"
                    options={{
                        text: 'Speichern',
                        disabled: false,
                        onClick: function (e) {

                            speichern()
                            setpopup_unterschrift_visible(false)

                        }
                    }}
                />
                <table style={{ marginBottom: "30px" }} width="100%">
                    <tbody>

                        <tr>
                            <th></th>
                            <th> <Button
                                text="Löschen"
                                icon="clear"
                                onClick={function (e) {
                                  
                                    sigPad.clear();
                                }}

                            /></th>
                        </tr>
                    </tbody>
                </table>
                <SignatureCanvas penColor='blue' backgroundColor='rgba(244, 244, 244)' canvasProps={{ width: 1000, height: 400, className: 'sigCanvas' }} ref={(ref) => { sigPad = ref }} />
            </Popup>
        </React.Fragment>
    );


};
