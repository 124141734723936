import React, { useState, useEffect } from 'react';
import { useHistory } from "react-router-dom";
import { getToken_api, getToken_user } from '../../../api/auth';
import DataGrid, { Column, Button, Grouping, FilterRow, SearchPanel, HeaderFilter, Editing, Form, Lookup, Sorting } from 'devextreme-react/data-grid';
import { networkConfig } from '../../../networkConfig';
import { Item, SimpleItem } from 'devextreme-react/form';
export default function () {

  const [token, setToken] = useState('')
  const [isAdmin, setisAdmin] = useState(false)
  const [dataSource, setdataSource] = useState('')
  const [dataSource_Buero, setdataSource_Buero] = useState('')
  const history = useHistory();

  useEffect(() => {

    getToken_api().then(token => {
      console.log(token)
      setdataSource({
        store: {
          type: 'odata',
          url: networkConfig.dataEndpoint + 'filialen',
          key: 'ein_id',
          withCredentials: true,
          beforeSend: function (e) {
            e.headers = {
              "Authorization": 'Bearer ' + token
            };
          },
          onInserted: function(values, key){
            history.push("/settings/filialen/bearbeiten/" + values.ein_id);
          }
        },
      });
      console.log(token)
      setdataSource_Buero({
        store: {
          type: 'odata',
          url: networkConfig.dataEndpoint + 'bueros',
          key: 'buero_id',
          withCredentials: true,
          beforeSend: function (e) {
            e.headers = {
              "Authorization": 'Bearer ' + token
            };
          }
        },
      });
   if (localStorage.getItem("roles").indexOf(";admin;") != -1){
     setisAdmin(true)
   }

    })

  }, []);

  const filiale_oeffnen = function (e) {
    history.push("/settings/filialen/bearbeiten/" + e.row.data.ein_id);
  };
       

  return (

    <React.Fragment>
      <h2 className={'content-block'}>Einsatzorte/Filialen</h2>
      <DataGrid

        dataSource={dataSource}
        showBorders={true}
        onInitNewRow={function(info){
          info.data.sperrkennzeichen = false;
        }

        }
      >
        <Grouping autoExpandAll={false} />
        <Sorting mode="multiple" />
        <Editing
        mode="form"
        allowUpdating={false}
        allowDeleting={false}
        allowAdding={isAdmin}
      >
          <Form title="Filiale" showTitle={true}>

            <Item dataField="name"/>
            
          </Form>
        </Editing>
        <FilterRow visible={true}
          applyFilter="auto" />
        <HeaderFilter visible={false} />
        <SearchPanel visible={true}
          width={240}
          placeholder="Suchen..." />
        <Column
          dataField="ein_nr"
          caption="Filiale-Nr"
          defaultSortOrder="asc"
        />
        <Column
          dataField="name"
          caption="Name"
        />
        <Column
          dataField="ort"
          caption="Ort"
        />
        <Column
          dataField="buero_id"
          caption="Büro"
        >
   <Lookup dataSource={dataSource_Buero} displayExpr="Name" valueExpr="buero_id" />
        </Column>
        <Column
          dataField="sperrkennzeichen"
          dataType="boolean"
          caption="gesperrt"
        />
<Column type="buttons">
          <Button
            text="bearbeiten"
            onClick={filiale_oeffnen}
          />
        </Column>




      </DataGrid>
    </React.Fragment>
  );


};
