import React, { useState, useEffect } from 'react';
import { useHistory } from "react-router-dom";
import { getToken_api } from '../../api/auth';
import DataGrid, { Column, Button, Grouping, FilterRow, SearchPanel, HeaderFilter, Editing, Form, Lookup, Sorting } from 'devextreme-react/data-grid';
import { networkConfig } from '../../networkConfig';
import { Item, SimpleItem } from 'devextreme-react/form';
export default function () {

  const [token, setToken] = useState('')
  const [isAdmin, setisAdmin] = useState(false)
  const [dataSource, setdataSource] = useState('')
  const [dataSource_deb, setdataSource_deb] = useState('')
  const history = useHistory();

  useEffect(() => {
    if (localStorage.getItem("roles").indexOf("admin;") != -1) {
      setisAdmin(true)
  }
    getToken_api().then(token => {
      setdataSource({
        store: {
          type: 'odata',
          url: networkConfig.dataEndpoint + 'vertrag',
          key: 'vertrag_id',
          withCredentials: true,
          beforeSend: function (e) {
            e.headers = {
              "Authorization": 'Bearer ' + token
            };
          },
          onInserted: function (values, key) {
            history.push("/vertrag/bearbeiten/" + values.vertrag_id);
          }

        },
      })
      setdataSource_deb({
        store: {
          type: 'odata',
          url: networkConfig.dataEndpoint + 'debitor',
          key: 'deb_id',
          withCredentials: true,
          beforeSend: function (e) {
            e.headers = {
              "Authorization": 'Bearer ' + token
            };
          },


        },
      })


    })

  }, []);

  const vertrag_oeffnen = function (e) {
    history.push("/vertrag/bearbeiten/" + e.row.data.vertrag_id);
  };


  return (

    <React.Fragment>
      <h2 className={'content-block'}>Verträge anzeigen</h2>
      <DataGrid

        dataSource={dataSource}
        showBorders={true}
      >
        <Grouping autoExpandAll={false} />
        <Sorting mode="multiple" />
        <Editing
          mode="form"
          allowUpdating={false}
          allowDeleting={isAdmin}
          allowAdding={isAdmin}
        >
          <Form title="Vertrag" showTitle={true}>

            <Item dataField="vertrag_nr" />

          </Form>
        </Editing>
        <FilterRow visible={true}
          applyFilter="auto" />
        <HeaderFilter visible={false} />
        <SearchPanel visible={true}
          width={240}
          placeholder="Suchen..." />
        <Column
          dataField="vertrag_nr"
          caption="Vertrag-Nr"
          defaultSortOrder="asc"
        />
        <Column
          dataField="kunde_id"
          dataType="Guid"
          caption="Kunde"
        >
          <Lookup dataSource={dataSource_deb} displayExpr={function (item) { return item.nr + " (" + item.name + ")" }} valueExpr="deb_id"
          />
        </Column>

        <Column
          dataField="vertrag_info"
          caption="Vertragsinfo"
        />



<Column type="buttons">
          <Button
            text="bearbeiten"
            onClick={vertrag_oeffnen}
          />
        </Column>
        <Column type="buttons">
        <Button
          visible={isAdmin}
          text="löschen"
          onClick={function (e) {
            e.component.deleteRow(e.row.rowIndex)
          }}
        />
      </Column>




      </DataGrid>
    </React.Fragment>
  );


};
