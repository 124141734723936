import { withNavigationWatcher } from './contexts/navigation';
import * as pages from './pages';

const routes = [
  {
    path: '/tasks',
    component: pages.TasksPage
  },
  {
    path: '/profile',
    component: pages.ProfilePage
  },
  {
    path: '/home',
    component: pages.HomePage
  },
  {
    path: '/user/bearbeiten/:user_id',
    component: pages.user_editPage
  },
  {
    path: '/user/anzeigen',
    component: pages.user_listPage
  },
  {
    path: '/bueros/bearbeiten/:buero_id',
    component: pages.bueros_editPage
  },
  {
    path: '/bueros/anzeigen',
    component: pages.bueros_listtPage
  },
  {
    path: '/customer-settings/automail',
    component: pages.automailPage
  },
  {
    path: '/customer-settings/aw',
    component: pages.awPage
  },
  {
    path: '/customer-settings/basf',
    component: pages.basfPage
  },
  {
    path: '/customer-settings/stoercode-liste',
    component: pages.stoercode_listePage
  },
  {
    path: '/customer-settings/stoercode-pflicht',
    component: pages.stoercode_pfichtPage
  },
  {
    path: '/customer-settings/vdma',
    component: pages.vdmaPage
  },
  {
    path: '/settings/kostentraeger',
    component: pages.kostentraegerPage
  },
  {
    path: '/settings/svs',
    component: pages.svsPage
  },
  {
    path: '/settings/filialen/bearbeiten/:ein_id',
    component: pages.filialen_editPage
  },
  {
    path: '/settings/filialen/anzeigen',
    component: pages.filialen_listPage
  },
  {
    path: '/settings/debitoren/bearbeiten/:deb_id',
    component: pages.debitoren_editPage
  },
  {
    path: '/settings/debitoren/anzeigen',
    component: pages.debitoren_listPage
  },
  {
    path: '/bereitschaft',
    component: pages.bereitschaft_editPage
  }
  ,
  {
    path: '/bereitschaft-edit',
    component: pages.bereitschaft_editPage
  },{
    path: '/auftrag/anzeigen',
    component: pages.auftrag_listPage
  },{
    path: '/auftrag/bearbeiten/:auftrag_id',
    component: pages.auftrag_editPage
  },{
    path: '/auftrag/erstellen',
    component: pages.auftrag_addPage
  },{
    path: '/vertrag/anzeigen',
    component: pages.vertrag_list
  },{
    path: '/vertrag/bearbeiten/:vertrag_id',
    component: pages.vertrag_edit
  },{
    path: '/moschein/bearbeiten/:moschein_id/:typ',
    component: pages.moschein_edit
  },{
    path: '/tourauftrag/bearbeiten/:tourauftrag_id',
    component: pages.tourauftrag_edit
  },{
    path: '/dispo',
    component: pages.dispo
  },
  {
    path: '/Moscheine/Mobil/anzeigen',
    component: pages.moschein_uebersicht_tablet
  },
  {
    path: '/moschein/bearbeitenmobil/:moschein_id',
    component: pages.moschein_edit_tablet
  },
  {
    path: '/moschein/protokolle/wartung_vdma/:moschein_id',
    component: pages.moschein_wartung_vdma
  },
  {
    path: '/moschein/wartung_vdma_details/bearbeiten/:moschein_id',
    component: pages.moschein_wartung_vdma_details
  },
  {
    path: '/pdf/:typ/:filename',
    component: pages.pdf_viewer
  },
  {
    path: '/moschein/protokolle/dhp/:moschein_id',
    component: pages.dhp_edit
  },
  {
    path: '/moschein/protokolle/splitklima/:moschein_id',
    component: pages.splitklima_edit
  },
  {
    path: '/moschein/protokolle/gaswarn/:moschein_id',
    component: pages.gaswarn_edit
  },
  {
    path: '/moschein/protokolle/basfkaelte/:moschein_id',
    component: pages.basfkaelte_edit
  },
  {
    path: '/moschein/protokolle/basfschadenverdichter/:moschein_id',
    component: pages.basfschadenverdichter_edit
  },
  {
    path: '/logout',
    component: pages.logout
  },
  {
    path: '/manual',
    component: pages.manual
  },
  {
    path: '/moschein/abschliessen/:typ',
    component: pages.moschein_abschluss
  },
  {
    path: '/moscheine/anzeigen',
    component: pages.moschein_bearbeitet_uebersicht
  },
  {
    path: '/wochenuebersicht',
    component: pages.wochenuebersicht
  },
  {
    path: '/wochenbericht/uebersicht',
    component: pages.wochenbericht_uebersicht
  },
  {
    path: '/wochenbericht/anzeigen',
    component: pages.wochenbericht_tag
  },
  

];

export default routes.map(route => {
  return {
    ...route,
    component: withNavigationWatcher(route.component)
  };
});
