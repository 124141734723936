import React, { useState, useEffect } from 'react';
import { useHistory } from "react-router-dom";
import { getToken_api } from '../../api/auth';
import DataGrid, { Column, Button, Grouping, FilterRow, SearchPanel, HeaderFilter, Editing, Form, Lookup, Sorting } from 'devextreme-react/data-grid';
import { networkConfig } from '../../networkConfig';
import { Item, SimpleItem } from 'devextreme-react/form';
import Guid from 'devextreme/core/guid';

export default function ({ kunde_id }) {
  const history = useHistory();
  const [isAdmin, setisAdmin] = useState(false)
  const [dataSource, setdataSource] = useState('')
  const [token, setToken] = useState('')
  useEffect(() => {
    if (localStorage.getItem("roles").indexOf("admin;") != -1) {
      setisAdmin(true)
    }
    getToken_api().then(token => {
      setdataSource({
        store: {
          type: 'odata',
          url: networkConfig.dataEndpoint + 'vertrag_view',
          key: 'vertrag_id',
          withCredentials: true,
          beforeSend: function (e) {
            e.headers = {
              "Authorization": 'Bearer ' + token
            };
          },
          onInserted: function (values, key) {
            history.push("/vertrag/bearbeiten/" + values.vertrag_id);
          }
        },
        filter: ["kunde_id", "=", new Guid(kunde_id)]
      })
    })
  }, []);

  const vertrag_offnen = function (e) {
    history.push("/vertrag/bearbeiten/" + e.row.data.vertrag_id);
  };
  return (
    <DataGrid

      dataSource={dataSource}
      showBorders={true}
    >
      <Grouping autoExpandAll={false} />
      <Sorting mode="multiple" />
      <Editing
        mode="form"
        allowUpdating={false}
        allowDeleting={isAdmin}
        allowAdding={isAdmin}
      >
        <Form title="Standort" showTitle={true}>

          <Item dataField="Name" />

        </Form>
      </Editing>
      <FilterRow visible={true}
        applyFilter="auto" />
      <HeaderFilter visible={false} />
      <SearchPanel visible={true}
        width={240}
        placeholder="Suchen..." />
      <Column
        dataField="vertrag_nr"
        caption="Vertrag-Nr"
        defaultSortOrder="asc"
      />

      <Column
        dataField="vertrag_info"

      />

 <Column type="buttons">
        <Button
          text="bearbeiten"
          onClick={vertrag_offnen}
        />
      </Column>
      <Column type="buttons">
        <Button
        visible={isAdmin}
          text="löschen"
          onClick={function(e){
            e.component.deleteRow(e.row.rowIndex)
          }}
        />
      </Column>
    </DataGrid>
  );
}
