import React, { useState, useEffect } from 'react';
import { getToken_api } from '../../api/auth';
import Form, { Item, TabbedItem, TabPanelOptions, Tab, Label, GroupItem } from 'devextreme-react/form';
import ODataStore from 'devextreme/data/odata/store';
import DataSource from 'devextreme/data/data_source';
import DataGrid, { Column, Editing, Lookup } from 'devextreme-react/data-grid';
import DropDownButton from 'devextreme-react/drop-down-button';
import { useHistory } from "react-router-dom";
import notify from 'devextreme/ui/notify';
import Guid from 'devextreme/core/guid';
import { networkConfig } from '../../networkConfig';

export default function ({ tourauftrag_id }) {

  
    const [token, setToken] = useState('')
    const [formData, setformData] = useState('')
    const [monteur_select_options, setmonteur_select_options] = useState('')
    const [status_select_options, setstatus_select_options] = useState('')

    const history = useHistory();
    const speichern = function (e) {

        const dataSource = new ODataStore({
            type: 'odata',
            url: networkConfig.dataEndpoint + 'tourauftrag',
            key: 'buero_id',
            withCredentials: true,
            beforeSend: function (e) {
                e.headers = {
                    "Authorization": 'Bearer ' + token
                };
            }
        });
        dataSource.update(new Guid(tourauftrag_id), formData)
        if (e.itemData == "Speichern und Auftrag öffnen") {
            history.push("/auftrag/bearbeiten/" + formData.auftrag_id);
        }
        if (e.itemData == "Speichern und Dispotool öffnen") {
            history.push("/dispo");
        }
        notify({
            message: 'Tourauftrag wurde erfolgreich gespeichert',

        }, 'success', 5000);
    }


    useEffect(() => {
        getToken_api().then(token => {
            setToken(token)

            const dataSource = new ODataStore({
                type: 'odata',
                url: networkConfig.dataEndpoint + 'tourauftrag',
                key: 'tourauftrag_id',
                withCredentials: true,
                beforeSend: function (e) {
                    e.headers = {
                        "Authorization": 'Bearer ' + token
                    };
                }
            });
            dataSource.load({ filter: ['tourauftrag_id', '=', new Guid(tourauftrag_id)] })
                .then(
                    (data) => {
                        setformData(data[0]);
                        const store3 = new DataSource({
                            store: new ODataStore({
                                type: 'odata',
                                url: networkConfig.dataEndpoint + 'dispo_monteur_view',
                                key: 'benutzerbuero_id',
                                withCredentials: true,
                                beforeSend: function (e) {
                                    e.headers = {
                                        "Authorization": 'Bearer ' + token
                                    };
                                }
                            }), filter: ["buero_id", "=", new Guid(data[0].buero_id)]
                        });
            
            
            
            
                        setmonteur_select_options({ dataSource: store3, displayExpr: "name", valueExpr: "benutzer_id", searchEnabled: true, showClearButton:true })

                    },
                    (error) => { /* Handle the "error" here */ }
                );

     



            const store2 = new ODataStore({
                type: 'odata',
                url: networkConfig.dataEndpoint + 'statecodes',
                key: 'deb_id',
                withCredentials: true,
                beforeSend: function (e) {
                    e.headers = {
                        "Authorization": 'Bearer ' + token
                    };
                },
            });

            setstatus_select_options({
                dataSource: { store: store2 }, displayExpr: "bezeichnung"
                , valueExpr: "statecode_id", searchEnabled: true
            })

        })
    }, []);

  

    return (

        <React.Fragment>
<div className="dx-toolbar-items-container" style={{ height: "60px" }}>
                <div className="dx-toolbar-before">
                    <h2 className={'content-block'}>Tourauftrag bearbeiten</h2>
                </div>
                <div className="dx-toolbar-after" style={{ "paddingTop": "20px", "paddingRight": "40px" }}>
                    <DropDownButton
                        text="Speichern"
                        icon="save"
                        type='success'
                        dropDownOptions={{ width: 230 }}
                        items={["Speichern", "Speichern und Auftrag öffnen", "Speichern und Dispotool öffnen"]}
                        onItemClick={speichern}
                    />
                </div>
            </div>
            <div className={'content-block dx-card responsive-paddings'}>
                <Form
                    id={'form'}
                    formData={formData}
                    labelLocation={'top'}
                    colCount={2}
                >
                    <GroupItem caption="Allgemein" colCount={3} colSpan={2}>
                        <Item dataField="tourauftrag_nr" colSpan={1} disabled={true} editorOptions={{}}>
                            <Label text="Tourauftrag" />
                        </Item>
                        <Item dataField="Auftragsnr" colSpan={1} disabled={true} editorOptions={{}}>
                            <Label text="Auftrags-Nr" />
                        </Item>
                        <Item dataField="auftrag_id" colSpan={1} visible={false} editorOptions={{}}>
                            <Label text="auftrag_id-Nr" />
                        </Item>
                        <Item dataField="buero_id" colSpan={1} visible={false} editorOptions={{}}>
                            <Label text="buero_id" />
                        </Item>
                        <Item dataField="Bemerkung" colSpan={1} editorOptions={{}}>
                            <Label text="Bemerkung" />
                        </Item>
                    </GroupItem>
                    <GroupItem caption="Tourauftrag" colCount={1} colSpan={1}>
                        <Item dataField="Name" colSpan={1} disabled={true} editorOptions={{}}>
                            <Label text="Name" />
                        </Item>
                        <Item dataField="Name2" colSpan={1} disabled={true} editorOptions={{}}>
                            <Label text="Name2" />
                        </Item>
                        <Item dataField="Name3" colSpan={1} disabled={true} editorOptions={{}}>
                            <Label text="Name3" />
                        </Item>
                        <Item dataField="Strasse1" colSpan={1} disabled={true} editorOptions={{}}>
                            <Label text="Straße" />
                        </Item>
                        <Item dataField="plz" colSpan={1} disabled={true} editorOptions={{}}>
                            <Label text="PLZ" />
                        </Item>
                        <Item dataField="Stadt" colSpan={1} disabled={true} editorOptions={{}}>
                            <Label text="Ort" />
                        </Item>
                    </GroupItem>
                    <GroupItem caption="Tourauftrag" colCount={1} colSpan={1}>
                        <Item dataField="erwDauer" editorType="dxNumberBox" colSpan={1} editorOptions={{}}>
                            <Label text="erw. Dauer" />
                        </Item>
                        <Item dataField="erwTag" editorType="dxDateBox" colSpan={1} editorOptions={{}}>
                            <Label text="erw. Einsatztag" />
                        </Item>
                        <Item dataField="user_id" editorType="dxSelectBox" colSpan={1} editorOptions={monteur_select_options}>
                            <Label text="Monteur" />
                        </Item>
                        <Item dataField="erwBeginn" colSpan={1} editorType="dxDateBox" editorOptions={{ type: "datetime" }}>
                            <Label text="erw. Beginn" />
                        </Item>
                        <Item dataField="erwEnde" colSpan={1} editorType="dxDateBox" editorOptions={{ type: "datetime" }}>
                            <Label text="erw. Ende" />
                        </Item>
                    </GroupItem>

                    <GroupItem caption="Einsatz" colCount={1} colSpan={2}>
                        <Item dataField="statecode" editorType="dxSelectBox" colSpan={1} editorOptions={status_select_options}>
                            <Label text="Status" />
                        </Item>
                        <Item dataField="active" editorType="dxCheckBox" colSpan={1} editorOptions={{}}>
                            <Label text="Aktiv" />
                        </Item>
                        <Item dataField="Abfahrt" editorType="dxDateBox" disabled={true} colSpan={1} editorOptions={{ type: "datetime" }}>
                            <Label text="Abfahrt" />
                        </Item>
                        <Item dataField="Ankunft" editorType="dxDateBox" disabled={true} colSpan={1} editorOptions={{ type: "datetime" }}>
                            <Label text="Ankunft" />
                        </Item>
                        <Item dataField="moschein_nr" disabled={true} colSpan={1} editorOptions={{}}>
                            <Label text="MoSchein" />
                        </Item>
                    </GroupItem>
                </Form>
            </div>
            
        </React.Fragment>
    );
};
