import React, { useState, useEffect } from 'react';
import { useHistory } from "react-router-dom";
import { getToken_api ,getToken_user } from '../../../api/auth';
import DataGrid, { Column, Button, Grouping, FilterRow, SearchPanel, HeaderFilter, Editing,  Form, Lookup, Sorting  } from 'devextreme-react/data-grid';
import { networkConfig } from '../../../networkConfig';
import { Item, SimpleItem } from 'devextreme-react/form';
export default function () {

  const [token, setToken] = useState('')
  const history = useHistory();
  const [dataSource, setdataSource] = useState('')
  const [dataSource_Buero, setdataSource_Buero] = useState('')
  const [userdaten, setuserdaten] = useState([])
  const [formData, setformData] = useState({
    name:""
  })

  
  const [gridBoxValue, setgridBoxValue] = useState('')
  const [vorname, setvorname] = useState('')
  const [isGridBoxOpened, setisGridBoxOpened] = useState(false)
  const gridColumns = ['displayName', 'mail'];


  useEffect(() => {

    getToken_api().then(token => {
      setdataSource({
        store: {
          type: 'odata',
          url: networkConfig.dataEndpoint + 'bueros',
          key: 'buero_id',
          withCredentials: true,
          beforeSend: function (e) {
            e.headers = {
              "Authorization": 'Bearer ' + token
            };
          },
          onInserted: function(values, key){
            history.push("/bueros/bearbeiten/" + values.buero_id);
          }
        },
      })

      setdataSource_Buero({
        store: {
          type: 'odata',
          url: networkConfig.dataEndpoint + 'bueros',
          key: 'buero_id',
          withCredentials: true,
          beforeSend: function (e) {
            e.headers = {
              "Authorization": 'Bearer ' + token
            };
          }
        },
      })
    })
    
  }, []);

  const buero_offnen = function (e) {
    history.push("/bueros/bearbeiten/" + e.row.data.buero_id);
  };
       
   

  return (

    <React.Fragment>
      <h2 className={'content-block'}>Standorte anzeigen</h2>
      <DataGrid
       
        dataSource={dataSource}
        showBorders={true}
      >
           <Grouping autoExpandAll={false} />
           <Sorting mode="multiple" />
        <Editing
        mode="form"
        allowUpdating={false}
        allowDeleting={false}
        allowAdding={true}
      >
          <Form title="Standort" showTitle={true}>

            <Item dataField="Name"/>
            
          </Form>
        </Editing>
        <FilterRow visible={true}
          applyFilter="auto" />
        <HeaderFilter visible={false} />
        <SearchPanel visible={true}
          width={240}
          placeholder="Suchen..." />
         <Column
        dataField="Name"
        caption="Name"
        defaultSortOrder="asc"
      />
        <Column
          dataField="parent_id"
          caption="Niederlassung"
          groupIndex={0}
          defaultSortOrder="asc">
   <Lookup dataSource={dataSource_Buero} displayExpr="Name" valueExpr="buero_id" />
                  </Column>
       
         <Column
          dataField="Ort"
        
        />
         <Column
          dataField="Kostenstelle"
          
        />
         <Column
          dataField="buero_nr"
          caption="Standort-Nr"
        />
         <Column
          dataField="IsDisabled"
          caption="deaktiviert"
          dataType="boolean"
        />
       
        <Column type="buttons">
          <Button
            text="bearbeiten"
            onClick={buero_offnen}
          />
        </Column>
      </DataGrid>
    </React.Fragment>
  );


};
