import React, { useState, useEffect } from 'react';
import { getToken_api } from '../../api/auth';
import { useHistory } from "react-router-dom";
import ODataStore from 'devextreme/data/odata/store';
import DataSource from 'devextreme/data/data_source';

import Guid from 'devextreme/core/guid';
import { networkConfig } from '../../networkConfig';
import Scheduler, { Resource, View } from 'devextreme-react/scheduler';
import notify from 'devextreme/ui/notify';
import SelectBox from 'devextreme-react/select-box';
import { Popup, Position, ToolbarItem } from 'devextreme-react/popup';
import { Button } from 'devextreme-react/button';
import Form, { Item, TabbedItem, TabPanelOptions, Tab, Label, GroupItem } from 'devextreme-react/form';

export default function () {
    const [token, setToken] = useState()
    const [formData, setformData] = useState()
    const [bueros, setbueros] = useState('')
    const [buero_auswahl, setbuero_auswahl] = useState()
    const [monteur_auswahl, setmonteur_auswahl] = useState()
    const [wbtag_status, setwbtag_status] = useState('')
    const history = useHistory();
    const [kalender, setkalender] = useState('')
    const [buero_id, setbuero_id] = useState()
    const [monteur_id, setmonteur_id] = useState()
    const [monteur_name, setmonteur_name] = useState('')
    const [editable, seteditable] = useState(false)
    const [popup_visible, setpopup_visible] = useState(false)
    const [buero_vorauswahl, setbuero_vorauswahl] = useState()
    const [monteur_vorauswahl, setmonteur_vorauswahl] = useState()
    const [monteur_select_options, setmonteur_select_options] = useState('')

  

    useEffect(() => {
        getToken_api().then(token => {
            setToken(token)

            var roles = localStorage.getItem("roles")
            if (roles.indexOf('wochenbericht_meister') == -1 && roles.indexOf('wochenbericht_admin') == -1 && roles.indexOf('wochenbericht_innendienst') == -1) {
                console.log(localStorage.getItem('id'))
                setmonteur_id(new Guid(localStorage.getItem('id')))
                setmonteur_vorauswahl(new Guid(localStorage.getItem('id')))
                setkalender(new DataSource({
                    store: {
                        type: 'odata',
                        url: networkConfig.dataEndpoint + 'wbtag_view',
                        key: 'wbtag_id',
                        withCredentials: true,
                        beforeSend: function (e) {
                            e.headers = {
                                "Authorization": 'Bearer ' + token
                            };
                        }
                    }, filter: ["benutzer_id", "=", new Guid(localStorage.getItem('id'))]
                })
                )
                
            }else{
                seteditable(true)
                if (localStorage.getItem('buero_wochenbericht') != undefined){
                    setbuero_vorauswahl(new Guid(localStorage.getItem('buero_wochenbericht') ))
                }
                if (localStorage.getItem('monteur_wochenbericht') != undefined){
                    setmonteur_vorauswahl(new Guid(localStorage.getItem('monteur_wochenbericht') ))
                }
                if (localStorage.getItem('monteur_wochenbericht_name') != undefined){
                    setmonteur_name(localStorage.getItem('monteur_wochenbericht_name'))
                }
    
                setbuero_auswahl(new DataSource({
                    store: {
                        type: 'odata',
                        url: networkConfig.dataEndpoint + 'bueros_aksn',
                        key: 'buero_id',
                        withCredentials: true,
                        beforeSend: function (e) {
                            e.headers = {
                                "Authorization": 'Bearer ' + token
                            };
                        }
                    }, sort: ["Name"]
                })
                )
    
                if (localStorage.getItem('buero_wochenbericht') != undefined){
                    setbuero_id(new Guid(localStorage.getItem('buero_wochenbericht')))
                    setmonteur_auswahl(new DataSource({
                        store: {
                            type: 'odata',
                            url: networkConfig.dataEndpoint + 'wochenbericht_monteur_view',
                            key: 'benutzbuero_id',
                            withCredentials: true,
                            beforeSend: function (e) {
                                e.headers = {
                                    "Authorization": 'Bearer ' + token
                                };
                            }
                        }, filter: ["buero_id", "=", new Guid(localStorage.getItem('buero_wochenbericht'))]
                        , sort: ["name"]
                    })
                    )
                }
                if (localStorage.getItem('monteur_wochenbericht') != undefined){
                setmonteur_id(new Guid(localStorage.getItem('monteur_wochenbericht')))
                    setkalender(new DataSource({
                        store: {
                            type: 'odata',
                            url: networkConfig.dataEndpoint + 'wbtag_view',
                            key: 'wbtag_id',
                            withCredentials: true,
                            beforeSend: function (e) {
                                e.headers = {
                                    "Authorization": 'Bearer ' + token
                                };
                            }
                        }, filter: ["benutzer_id", "=", new Guid(localStorage.getItem('monteur_wochenbericht'))]
                    })
                    )
                }
                }
            setwbtag_status(new DataSource({
                store: {
                    type: 'odata',
                    url: networkConfig.dataEndpoint + 'wbtagstatus',
                    key: 'wbtagstatus_id',
                    withCredentials: true,
                    beforeSend: function (e) {
                        e.headers = {
                            "Authorization": 'Bearer ' + token
                        };
                    }
                }
            })
            )
            



        })
    }, []);
    useEffect(() => {

        if (buero_id != null) {
            setmonteur_auswahl(new DataSource({
                store: {
                    type: 'odata',
                    url: networkConfig.dataEndpoint + 'wochenbericht_monteur_view',
                    key: 'benutzbuero_id',
                    withCredentials: true,
                    beforeSend: function (e) {
                        e.headers = {
                            "Authorization": 'Bearer ' + token
                        };
                    }
                }, filter: ["buero_id", "=", buero_id]
                , sort: ["name"]
            })
            )
        }


    }, [buero_id]);
    useEffect(() => {
        if (monteur_id != null) {
            setkalender(new DataSource({
                store: {
                    type: 'odata',
                    url: networkConfig.dataEndpoint + 'wbtag_view',
                    key: 'wbtag_id',
                    withCredentials: true,
                    beforeSend: function (e) {
                        e.headers = {
                            "Authorization": 'Bearer ' + token
                        };
                    }
                }, filter: ["benutzer_id", "=", monteur_id]
            })
            )
        }


    }, [monteur_id]);
    var scheduler = {};

    return (

        <React.Fragment>

            <div className="dx-toolbar-items-container" style={{ height: "60px" }}>
                <div className="dx-toolbar-before">
                    <h2 className={'content-block'}>Wochenbericht Übersicht</h2>
                </div>

            </div>
            <div className={'content-block dx-card responsive-paddings'}  >
                <SelectBox dataSource={buero_auswahl}
                    displayExpr="Name"
                    valueExpr="buero_id"
                    placeholder="Büro auswählen"
                    value={buero_vorauswahl}
                    deferRendering={false}
                    visible={editable}
                    onValueChanged={function (e) { setbuero_id(e.value); localStorage.setItem('buero_wochenbericht',e.value.toString()); setbuero_vorauswahl(e.value) }} />
                <SelectBox dataSource={monteur_auswahl}
                    displayExpr={function (item){return item && item.name + " (" + item.offeneTage + ")"}}
                    valueExpr="benutzer_id"
                    value={monteur_vorauswahl}
                    placeholder="Mitarbeiter auswählen"
                    deferRendering={false}
                    visible={editable}
                    onValueChanged={function (e) { setmonteur_id(e.value); localStorage.setItem('monteur_wochenbericht',e.value.toString()); localStorage.setItem('monteur_wochenbericht_name', e.component.option('text')); setmonteur_vorauswahl(e.value); setmonteur_name(e.component.option('text')) }} />


                <Scheduler
                    // id="scheduler"
                   // timeZone="Europe/Berlin"
                    dataSource={kalender}
                    views={["month"]}
                    defaultCurrentView="month"
                    defaultCurrentDate={new Date()}
                    height={"100%"}
                    recurrenceRuleExpr=""
                    editing={true}
                    startDateExpr="tagdate"
                    endDateExpr="tagdate"
                    crossScrollingEnabled={true}
                    appointmentTooltipComponent={function (e){return null}}
                    showAllDayPanel={false}
                    remoteFiltering={true}
                    //       className={"bereitschaftstool"}
                    onAppointmentFormOpening={function (e) {          
                        var d = new Date();
                        d.setDate(e.appointmentData.tagdate.getDate()-1)

                        var monat = (1+d.getMonth())

                        localStorage.setItem('wbtag_start', d.getFullYear() + "-" + monat + "-" + (d.getDate()))
                            history.push({
                                pathname: '/wochenbericht/anzeigen',
                                state: {  // location state
                                  user_id: monteur_id, 
                                  start: d,
                                  name: monteur_name
                                },
                              });                         
                        e.cancel = true;
                    }}
                    ref={(ref) => { scheduler = ref }}
                >
                    <Resource
                        dataSource={wbtag_status}
                        fieldExpr="wbtagstatus_id"
                        valueExpr="wbtagstatus_id"
                        displayExpr="text"
                        colorExpr="farbe"
                        label="Name"

                    />

                </Scheduler>
            </div>

    

        </React.Fragment>
    );
};
