import React, { useState, useEffect } from 'react';
import { getToken_api } from '../../api/auth';
import Form, { Item, GroupItem, Label } from 'devextreme-react/form';
import ODataStore from 'devextreme/data/odata/store';
import DataSource from 'devextreme/data/data_source';
import { useParams } from 'react-router-dom'
import Guid from 'devextreme/core/guid';
import { networkConfig } from '../../networkConfig';
import DropDownButton from 'devextreme-react/drop-down-button';
import { useHistory } from "react-router-dom";
import notify from 'devextreme/ui/notify';
import { Tourauftrag_edit_comp } from '../../components';
export default function () {

   
    let { tourauftrag_id } = useParams()
    return (

        <React.Fragment>

            <Tourauftrag_edit_comp tourauftrag_id={tourauftrag_id}></Tourauftrag_edit_comp>


        </React.Fragment>
    );
};
