import React, { useState, useEffect } from 'react';
import { getToken_api } from '../../../api/auth';
import Form, { Item, SimpleItem, Label } from 'devextreme-react/form';
import ODataStore from 'devextreme/data/odata/store';
import DataGrid, { Column, Editing, Lookup } from 'devextreme-react/data-grid';
import { useParams } from 'react-router-dom'
import Guid from 'devextreme/core/guid';
import ResponsiveBox, {
  Row,
  Col,
  Item as Resitem,
  Location
} from 'devextreme-react/responsive-box';
import { networkConfig } from '../../../networkConfig';
import { Button } from 'devextreme-react';
export default function () {

  const [token, setToken] = useState('')
  const [dataSource_Bueros, setdataSource_Bueros] = useState('')
  const [dataSource_Bueros_all, setdataSource_Bueros_all] = useState('')
  const [dataSource_Bueros_auswahl, setdataSource_Bueros_auswahl] = useState('')
  const [dataSource_Rollen, setdataSource_Rollen] = useState('')
  const [dataSource_Rollen_all, setdataSource_Rollen_all] = useState('')
  const [formData, setformData] = useState('')
  const [buero_select_options, setbuero_select_options] = useState('')




  let { user_id } = useParams()
  const saveButton = {
    icon: 'save',
    text: 'Speichern',
    onClick: () => {

      const dataSource = new ODataStore({
        type: 'odata',
        url: networkConfig.dataEndpoint + 'benutzer',
        key: 'benutzer_id',
        withCredentials: true,
        beforeSend: function (e) {
          e.headers = {
            "Authorization": 'Bearer ' + token
          };
        }
      });
      dataSource.update(new Guid(user_id), formData)

    }
  };
  useEffect(() => {
    getToken_api().then(token => {
      setToken(token)
      setdataSource_Bueros({
        store: {
          type: 'odata',
          url: networkConfig.dataEndpoint + 'benutzerbuero',
          key: 'benutzerbuero_id',
          withCredentials: true,
          beforeSend: function (e) {
            e.headers = {
              "Authorization": 'Bearer ' + token
            };
          },
          onInserting: function (values, key) {
            values.mandant_id = "1"
            values.benutzer_id = new Guid(user_id)
          }
        },
        select: ['benutzerbuero_id', 'buero_id'],
        sort:["Name"],
        filter: ['benutzer_id', '=', new Guid(user_id)],

      });
      setdataSource_Bueros_all({
        store: {
          type: 'odata',
          url: networkConfig.dataEndpoint + 'bueros',
          key: 'buero_id',
          withCredentials: true,
          beforeSend: function (e) {
            e.headers = {
              "Authorization": 'Bearer ' + token
            };
          },

        }
      });
      setdataSource_Bueros_auswahl({
        store: {
          type: 'odata',
          url: networkConfig.dataEndpoint + 'bueros',
          key: 'buero_id',
          withCredentials: true,
          beforeSend: function (e) {
            e.headers = {
              "Authorization": 'Bearer ' + token
            };
          },

        }
      });

      setdataSource_Rollen({
        store: {
          type: 'odata',
          url: networkConfig.dataEndpoint + 'userrolle',
          key: 'userrolle_id',
          withCredentials: true,
          beforeSend: function (e) {
            e.headers = {
              "Authorization": 'Bearer ' + token
            };
          },
          onInserting: function (values, key) {
            values.mandant_id = "1"
            values.benutzer_id = new Guid(user_id)
          }
        },
        select: ['userrolle_id', 'rolle_id'],
        sort:["bezeichnung"],
        filter: ['benutzer_id', '=', new Guid(user_id)],

      });

      setdataSource_Rollen_all({
        store: {
          type: 'odata',
          url: networkConfig.dataEndpoint + 'rollen',
          key: 'rollen_id',
          withCredentials: true,
          beforeSend: function (e) {
            e.headers = {
              "Authorization": 'Bearer ' + token
            };
          }
        }

      });


      const dataSource = new ODataStore({
        type: 'odata',
        url: networkConfig.dataEndpoint + 'benutzer',
        key: 'benutzer_id',
        withCredentials: true,
        beforeSend: function (e) {

          e.headers = {
            "Authorization": 'Bearer ' + token
          };
        }
      });
      dataSource.load({ filter: ['benutzer_id', '=', new Guid(user_id)] })
        .then(
          (data) => { setformData(data[0]); },
          (error) => { /* Handle the "error" here */ }
        );

      const store = new ODataStore({
        type: 'odata',
        url: networkConfig.dataEndpoint + 'bueros',
        key: 'buero_id',
        withCredentials: true,
        beforeSend: function (e) {
          e.headers = {
            "Authorization": 'Bearer ' + token
          };
        },
      });

      setbuero_select_options({ dataSource: { store: store, sort: ['Name'] }, displayExpr: "Name", valueExpr: "buero_id", searchEnabled: true })
    })
  }, []);


  return (

    <React.Fragment>
      <h2 className={'content-block'}>Benutzer bearbeiten</h2>

      <div className={'content-block dx-card responsive-paddings'}>
        <Form
          id={'form'}
          formData={formData}
          labelLocation={'top'}
          colCount={4}
        >
          <Item dataField="mailadresse" editorOptions={{ disabled: true }} />
          <Item dataField="name" editorOptions={{ disabled: true }} />
          <Item dataField="title" colSpan={2} editorOptions={{ disabled: false }} />
          <Item dataField="telefon" editorOptions={{ disabled: false }} />
          <Item dataField="buero_id" editorType="dxSelectBox" editorOptions={buero_select_options}>
            <Label text="Standort" />
          </Item>
          <Item dataField="aksn" editorOptions={{}}>
            <Label text="Mitarbeiter-Nr" />
          </Item>
          <Item dataField="isDisabled" editorType="dxCheckBox" editorOptions={{}}>
            <Label text="deaktiviert" />
          </Item>
          <Item dataField="dispo" editorType="dxCheckBox" editorOptions={{}}>
            <Label text="Anzeige im Dispotool" />
          </Item>
          <Item dataField="bereitschaft" editorType="dxCheckBox" editorOptions={{}}>
            <Label text="Anzeige im Bereitschaftstool" />
          </Item>
          <Item dataField="wochenbericht" editorType="dxCheckBox" editorOptions={{}}>
            <Label text="Anzeige im Wochenbericht" />
          </Item>

          <SimpleItem itemType="button"
            horizontalAlignment="left"
            buttonOptions={saveButton}>
          </SimpleItem>
        </Form>
      </div>
      <ResponsiveBox>
        <Row ratio={1} />
        <Col ratio={1} />
        <Col ratio={1} />
        <Resitem>
          <Location row={0} col={0} />
          <div className={'content-block dx-card responsive-paddings'}>
            <Button
              icon='save'
              text='alle Büros hinzufügen'
              onClick={function (e) {

                const headers = new Headers();
                const bearer = `Bearer ${token}`;
                headers.append("Authorization", bearer);
                headers.append("Content-Type", "application/json;charset=UTF-8")
                const options = {
                  method: "GET",
                  headers: headers,
                };


                return fetch(networkConfig.serverEndpoint + "api/user/SetAllBueros?user=" + user_id, options)
                  .then(response => response.json())
                  .then(data => {
                    if (data.success == true) {
                      setdataSource_Bueros({
                        store: {
                          type: 'odata',
                          url: networkConfig.dataEndpoint + 'benutzerbuero',
                          key: 'benutzerbuero_id',
                          withCredentials: true,
                          beforeSend: function (e) {
                            e.headers = {
                              "Authorization": 'Bearer ' + token
                            };
                          },
                          onInserting: function (values, key) {
                            values.mandant_id = "1"
                            values.benutzer_id = new Guid(user_id)
                          }
                        },
                        select: ['benutzerbuero_id', 'buero_id'],
                        sort:["Name"],
                        filter: ['benutzer_id', '=', new Guid(user_id)],
                
                      });
                    }
                  }
                  )
                  .catch(error => console.log(error));
                

              }
              }>
            </Button>
            <DataGrid
              dataSource={dataSource_Bueros}
              showBorders={true}
            >
              <Editing
                mode="row"
                allowUpdating={false}
                allowDeleting={true}
                allowAdding={true} />
              <Column
                dataField="buero_id"
                caption="Name"
              >
                <Lookup dataSource={dataSource_Bueros_all} displayExpr="Name" valueExpr="buero_id" />
              </Column>

            </DataGrid>
          </div>
        </Resitem>
        <Resitem>
          <Location row={0} col={1} />
          <div className={'content-block dx-card responsive-paddings'}>

            <DataGrid
              dataSource={dataSource_Rollen}
              showBorders={true}
            >
              <Editing
                mode="row"
                allowUpdating={false}
                allowDeleting={true}
                allowAdding={true} />
              <Column
                dataField="rolle_id"
                caption="Bezeichnung"
              >
                <Lookup dataSource={dataSource_Rollen_all} displayExpr="bezeichnung" valueExpr="rollen_id" />
              </Column>
            </DataGrid>
          </div>
        </Resitem>
      </ResponsiveBox>



    </React.Fragment>
  );
};
