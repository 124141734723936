import React, { useState, useEffect, useRef } from 'react';
import { getToken_api } from '../../api/auth';
import { useHistory, useLocation } from "react-router-dom";
import ODataStore from 'devextreme/data/odata/store';
import DataSource from 'devextreme/data/data_source';
import { confirm, alert } from 'devextreme/ui/dialog';
import Guid from 'devextreme/core/guid';
import { networkConfig } from '../../networkConfig';
import Scheduler, { Resource, View } from 'devextreme-react/scheduler';
import notify from 'devextreme/ui/notify';
import SelectBox from 'devextreme-react/select-box';
import { Popup, Position, ToolbarItem } from 'devextreme-react/popup';
import { Button } from 'devextreme-react/button';
import { CheckBox } from 'devextreme-react/check-box';
import Form, { Item, TabbedItem, TabPanelOptions, Tab, Label, GroupItem } from 'devextreme-react/form';
import { DataGrid, DropDownBox, DropDownButton, TextArea, TextBox } from 'devextreme-react';
import { exportDefaultSpecifier } from '@babel/types';

export default function () {
    const [token, setToken] = useState()
    const [popupData, setpopupData] = useState([])
    const [tagdata, setTagdata] = useState([])
    const [bueros, setbueros] = useState('')
    const [buero_auswahl, setbuero_auswahl] = useState()
    const [monteur_auswahl, setmonteur_auswahl] = useState()
    const [wbtyp, setwbtyp] = useState('')
    const history = useHistory();
    const [kalender, setkalender] = useState('')
    const [buero_id, setbuero_id] = useState()
    const [monteur_id, setmonteur_id] = useState()
    const [editable, seteditable] = useState(false)
    const [popup_visible, setpopup_visible] = useState(false)
    const [buero_vorauswahl, setbuero_vorauswahl] = useState()
    const [monteur_vorauswahl, setmonteur_vorauswahl] = useState()
    const [typ_select_options, settyp_select_options] = useState('')
    const [monteur_name, setmonteur_name] = useState('')
    const [start, setstart] = useState()
    const [dayView, setdayView] = useState(true)
    const [startDate, setstartDate] = useState(new Date(localStorage.getItem('wbtag_start')))
    const [viewbeginDatum, setviewbeginDatum] = useState()
    const [viewendeDatum, setviewendeDatum] = useState()


    const [bereitschaft, setbereitschaft] = useState()
    const [ausloese, setausloese] = useState()
    const [status, setstatus] = useState()
    const [wbtagstatus_id, setwbtagstatus_id] = useState()
    const [wbtagstatus_name, setwbtagstatus_name] = useState()
    const [wbstatus_items, setwbstatus_items] = useState([])
    const [grund, setgrund] = useState()
    const [readOnly, setreadOnly] = useState(false)
    const [readOnlyGrund, setreadOnlyGrund] = useState(true)

    const [summaryData, setsummaryData] = useState()



    let location = useLocation();

    const speichern = function (e) {

        const headers = new Headers();
        const bearer = `Bearer ${token}`;
        headers.append("Authorization", bearer);
        headers.append("Content-Type", "application/json;charset=UTF-8")
        const options = {
            method: "POST",
            headers: headers,
            body: JSON.stringify(popupData)
        };
        console.log(popupData)
        return fetch(networkConfig.serverEndpoint + "api/wb/AddAppointment", options)
            .then(response => response.json())
            .then(data => {
                if (data.success == true) {
                    setpopup_visible(false)
                    scheduler.current.instance.getDataSource().reload();
                    tagabrufen(popupData.startdate);
                    notify({
                        message: 'Eintrag wurde gespeichert',

                    }, 'success', 1000);

                } else {
                    notify({
                        message: 'Fehler :(',
                    }, 'error', 5000);
                }

            }
            )
            .catch(error => console.log(error));
    }
    const loeschen = function (e) {

        const headers = new Headers();
        const bearer = `Bearer ${token}`;
        headers.append("Authorization", bearer);
        headers.append("Content-Type", "application/json;charset=UTF-8")
        const options = {
            method: "POST",
            headers: headers,
            body: JSON.stringify(popupData)
        };
        console.log(popupData)
        return fetch(networkConfig.serverEndpoint + "api/wb/DeleteAppointment", options)
            .then(response => response.json())
            .then(data => {
                if (data.success == true) {
                    setpopup_visible(false)

                    scheduler.current.instance.getDataSource().reload();
                    notify({
                        message: 'Eintrag wurde gelöscht',

                    }, 'success', 1000);

                } else {
                    notify({
                        message: 'Fehler :(',
                    }, 'error', 5000);
                }

            }
            )
            .catch(error => console.log(error));
    }
    const status_wechseln = function (e) {

        switch (e.itemData) {

            case 'in Bearbeitung':
                var tag = { wbtag_id: tagdata.wbtag_id, user_id: monteur_id, tagdate: scheduler.current.instance.getStartViewDate(), bereitschaft: bereitschaft, ausloese: ausloese, wbtagstatus_id: 0, hinweise: grund }
                break;
            case 'abgeschlossen':
                var tag = { wbtag_id: tagdata.wbtag_id, user_id: monteur_id, tagdate: scheduler.current.instance.getStartViewDate(), bereitschaft: bereitschaft, ausloese: ausloese, wbtagstatus_id: 2, hinweise: grund }
                break;
            case 'in Prüfung':
                var tag = { wbtag_id: tagdata.wbtag_id, user_id: monteur_id, tagdate: scheduler.current.instance.getStartViewDate(), bereitschaft: bereitschaft, ausloese: ausloese, wbtagstatus_id: 1, hinweise: grund }
                break;
            case 'in Bearbeitung (zurückgewiesen von Meister)':
                var tag = { wbtag_id: tagdata.wbtag_id, user_id: monteur_id, tagdate: scheduler.current.instance.getStartViewDate(), bereitschaft: bereitschaft, ausloese: ausloese, wbtagstatus_id: 4, hinweise: grund }

                break;
            case 'zurückweisen an Monteur':
                var tag = { wbtag_id: tagdata.wbtag_id, user_id: monteur_id, tagdate: scheduler.current.instance.getStartViewDate(), bereitschaft: bereitschaft, ausloese: ausloese, wbtagstatus_id: 4, hinweise: grund }

                break;
            case 'in Bearbeitung (Änderung in MoScheinen)':
                var tag = { wbtag_id: tagdata.wbtag_id, user_id: monteur_id, tagdate: scheduler.current.instance.getStartViewDate(), bereitschaft: bereitschaft, ausloese: ausloese, wbtagstatus_id: 3, hinweise: grund }

                break;



        }

        if (dayView == false) {
            tag = { ...tag, start: scheduler.current.instance.getStartViewDate(), ende: scheduler.current.instance.getEndViewDate() }
            const headers = new Headers();
            const bearer = `Bearer ${token}`;
            headers.append("Authorization", bearer);
            headers.append("Content-Type", "application/json;charset=UTF-8")
            const options = {
                method: "POST",
                headers: headers,
                body: JSON.stringify(tag)
            };
            console.log(tag)
            return fetch(networkConfig.serverEndpoint + "api/wb/UpdateTage", options)
                .then(response => response.json())
                .then(data => {
                    console.log(data)
                    if (data.success == true) {
                        scheduler.current.instance.getDataSource().reload();
                        tagabrufen(tag.tagdate);
                        notify({
                            message: 'Gespeichert',
                        }, 'success', 3000);

                    } else {
                        notify({
                            message: 'Fehler :(',
                        }, 'error', 5000);
                    }

                }
                )
                .catch(error => console.log(error));
        } else {


            if (e.itemData == 'in Prüfung') {

                var tag2 = { user_id: monteur_id, tagdate: scheduler.current.instance.getStartViewDate() }
                const headers = new Headers();
                const bearer = `Bearer ${token}`;
                headers.append("Authorization", bearer);
                headers.append("Content-Type", "application/json;charset=UTF-8")
                const options = {
                    method: "POST",
                    headers: headers,
                    body: JSON.stringify(tag2)
                };

                return fetch(networkConfig.serverEndpoint + "api/wb/PruefenTag", options)
                    .then(response => response.json())
                    .then(data => {
                        console.log(data)
                        if (data.success == true) {
                            if (data.result_set == true) {
                                const headers = new Headers();
                                const bearer = `Bearer ${token}`;
                                headers.append("Authorization", bearer);
                                headers.append("Content-Type", "application/json;charset=UTF-8")
                                const options = {
                                    method: "POST",
                                    headers: headers,
                                    body: JSON.stringify(tag)
                                };
                                console.log(tag)
                                return fetch(networkConfig.serverEndpoint + "api/wb/UpdateTag", options)
                                    .then(response => response.json())
                                    .then(data => {
                                        console.log(data)
                                        if (data.success == true) {
                                            scheduler.current.instance.getDataSource().reload();
                                            tagabrufen(tag.tagdate);
                                            notify({
                                                message: 'Gespeichert',
                                            }, 'success', 3000);

                                        } else {
                                            notify({
                                                message: 'Fehler :(',
                                            }, 'error', 5000);
                                        }

                                    }
                                    )
                                    .catch(error => console.log(error));
                            }
                            else {

                                let result = confirm(data.userMessage, "Trotzdem fortfahren?");
                                result.then((dialogResult) => {
                                    if (dialogResult == true) {
                                        const headers = new Headers();
                                        const bearer = `Bearer ${token}`;
                                        headers.append("Authorization", bearer);
                                        headers.append("Content-Type", "application/json;charset=UTF-8")
                                        const options = {
                                            method: "POST",
                                            headers: headers,
                                            body: JSON.stringify(tag)
                                        };
                                        console.log(tag)
                                        return fetch(networkConfig.serverEndpoint + "api/wb/UpdateTag", options)
                                            .then(response => response.json())
                                            .then(data => {
                                                console.log(data)
                                                if (data.success == true) {
                                                    scheduler.current.instance.getDataSource().reload();
                                                    tagabrufen(tag.tagdate);
                                                    notify({
                                                        message: 'Gespeichert',
                                                    }, 'success', 3000);

                                                } else {
                                                    notify({
                                                        message: 'Fehler :(',
                                                    }, 'error', 5000);
                                                }

                                            }
                                            )
                                            .catch(error => console.log(error));

                                    }
                                    // alert(dialogResult ? "Confirmed" : "Canceled");
                                });
                            }


                        } else {
                            alert(data.userMessage, "FEHLER");
                        }

                    }
                    )
                    .catch(error => console.log(error));

            }
            else {
                const headers = new Headers();
                const bearer = `Bearer ${token}`;
                headers.append("Authorization", bearer);
                headers.append("Content-Type", "application/json;charset=UTF-8")
                const options = {
                    method: "POST",
                    headers: headers,
                    body: JSON.stringify(tag)
                };
                console.log(tag)
                return fetch(networkConfig.serverEndpoint + "api/wb/UpdateTag", options)
                    .then(response => response.json())
                    .then(data => {
                        console.log(data)
                        if (data.success == true) {
                            scheduler.current.instance.getDataSource().reload();
                            tagabrufen(tag.tagdate);
                            notify({
                                message: 'Gespeichert',
                            }, 'success', 3000);

                        } else {
                            notify({
                                message: 'Fehler :(',
                            }, 'error', 5000);
                        }

                    }
                    )
                    .catch(error => console.log(error));
            }
        }


    }
    const pruefen = function (e) {

        if (dayView == true) {
            var tag = { user_id: monteur_id, tagdate: scheduler.current.instance.getStartViewDate() }
            const headers = new Headers();
            const bearer = `Bearer ${token}`;
            headers.append("Authorization", bearer);
            headers.append("Content-Type", "application/json;charset=UTF-8")
            const options = {
                method: "POST",
                headers: headers,
                body: JSON.stringify(tag)
            };
            console.log(tag)
            return fetch(networkConfig.serverEndpoint + "api/wb/PruefenTag", options)
                .then(response => response.json())
                .then(data => {
                    console.log(data)
                    if (data.success == true) {
                        if (data.result_set == true) {
                            notify({
                                message: 'Prüfung Erfolgreich',
                            }, 'success', 3000);
                        }
                        else {
                            alert(data.userMessage, "HINWEIS");
                            // let result = confirm(data.userMessage,"Trotzdem fortfahren?");
                            //   result.then((dialogResult) => {
                            // alert(dialogResult ? "Confirmed" : "Canceled");
                            //  });
                        }


                    } else {
                        alert(data.userMessage, "FEHLER");
                    }

                }
                )
                .catch(error => console.log(error));
        } else {
            var tag = { user_id: monteur_id, start: scheduler.current.instance.getStartViewDate(), ende: scheduler.current.instance.getEndViewDate() }
            const headers = new Headers();
            const bearer = `Bearer ${token}`;
            headers.append("Authorization", bearer);
            headers.append("Content-Type", "application/json;charset=UTF-8")
            const options = {
                method: "POST",
                headers: headers,
                body: JSON.stringify(tag)
            };
            console.log(tag)
            return fetch(networkConfig.serverEndpoint + "api/wb/PruefenTage", options)
                .then(response => response.json())
                .then(data => {
                    console.log(data)
                    if (data.success == true) {
                        if (data.result_set == true) {
                            notify({
                                message: 'Prüfung Erfolgreich',
                            }, 'success', 3000);
                        }
                        else {
                            alert(data.userMessage, "HINWEIS");
                            // let result = confirm(data.userMessage,"Trotzdem fortfahren?");
                            //   result.then((dialogResult) => {
                            // alert(dialogResult ? "Confirmed" : "Canceled");
                            //  });
                        }


                    } else {
                        alert(data.userMessage, "FEHLER");
                    }

                }
                )
                .catch(error => console.log(error));
        }

    }
    const tagabrufen = function (e) {
        var tag = { user_id: monteur_id, tagdate: e }
        const headers = new Headers();
        const bearer = `Bearer ${token}`;
        headers.append("Authorization", bearer);
        headers.append("Content-Type", "application/json;charset=UTF-8")
        const options = {
            method: "POST",
            headers: headers,
            body: JSON.stringify(tag)
        };
        console.log(tag)
        return fetch(networkConfig.serverEndpoint + "api/wb/GetTag", options)
            .then(response => response.json())
            .then(data => {
                console.log(data)
                if (data.success == true) {

                    if (data.result_set != undefined) {
                        setTagdata(data.result_set)
                        setbereitschaft(data.result_set.bereitschaft)
                        setausloese(data.result_set.ausloese)
                        setwbtagstatus_id(data.result_set.wbtagstatus_id)
                        setwbtagstatus_name(data.result_set.text)
                        setgrund(data.result_set.hinweise)
                        console.log(data.result_set)

                    } else {
                        setTagdata([])
                        setbereitschaft(false)
                        setausloese('')
                        setwbtagstatus_id(0)
                        setwbtagstatus_name('Tag nicht bearbeitet')
                        setgrund('')

                    }

                } else {
                    notify({
                        message: 'Fehler :(',
                    }, 'error', 5000);
                }

            }
            )
            .catch(error => console.log(error));

    }

    const summary_abrufen = function (start, ende) {

        var tag = { user_id: monteur_id, start: start, ende: ende }
        const headers = new Headers();
        const bearer = `Bearer ${token}`;
        headers.append("Authorization", bearer);
        headers.append("Content-Type", "application/json;charset=UTF-8")
        const options = {
            method: "POST",
            headers: headers,
            body: JSON.stringify(tag)
        };
        console.log(tag)
        return fetch(networkConfig.serverEndpoint + "api/wb/GetSummary", options)
            .then(response => response.json())
            .then(data => {
                console.log(data)
                if (data.success == true) {
                    console.log(data)
                    setsummaryData(data.result_set)
                } else {
                    notify({
                        message: 'Fehler :(',
                    }, 'error', 5000);
                }

            }
            )
            .catch(error => console.log(error));

    }

    const form = useRef();
    const scheduler = useRef();

    useEffect(() => {

        var roles = localStorage.getItem("roles")
        if (roles.indexOf('wochenbericht_admin') != -1) {
            setwbstatus_items(['in Bearbeitung',
                'in Prüfung',
                'abgeschlossen',
                'in Bearbeitung (Änderung in MoScheinen)',
                'in Bearbeitung (zurückgewiesen von Meister)'])
            setreadOnly(false)
            setreadOnlyGrund(false)
        } else if (roles.indexOf('wochenbericht_meister') != -1) {
            if (roles.indexOf('wochenbericht_monteur') != -1) {
                if (tagdata.wbtagstatus_id == 1) {
                    setwbstatus_items(['in Prüfung',
                        'zurückweisen an Monteur',
                        'abgeschlossen',
                    ])
                    setreadOnly(false)
                } else {
                    setreadOnly(true)
                }
            } else {
                if (tagdata.wbtagstatus_id == 1) {
                    setwbstatus_items([
                        'zurückweisen an Monteur',
                        'abgeschlossen'])
                    setreadOnly(false)
                    setreadOnlyGrund(false)
                } else {
                    setreadOnly(true)
                }
            }

        } else if (roles.indexOf('wochenbericht_monteur') != -1) {
            if (tagdata.wbtagstatus_id != 1 && tagdata.wbtagstatus_id != 2) {
                setwbstatus_items([
                    'in Prüfung'])
                setreadOnly(false)
            } else {
                setreadOnly(true)
            }
        } else if (roles.indexOf('wochenbericht_innendienst') != -1) {
            setreadOnly(true)
        }
    }, [tagdata]);
    useEffect(() => {
        console.log(location)
        var datum;
        try {
            setmonteur_id(new Guid(location.state.user_id))
            localStorage.setItem('wbtag_monteur', location.state.user_id)
            setmonteur_name(location.state.name)
            localStorage.setItem('wbtag_monteur_name', location.state.name)
            //  setstart(new Date(location.state.start))
            datum = new Date(location.state.start)
            //    localStorage.setItem('wbtag_start', Date.parse(location.state.start))
        } catch {

            setmonteur_id(new Guid(localStorage.getItem('wbtag_monteur')))
            setmonteur_name(localStorage.getItem('wbtag_monteur_name'))
            datum = new Date(localStorage.getItem('wbtag_start'))
            setstart(new Date(localStorage.getItem('wbtag_start')))

        }
        ;



        getToken_api().then(token => {
            setToken(token)

            var roles = localStorage.getItem("roles")
            if (roles.indexOf('wochenbericht_meister') == -1 && roles.indexOf('wochenbericht_admin') == -1 && roles.indexOf('wochenbericht_innendienst') == -1) {


            }
            setkalender(new DataSource({
                store: {
                    type: 'odata',
                    url: networkConfig.dataEndpoint + 'wbappointment_gesamt_view',
                    key: 'wbtag_id',
                    withCredentials: true,
                    beforeSend: function (e) {
                        e.headers = {
                            "Authorization": 'Bearer ' + token
                        };
                    }
                }, filter: ["user_id", "=", new Guid(localStorage.getItem('wbtag_monteur'))], paginate: false
            })
            )
            setwbtyp(new DataSource({
                store: {
                    type: 'odata',
                    url: networkConfig.dataEndpoint + 'wbtyp',
                    key: 'wbtyp_id',
                    withCredentials: true,
                    beforeSend: function (e) {
                        e.headers = {
                            "Authorization": 'Bearer ' + token
                        };
                    }
                }
            })
            )
            const data = new DataSource({
                store: {
                    type: 'odata',
                    url: networkConfig.dataEndpoint + 'wbtyp',
                    key: 'wbtyp_id',
                    withCredentials: true,
                    beforeSend: function (e) {
                        e.headers = {
                            "Authorization": 'Bearer ' + token
                        };
                    }
                }, filter: ["auswahlmoeglich", "=", 1]
            });

            settyp_select_options({
                dataSource: data, displayExpr: "bezeichnung", valueExpr: "wbtyp_id", onValueChanged: function (args) {
                    var daten = args.component.getDataSource().items();

                    for (var i = 0; i < daten.length; i++) {
                        if (daten[i].wbtyp_id == args.value) {
                            if (daten[i].allday == true) {

                                form.current.instance.getEditor("allDay").option("value", true)
                                // form.getEditor("allday").option("disabled", true)

                            } else {
                                form.current.instance.getEditor("allDay").option("value", false)
                                //   form.getEditor("allday").option("disabled", false)
                            }
                            form.current.instance.getEditor("detail").option("value", daten[i].bezeichnung)
                            form.current.instance.getEditor("bemerkung").option("placeholder", daten[i].bemerkung)
                            form.current.instance.getEditor("zusatz").option("placeholder", daten[i].zusatz)

                            if (daten[i].bezeichnung == 'Urlaub' || daten[i].bezeichnung == 'Feiertag' || daten[i].bezeichnung == 'Krankheit' || daten[i].bezeichnung == 'AZV' || daten[i].bezeichnung == 'Unbezahlte Freistellung') {
                                form.current.instance.getEditor("detail").option("disabled", true)
                            } else {
                                form.current.instance.getEditor("detail").option("disabled", false)
                            }
                        }
                    }
                }
            })

            var tag = { user_id: new Guid(localStorage.getItem('wbtag_monteur')), tagdate: datum }

            const headers = new Headers();
            const bearer = `Bearer ${token}`;
            headers.append("Authorization", bearer);
            headers.append("Content-Type", "application/json;charset=UTF-8")
            const options = {
                method: "POST",
                headers: headers,
                body: JSON.stringify(tag)
            };
            console.log(tag)
            return fetch(networkConfig.serverEndpoint + "api/wb/GetTag", options)
                .then(response => response.json())
                .then(data => {

                    console.log(data)
                    if (data.success == true) {

                        if (data.result_set != undefined) {
                            setTagdata(data.result_set)
                            setbereitschaft(data.result_set.bereitschaft)
                            setausloese(data.result_set.ausloese)
                            setwbtagstatus_id(data.result_set.wbtagstatus_id)
                            setwbtagstatus_name(data.result_set.text)
                            setgrund(data.result_set.hinweise)
                            console.log(data.result_set)
                            scheduler.current.instance.getDataSource().reload();
                        } else {
                            setTagdata([])
                            setbereitschaft(false)
                            setausloese('')
                            setwbtagstatus_id(0)
                            setwbtagstatus_name('Tag nicht bearbeitet')
                            setgrund('')
                        }

                    } else {
                        notify({
                            message: 'Fehler :(',
                        }, 'error', 5000);
                    }

                }
                )
                .catch(error => console.log(error));


        })
    }, []);


    const popup_oeffnen = function (e) {
        console.log(e)
        setstartDate(e.beginn)
        setpopupData({ user_id: monteur_id, wbappointment_id: e.molohn_id, datum: e.beginn, startdate: e.beginn, enddate: e.ende, wbtyp_id: e.wbtyp_id, detail: e.detail, bemerkung: e.bemerkung, zusatz: e.zusatz })
        setpopup_visible(true)
    }



    return (

        <React.Fragment>

            <div className="dx-toolbar-items-container" style={{ height: "60px" }}>
                <div className="dx-toolbar-before">
                    <h2 className={'content-block'}>Wochenbericht {monteur_name}</h2>
                </div>

            </div>
            <div className={'content-block dx-card responsive-paddings'} style={{ position: "relative" }} >

                <Scheduler
                    // id="scheduler"
                    timeZone="Europe/Berlin"
                    dataSource={kalender}
                    views={["day", "workWeek", "week"]}
                    defaultCurrentView="day"
                    currentDate={startDate}
                    height={"700px"}
                    recurrenceRuleExpr=""
                    editing={true}
                    startDateExpr="beginn"
                    endDateExpr="ende"
                    crossScrollingEnabled={true}
                    appointmentTooltipComponent={function (e) { return null }}
                    textExpr="detail"
                    remoteFiltering={true}
                    className={"kalender_links"}
                    onAppointmentFormOpening={function (e) {

                        if (readOnly == false) {
                            popup_oeffnen(e.appointmentData)
                        }
                        e.cancel = true;
                    }}

                    ref={scheduler}
                    onContentReady={ function (e) {
                        e.component.scrollToTime(6, 0)
                    }}
                    onOptionChanged={function (e) {
                        if (e.name == 'currentDate') {
                            tagabrufen(e.value)
                        }
                        if (e.name == 'currentView') {
                            tagabrufen(e.value)
                            if (e.value == "day") {
                                setdayView(true)
                                //Tagesdinge abrufen
                            } else {
                                setdayView(false)
                            }
                            //Summary abrufen
                        }

                    }}
                    appointmentComponent={function (info) {
                        function pad(num) {
                            if (num.toString().length == 1) {
                                return num.toString() + "0"
                            } else {
                                return num
                            }
                        };

                        return (<div>
                            <div>{info.data.appointmentData.detail}</div>
                            <div className='dx-scheduler-appointment-content-details'>{info.data.appointmentData.bemerkung}</div>
                            <div className='dx-scheduler-appointment-content-details'>{info.data.appointmentData.beginn.getHours()}:{pad(info.data.appointmentData.beginn.getMinutes())} - {info.data.appointmentData.ende.getHours()}:{pad(info.data.appointmentData.ende.getMinutes())}</div>
                        </div>);
                    }}
                >
                    <Resource
                        dataSource={wbtyp}
                        fieldExpr="wbtyp_id"
                        valueExpr="wbtyp_id"
                        displayExpr="bezeichnung"
                        colorExpr="farbe"
                        label="Name"

                    />

                </Scheduler>
                <div className={'kalender_rechts_box'} >

                    <CheckBox
                        width={200}
                        visible={dayView}
                        readOnly={readOnly}
                        value={bereitschaft}
                        style={{ marginTop: "10px" }}
                        text="Bereitschaft"
                        onValueChanged={function (e) { if (e.value != null) { setbereitschaft(e.value) } }}
                    />
                    <SelectBox
                        width={200}
                        visible={dayView}
                        readOnly={readOnly}
                        value={ausloese}
                        placeholder={"Auslöse"}
                        showClearButton={true}
                        style={{ marginTop: "10px" }}
                        onValueChanged={function (e) { setausloese(e.value) }}
                        items={["Service Abw. > 6h",
                            "AB Nah 5 - 20km(Abw. > 8h)",
                            "AB Nah 21 - 40km(Abw. > 8h)",
                            "AB Nah ab 41km(Abw. > 8h)",
                            "AB Fern mit Übern.Anreisetag",
                            "AB Fern mit Übern.Folgetag",
                            "AB Fern mit Übern.Abreisetag", "Qualifikation"
                        ]}

                    />
                    <TextArea
                        width={200}
                        readOnly={readOnly || readOnlyGrund}
                        visible={dayView}
                        placeholder="Abweisungsgrund"
                        value={grund}
                        style={{ marginTop: "10px" }}
                        autoResizeEnabled={true}
                        onValueChanged={function (e) { if (e.value != null) { setgrund(e.value) } }}
                    />
                    <Button
                        width={200}
                        text="Eingaben Prüfen"
                        style={{ marginTop: "10px" }}
                        visible={readOnly ? false : true}
                        onClick={pruefen}
                    />
                    <Button
                        width={200}

                        text="Speichern"
                        style={{ marginTop: "10px" }}
                        visible={dayView && readOnly ? false : true}
                        onClick={
                            function (e) {
                                if (readOnly == true || dayView == false) {
                                    notify({
                                        message: 'Nicht möglich',
                                    }, 'error', 5000);
                                    return
                                }
                                if (dayView == true) {
                                    var tag = { wbtag_id: tagdata.wbtag_id, user_id: monteur_id, tagdate: tagdata.tagdate, bereitschaft: bereitschaft, ausloese: ausloese, wbtagstatus_id: wbtagstatus_id, hinweise: grund }
                                    const headers = new Headers();
                                    const bearer = `Bearer ${token}`;
                                    headers.append("Authorization", bearer);
                                    headers.append("Content-Type", "application/json;charset=UTF-8")
                                    const options = {
                                        method: "POST",
                                        headers: headers,
                                        body: JSON.stringify(tag)
                                    };
                                    console.log(tag)
                                    return fetch(networkConfig.serverEndpoint + "api/wb/UpdateTag", options)
                                        .then(response => response.json())
                                        .then(data => {
                                            console.log(data)
                                            if (data.success == true) {
                                                scheduler.current.instance.getDataSource().reload();
                                                notify({
                                                    message: 'Gespeichert',
                                                }, 'success', 3000);

                                            } else {
                                                notify({
                                                    message: 'Fehler :(',
                                                }, 'error', 5000);
                                            }

                                        }
                                        )
                                        .catch(error => console.log(error));
                                }



                            }
                        }

                    />
                    <TextBox
                        width={200}
                        value={wbtagstatus_name}
                        visible={dayView}
                        style={{ marginTop: "10px" }}
                        readOnly={true}

                    />
                    <DropDownButton
                        readOnly={readOnly}
                        visible={dayView && readOnly ? false : true}
                        width={200}
                        text="Status wechseln"
                        items={wbstatus_items}
                        style={{ marginTop: "10px" }}
                        onItemClick={status_wechseln}

                    />
                    <DataGrid
                        width={200}
                        dataSource={summaryData}
                        style={{ marginTop: "10px" }}

                    />

                </div>


            </div>

            <Popup
                visible={popup_visible}
                onHiding={function (e) { setpopup_visible(false); setpopupData([]) }}
                dragEnabled={false}
                closeOnOutsideClick={true}
                showCloseButton={true}
                showTitle={false}

                width={500}
                height={800}
            >
                <Position
                    at="center"
                    my="center"

                />

                <ToolbarItem
                    widget="dxButton"
                    toolbar="bottom"
                    location="befor"
                    options={{
                        text: 'Löschen',
                        disabled: false,
                        onClick: loeschen
                    }}
                />
                <ToolbarItem
                    widget="dxButton"
                    toolbar="bottom"
                    location="after"
                    options={{
                        text: 'Speichern',
                        disabled: false,
                        onClick: speichern
                    }}
                />
                <Form
                    id={'form'}
                    formData={popupData}
                    labelLocation={'left'}
                    colCount={1}
                    ref={form}
                >
                    <Item dataField="wbtyp_id" editorType="dxSelectBox" colSpan={1} editorOptions={typ_select_options}>
                        <Label text="Typ" />
                    </Item>
                    <Item dataField="detail">
                        <Label text="Detail" />
                    </Item>
                    <Item dataField="bemerkung">
                        <Label text="Bemerkung" />
                    </Item>
                    <Item dataField="zusatz" editorType="dxNumberBox">
                        <Label text="ggf. Kilometer" />
                    </Item>
                    <Item dataField="allDay" editorType="dxCheckBox" editorOptions={{
                        onValueChanged: function (args) {
                            console.log(args)
                            if (args.value == true) {

                                var anfang = new Date(startDate.getFullYear(), startDate.getMonth(), startDate.getDate());
                                var ende = new Date(startDate.getFullYear(), startDate.getMonth(), startDate.getDate(), 23, 45);

                                form.current.instance.getEditor("startdate").option("value", anfang);
                                form.current.instance.getEditor("enddate").option("value", ende);
                                // form.getEditor("startdate").option("disabled", true);
                                // form.getEditor("enddate").option("disabled", true);
                            }
                            else {
                                form.current.instance.getEditor("startdate").option("disabled", false);
                                form.current.instance.getEditor("enddate").option("disabled", false);
                            };
                        }
                    }}>
                        <Label text="ganztägig" />
                    </Item>
                    <Item dataField="datum" disabled={true} editorType="dxDateBox" editorOptions={{
                    }}>
                        <Label text="Datum" />
                    </Item>
                    <Item dataField="startdate" editorType="dxDateBox" editorOptions={{
                        width: "100%",
                        type: "time",
                        pickerType: "list",

                        interval: 15,
                        acceptCustomValue: false,
                        onValueChanged: function (args) {
                            if (new Date(form.current.instance.getEditor("enddate").option("value")).getTime() < new Date(args.value).getTime()) {
                                // form.current.instance.getEditor("enddate").option("value", new Date(args.value));
                            };
                            form.current.instance.getEditor("enddate").option("min", new Date(args.value));
                        }
                    }}>
                        <Label text="Beginn" />
                    </Item>
                    <Item dataField="enddate" editorType="dxDateBox" editorOptions={{
                        width: "100%",
                        type: "time",
                        pickerType: "list",
                        interval: 15,
                        acceptCustomValue: false
                    }}>
                        <Label text="Ende" />
                    </Item>
                </Form>
            </Popup>


        </React.Fragment>
    );
};
