import React, { useState, useEffect } from 'react';
import { getToken_api } from '../../../api/auth';
import Form, { Item, SimpleItem, Label } from 'devextreme-react/form';
import ODataStore from 'devextreme/data/odata/store';
import DataGrid, { Column, Editing, Lookup } from 'devextreme-react/data-grid';
import { useParams } from 'react-router-dom'
import Guid from 'devextreme/core/guid';
import ResponsiveBox, {
    Row,
    Col,
    Item as Resitem,
    Location
} from 'devextreme-react/responsive-box';
import { networkConfig } from '../../../networkConfig';
import { Vertrag_uebersicht } from '../../../components';
export default function () {

    const [token, setToken] = useState('')
    const [formData, setformData] = useState('')
    const [buero_select_options, setbuero_select_options] = useState('')
    const [isAdmin, setisAdmin] = useState(false)
    const [deb_select_options, setdeb_select_options] = useState('')
    const bereich_select_options = { dataSource: ["OST", "WEST"], disabled:isAdmin?false:true }

    let { ein_id } = useParams()
    const saveButton = {
        icon: 'save',
        text: 'Speichern',
        onClick: () => {

            const dataSource = new ODataStore({
                type: 'odata',
                url: networkConfig.dataEndpoint + 'filialen',
                key: 'ein_id',
                withCredentials: true,
                beforeSend: function (e) {
                    e.headers = {
                        "Authorization": 'Bearer ' + token
                    };
                }
            });
            console.log(formData)
            dataSource.update(new Guid(ein_id), formData)

        }
    };

    useEffect(() => {
        console.log('Start')
        getToken_api().then(token => {
            setToken(token)
            console.log(1+token);
            const dataSource = new ODataStore({
                type: 'odata',
                url: networkConfig.dataEndpoint + 'filialen',
                key: 'ein_id',
                withCredentials: true,
                beforeSend: function (e) {

                    e.headers = {
                        "Authorization": 'Bearer ' + token
                    };
                }
            });
            console.log(2+token);
            dataSource.load({ filter: ['ein_id', '=', new Guid(ein_id)] })
                .then(
                    (data) => { console.log(7+data); setformData(data[0]); console.log(8+formData) },
                    (error) => { console.log(9+error) }
                );
            console.log(3+token);
            const store = new ODataStore({
                type: 'odata',
                url: networkConfig.dataEndpoint + 'bueros',
                key: 'buero_id',
                withCredentials: true,
                beforeSend: function (e) {
                    e.headers = {
                        "Authorization": 'Bearer ' + token
                    };
                },
            });
            console.log(4+token)
            setbuero_select_options({ dataSource: { store }, displayExpr: "Name", valueExpr: "buero_id", searchEnabled: true , disabled:isAdmin?false:true  })
            console.log(token)
            const store2 = new ODataStore({
                type: 'odata',
                url: networkConfig.dataEndpoint + 'debitor',
                key: 'deb_id',
                withCredentials: true,
                beforeSend: function (e) {
                    e.headers = {
                        "Authorization": 'Bearer ' + token
                    };
                },
            });
            console.log(5+token)
            setdeb_select_options({
                dataSource: { store: store2 }, displayExpr: function (item) {
                    // "item" can be null
                    return item && item.nr + ' (' + item.name + ')';
                }, valueExpr: "deb_id", searchEnabled: true, disabled:isAdmin?false:true
            })
           
           
            if (localStorage.getItem("roles").indexOf("admin;") != -1){
                console.log( localStorage.getItem("roles"))
                setisAdmin(true)
              }
           
        })
    }, [isAdmin]);


    return (

        <React.Fragment>
            <h2 className={'content-block'}>Standort bearbeiten</h2>

            <div className={'content-block dx-card responsive-paddings'}>
                <Form
                    id={'form'}
                    formData={formData}
                    labelLocation={'top'}
                    colCount={4}
                >
                    <Item dataField="ein_nr" colSpan={4} editorOptions={{}}>
                        <Label text="Nummer" />
                    </Item>
                    <Item dataField="name" colSpan={2} editorOptions={{}}>
                        <Label text="Name" />
                    </Item>
                    <Item dataField="name2" editorOptions={{}}>
                        <Label text="Name2" />
                    </Item>
                    <Item dataField="name3" editorOptions={{}}>
                        <Label text="Name3" />
                    </Item>
                    <Item dataField="strasse" colSpan={2} editorOptions={{}}>
                        <Label text="Straße" />
                    </Item>
                    <Item dataField="plz" editorOptions={{
                        onValueChanged: function (e) {

                            if (e.previousValue != "") {
                                console.log(e.previousValue)
                                const headers = new Headers();
                                const bearer = `Bearer ${token}`;
                                headers.append("Authorization", bearer);
                                console.log(formData)
                                console.log(JSON.stringify(formData))
                                const options = {
                                    method: "GET",
                                    headers: headers,

                                };

                                return fetch(networkConfig.serverEndpoint + "api/auftrag/GetBuero_fromPLZ?plz=" + e.value, options)
                                    .then(response => response.json())
                                    .then(data => {
                                        console.log(data)
                                        if (data.success == true) {
                                            setformData({ ...formData, buero_id: new Guid(data.result_set) })
                                        }
                                    }
                                    )
                                    .catch(error => console.log(error));

                            }


                        }
                    }}>
                        <Label text="PLZ" />
                    </Item>
                    <Item dataField="ort" editorOptions={{}}>
                        <Label text="Ort" />
                    </Item>
                    <Item dataField="deb_id" colSpan={2} editorType="dxSelectBox" editorOptions={deb_select_options}>
                        <Label text="Debitor" />
                    </Item>
                    <Item dataField="buero_id"  editorType="dxSelectBox" editorOptions={buero_select_options}>
                        <Label text="Büro" />
                    </Item>
                    <Item dataField="bereich"   editorType="dxSelectBox" editorOptions={bereich_select_options}>
                        <Label text="Bereich" />
                    </Item>
                    <Item dataField="fsz" colSpan={4} editorOptions={{ disabled:isAdmin?false:true}}>
                        <Label text="FSZ" />
                    </Item>
                    <Item dataField="callcenter"  colSpan={4} editorOptions={{ disabled:isAdmin?false:true}}>
                        <Label text="Callcenter" />
                    </Item>
                    <Item dataField="infos" colSpan={4} editorOptions={{}}>
                        <Label text="Infos" />
                    </Item>

                    <Item dataField="kontakt_tel1" editorOptions={{}}>
                        <Label text="Telefon1" />
                    </Item>
                    <Item dataField="kontakt_tel2" editorOptions={{}}>
                        <Label text="Telefon2" />
                    </Item>
                    <Item dataField="kontakt_ansprechpartner" editorOptions={{}}>
                        <Label text="Ansprechpartner" />
                    </Item>
                    <Item dataField="kontakt_mail" editorOptions={{}}>
                        <Label text="Mail" />
                    </Item>

                    <Item dataField="sperrkennzeichen"editorType="dxCheckBox" editorOptions={{ disabled:isAdmin?false:true}}>
                        <Label text="Sperrkennzeichen" />
                    </Item>
                    <SimpleItem itemType="button"
                        horizontalAlignment="left"
                        buttonOptions={saveButton}>
                    </SimpleItem>
                </Form>
            </div>

            <div className={'content-block dx-card responsive-paddings'}>
                <Vertrag_uebersicht kunde_id={formData.deb_id}></Vertrag_uebersicht>
            </div>
        </React.Fragment>
    );
};
