import { networkConfig } from "../networkConfig";

export async function get_Items(accessToken) {
  const headers = new Headers();
  const bearer = `Bearer ${accessToken}`;

  headers.append("Authorization", bearer);

  const options = {
      method: "GET",
      headers: headers
  };

  return fetch(networkConfig.serverEndpoint + "api/user/GetRolesByUser", options)
      .then(response => response.json())
      .catch(error => console.log(error));
}

