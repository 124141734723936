import React, { useState, useEffect, useRef } from 'react';
import { useHistory } from "react-router-dom";
import { getToken_api, getToken_user } from '../../api/auth';
import DataGrid, { Column, Grouping, FilterRow, Label, GroupItem, Editing, Lookup, Sorting, Summary, SortByGroupSummaryInfo } from 'devextreme-react/data-grid';
import { networkConfig } from '../../networkConfig';
import Form, { Item, SimpleItem } from 'devextreme-react/form';
import { useParams } from 'react-router-dom'


export default function () {
    
    let { filename } = useParams()
    let { typ } = useParams()
    const [file, setfile] = useState()
    const [token, setToken] = useState('')
    useEffect(() => {
        getToken_api().then(token => {
            setToken(token)
            setfile({ url: networkConfig.serverEndpoint + "api/moschein/"+typ+"?file=" + filename, httpHeaders: { 'Authorization': `Bearer ${token}` } })

         
        })


    }, []);



    return (

        <React.Fragment>



        </React.Fragment>
    );


};
