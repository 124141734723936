import React, { useState, useEffect } from 'react';
import { getToken_api } from '../../api/auth';

import ODataStore from 'devextreme/data/odata/store';
import DataSource from 'devextreme/data/data_source';
import { useParams } from 'react-router-dom'
import Guid from 'devextreme/core/guid';
import { networkConfig } from '../../networkConfig';
import DropDownButton from 'devextreme-react/drop-down-button';
import { useHistory } from "react-router-dom";
import Scheduler, { Resource, View } from 'devextreme-react/scheduler';
import Draggable from 'devextreme-react/draggable';
import ScrollView from 'devextreme-react/scroll-view';
import notify from 'devextreme/ui/notify';
import SelectBox from 'devextreme-react/select-box';
import DataGrid, { Column } from 'devextreme-react/data-grid';
import { Popup, Position, ToolbarItem } from 'devextreme-react/popup';
import { Tourauftrag_edit_comp } from '../../components';
import Form, { Item, TabbedItem, TabPanelOptions, Tab, Label, GroupItem } from 'devextreme-react/form';

export default function () {

    const [token, setToken] = useState('')

    const [monteure, setmonteure] = useState('')
    const [kalender, setkalender] = useState('')
    const [bueros, setbueros] = useState('')
    const [buero_id, setbuero_id] = useState('')
    const [popup_visible, setpopup_visible] = useState(false)
    const [tourauftrag_id, settourauftrag_id] = useState('')
    const [tourauftraege, setourauftraege] = useState([])
    const [aktuelle, setaktuelle] = useState(new Date())
    const history = useHistory();

    const currentDate = new Date();
    const views = [{ type: 'timelineDay', intervalCount: 1 }];
    const draggingGroupName = 'appointmentsGroup';

    useEffect(() => {
        getToken_api().then(token => {
            setToken(token)

            setbueros({
                store: {
                    type: 'odata',
                    url: networkConfig.dataEndpoint + 'bueros_aksn',
                    key: 'buero_id',
                    withCredentials: true,
                    beforeSend: function (e) {
                        e.headers = {
                            "Authorization": 'Bearer ' + token
                        };
                    }
                }
            })
           


        })
    }, []);

    useEffect(() => {
        getToken_api().then(token => {
            setToken(token)

            if (buero_id != '') {
                setmonteure({
                    store: {
                        type: 'odata',
                        url: networkConfig.dataEndpoint + 'dispo_monteur_view',
                        key: 'benutzer_id',
                        withCredentials: true,
                        beforeSend: function (e) {
                            e.headers = {
                                "Authorization": 'Bearer ' + token
                            };
                        }
                    }, filter: ["buero_id", "=", new Guid(buero_id)]
                })

                setkalender(new ODataStore(
                    {
                        type: 'odata',
                        url: networkConfig.dataEndpoint + 'tourauftrag_view',
                        key: 'tourauftrag_id',
                        withCredentials: true,
                        beforeSend: function (e) {
                            e.headers = {
                                "Authorization": 'Bearer ' + token
                            };
                        }

                    }))

                setourauftraege(new DataSource({
                    store: {
                        type: 'odata',
                        url: networkConfig.dataEndpoint + 'tourauftrag_view',
                        key: 'tourauftrag_id',
                        withCredentials: true,
                        beforeSend: function (e) {
                            e.headers = {
                                "Authorization": 'Bearer ' + token
                            };
                        }
                    }, filter: [["user_id", "=", null], ["active", "=", true], ["buero_id", "=", buero_id]]
                })
                )


            }


        })
    }, [buero_id], [aktuelle]);
    const [formData, setformData] = useState('')
    const [monteur_select_options, setmonteur_select_options] = useState('')
    const [status_select_options, setstatus_select_options] = useState('')

    const popup_oeffnen = function (tourauftrag_id) {
        getToken_api().then(token => {
            setToken(token)

            const dataSource = new ODataStore({
                type: 'odata',
                url: networkConfig.dataEndpoint + 'tourauftrag',
                key: 'tourauftrag_id',
                withCredentials: true,
                beforeSend: function (e) {
                    e.headers = {
                        "Authorization": 'Bearer ' + token
                    };
                }
            });
            dataSource.load({ filter: ['tourauftrag_id', '=', new Guid(tourauftrag_id)] })
                .then(
                    (data) => {
                        setformData(data[0]);
                        setpopup_visible(true)
                        const store3 = new DataSource({
                            store: new ODataStore({
                                type: 'odata',
                                url: networkConfig.dataEndpoint + 'dispo_monteur_view',
                                key: 'benutzerbuero_id',
                                withCredentials: true,
                                beforeSend: function (e) {
                                    e.headers = {
                                        "Authorization": 'Bearer ' + token
                                    };
                                }
                            }), filter: ["buero_id", "=", new Guid(data[0].buero_id)]
                        });




                        setmonteur_select_options({ dataSource: store3, displayExpr: "name", valueExpr: "benutzer_id", searchEnabled: true, showClearButton: true })

                    },
                    (error) => { /* Handle the "error" here */ }
                );





            const store2 = new ODataStore({
                type: 'odata',
                url: networkConfig.dataEndpoint + 'statecodes',
                key: 'deb_id',
                withCredentials: true,
                beforeSend: function (e) {
                    e.headers = {
                        "Authorization": 'Bearer ' + token
                    };
                },
            });

            setstatus_select_options({
                dataSource: { store: store2 }, displayExpr: "bezeichnung"
                , valueExpr: "statecode_id", searchEnabled: true
            })

        })


    }
    const Appointment = function (model) {

        function pad(num) {
            if (num.toString().length == 1) {
                return num.toString() + "0"
            } else {
                return num
            }
        };
        return (<div >
            <div>  {model.data.appointmentData.Name}  </div>
            <div className='dx-scheduler-appointment-content-details'>  {model.data.appointmentData.Typ}  </div>
            <div className='dx-scheduler-appointment-content-details'>  {model.data.appointmentData.erwBeginn.getHours()}:{pad(model.data.appointmentData.erwBeginn.getMinutes())} - {model.data.appointmentData.erwEnde.getHours()}:{pad(model.data.appointmentData.erwEnde.getMinutes())}  </div>
        </div>);
    }

    return (

        <React.Fragment>

            <div className="dx-toolbar-items-container" style={{ height: "60px" }}>
                <div className="dx-toolbar-before">
                    <h2 className={'content-block'}>Dispo-Tool</h2>
                </div>

            </div>
            <div className={'content-block dx-card responsive-paddings'}  >
                <SelectBox dataSource={bueros}
                    displayExpr="Name"
                    valueExpr="buero_id"
                    onValueChanged={function (e) { setbuero_id(e.value) }} />


                <DataGrid
                    dataSource={tourauftraege}
                    showBorders={true}
                    style={{ "position": "absolute", "left": "50" }}
                    width={350}
                    onRowClick={function (e) {
                        if (e.rowType === "data") {
                            popup_oeffnen(e.data.tourauftrag_id)

                        }
                    }}
                >

                    <Column
                        dataField="Typ"
                    />
                    <Column
                        dataField="title"
                    />
                    <Column
                        dataField="erwTag"
                    />
                    <Column
                        dataField="Name"
                    />

                </DataGrid>
                <Scheduler style={{ "marginLeft": "400px" }}
                    // id="scheduler"
                    dataSource={kalender}
                    views={views}
                    defaultCurrentView="timelineDay"
                    defaultCurrentDate={currentDate}
                    height={600}
                    startDayHour={6}
                    endDayHour={20}
                    editing={false}
                    startDateExpr="erwBeginn"
                    endDateExpr="erwEnde"
                    groups={['aksn']}
                    cellDuration={120}
                    crossScrollingEnabled={true}
                    appointmentComponent={Appointment}
                    showAllDayPanel={false}
                    onAppointmentClick={function (e) {
                        popup_oeffnen(e.appointmentData.tourauftrag_id)
                        

                    }}
                    //remoteFiltering={true}
                >
                    <Resource
                        fieldExpr="aksn"

                        allowMultiple={false}
                        dataSource={monteure}
                        displayExpr="name"
                    />
                </Scheduler>
            </div>

            <Popup
                visible={popup_visible}
                onHiding={function (e) { setpopup_visible(false) }}
                dragEnabled={false}
                closeOnOutsideClick={true}
                showCloseButton={true}
                showTitle={true}
                title="Vertrag"

                width={1200}
                height={800}
            >
                <Position
                    at="center"
                    my="center"

                />
                <ToolbarItem
                    widget="dxButton"
                    toolbar="bottom"
                    location="after"
                    options={{
                        text: 'Speichern',
                        disabled: false,
                        onClick: function (e) {
                            const dataSource = new ODataStore({
                                type: 'odata',
                                url: networkConfig.dataEndpoint + 'tourauftrag',
                                key: 'tourauftrag_id',
                                withCredentials: true,
                                beforeSend: function (e) {
                                    e.headers = {
                                        "Authorization": 'Bearer ' + token
                                    };
                                }
                            });
                            console.log(formData)
                            dataSource.update(formData.tourauftrag_id, formData)

                            notify({
                                message: 'Tourauftrag wurde erfolgreich gespeichert',

                            }, 'success', 5000);
                            setpopup_visible(false);

                            setourauftraege(new DataSource({
                                store: {
                                    type: 'odata',
                                    url: networkConfig.dataEndpoint + 'tourauftrag_view',
                                    key: 'tourauftrag_id',
                                    withCredentials: true,
                                    beforeSend: function (e) {
                                        e.headers = {
                                            "Authorization": 'Bearer ' + token
                                        };
                                    }
                                }, filter: [["user_id", "=", null], ["active", "=", true], ["buero_id", "=", buero_id]]
                            })
                            )

                        }
                    }}
                />
                <Form
                    id={'form'}
                    formData={formData}
                    labelLocation={'top'}
                    colCount={2}
                >
                    <GroupItem caption="Allgemein" colCount={3} colSpan={2}>
                        <Item dataField="tourauftrag_nr" colSpan={1} disabled={true} editorOptions={{}}>
                            <Label text="Tourauftrag" />
                        </Item>
                        <Item dataField="Auftragsnr" colSpan={1} disabled={true} editorOptions={{}}>
                            <Label text="Auftrags-Nr" />
                        </Item>
                        <Item dataField="auftrag_id" colSpan={1} visible={false} editorOptions={{}}>
                            <Label text="auftrag_id-Nr" />
                        </Item>
                        <Item dataField="buero_id" colSpan={1} visible={false} editorOptions={{}}>
                            <Label text="buero_id" />
                        </Item>
                        <Item dataField="Bemerkung" colSpan={1} editorOptions={{}}>
                            <Label text="Bemerkung" />
                        </Item>
                    </GroupItem>
                    <GroupItem caption="Tourauftrag" colCount={1} colSpan={1}>
                        <Item dataField="Name" colSpan={1} disabled={true} editorOptions={{}}>
                            <Label text="Name" />
                        </Item>
                        <Item dataField="Name2" colSpan={1} disabled={true} editorOptions={{}}>
                            <Label text="Name2" />
                        </Item>
                        <Item dataField="Name3" colSpan={1} disabled={true} editorOptions={{}}>
                            <Label text="Name3" />
                        </Item>
                        <Item dataField="Strasse1" colSpan={1} disabled={true} editorOptions={{}}>
                            <Label text="Straße" />
                        </Item>
                        <Item dataField="plz" colSpan={1} disabled={true} editorOptions={{}}>
                            <Label text="PLZ" />
                        </Item>
                        <Item dataField="Stadt" colSpan={1} disabled={true} editorOptions={{}}>
                            <Label text="Ort" />
                        </Item>
                    </GroupItem>
                    <GroupItem caption="Tourauftrag" colCount={1} colSpan={1}>
                        <Item dataField="erwDauer" editorType="dxNumberBox" colSpan={1} editorOptions={{}}>
                            <Label text="erw. Dauer" />
                        </Item>
                        <Item dataField="erwTag" editorType="dxDateBox" colSpan={1} editorOptions={{}}>
                            <Label text="erw. Einsatztag" />
                        </Item>
                        <Item dataField="user_id" editorType="dxSelectBox" colSpan={1} editorOptions={monteur_select_options}>
                            <Label text="Monteur" />
                        </Item>
                        <Item dataField="erwBeginn" colSpan={1} editorType="dxDateBox" editorOptions={{ type: "datetime" }}>
                            <Label text="erw. Beginn" />
                        </Item>
                        <Item dataField="erwEnde" colSpan={1} editorType="dxDateBox" editorOptions={{ type: "datetime" }}>
                            <Label text="erw. Ende" />
                        </Item>
                    </GroupItem>

                    <GroupItem caption="Einsatz" colCount={1} colSpan={2}>
                        <Item dataField="statecode" editorType="dxSelectBox" colSpan={1} editorOptions={status_select_options}>
                            <Label text="Status" />
                        </Item>
                        <Item dataField="active" editorType="dxCheckBox" colSpan={1} editorOptions={{}}>
                            <Label text="Aktiv" />
                        </Item>
                        <Item dataField="Abfahrt" editorType="dxDateBox" disabled={true} colSpan={1} editorOptions={{ type: "datetime" }}>
                            <Label text="Abfahrt" />
                        </Item>
                        <Item dataField="Ankunft" editorType="dxDateBox" disabled={true} colSpan={1} editorOptions={{ type: "datetime" }}>
                            <Label text="Ankunft" />
                        </Item>
                        <Item dataField="moschein_nr" disabled={true} colSpan={1} editorOptions={{}}>
                            <Label text="MoSchein" />
                        </Item>
                    </GroupItem>
                </Form>

            </Popup>
        </React.Fragment>
    );
};
