import React, { useState, useEffect } from 'react';
import { getToken_api } from '../../api/auth';

import ODataStore from 'devextreme/data/odata/store';
import DataSource from 'devextreme/data/data_source';

import Guid from 'devextreme/core/guid';
import { networkConfig } from '../../networkConfig';
import Scheduler, { Resource, View } from 'devextreme-react/scheduler';
import notify from 'devextreme/ui/notify';
import SelectBox from 'devextreme-react/select-box';
import { Popup, Position, ToolbarItem } from 'devextreme-react/popup';
import { Button } from 'devextreme-react/button';
import Form, { Item, TabbedItem, TabPanelOptions, Tab, Label, GroupItem } from 'devextreme-react/form';

export default function () {
    const [token, setToken] = useState()
    const [formData, setformData] = useState()
    const [bueros, setbueros] = useState('')
    const [buero_auswahl, setbuero_auswahl] = useState()
    const [mitarbeiter, setmitarbeiter] = useState()
    const [kalender, setkalender] = useState('')
    const [buero_id, setbuero_id] = useState()
    const [editable, seteditable] = useState(false)
    const [popup_visible, setpopup_visible] = useState(false)
    const [monteur_select_options, setmonteur_select_options] = useState('')

    const Appointment = function (model) {
        var data = model.data.appointmentData;
        var bemerk = ""
        if (data.bemerkung != null) {
            bemerk = data.bemerkung
        };
        return (<div style={{ "height": "50px" }}>
            <div>  {data.name}({data.title})</div>
            <div className='dx-scheduler-appointment-content-details'> {data.telefon} </div>
            <div className='dx-scheduler-appointment-content-details'> {bemerk} </div>
        </div>);

    }
    const AppointmentTooltip = function (model) {
        var data = model.data.appointmentData;
        if (editable == true) {

            var bemerk = ""
            if (data.bemerkung != null) {
                bemerk = data.bemerkung
            };
            var markup = (<div>
                <div>{data.name} ({data.title})</div>
                <div>{data.telefon}  </div>
                <div>{bemerk}</div>
             
            </div>)



            return markup;
        } else {

            var bemerk = ""
            if (data.bemerkung != null) {
                bemerk = data.bemerkung
            };
            var markup = (<div className='tooltip'>
                <div>{data.name} ({data.title})</div>
                <div>{data.telefon}  </div>
                <div>{bemerk}</div>

            </div>)
            return markup;
        }

    }
    const copy = function () {

        const headers = new Headers();
        const bearer = `Bearer ${token}`;
        headers.append("Authorization", bearer);
        headers.append("Content-Type", "application/json;charset=UTF-8")
        const options = {
            method: "POST",
            headers: headers,
            body: JSON.stringify(formData)
        };

        return fetch(networkConfig.serverEndpoint + "api/bereitschaft/CopyBereitschaft", options)
            .then(response => response.json())
            .then(data => {
                if (data.success == true) {

                    setformData(data.result_set)
                    notify({
                        message: 'Bereitschaft wurde erfolgreich kopiert',

                    }, 'success', 5000);
                } else {
                    notify({
                        message: 'Fehler :(',

                    }, 'error', 5000);
                }

            }
            )
            .catch(error => console.log(error));
    }
    const speichern = function () {
        const headers = new Headers();
        const bearer = `Bearer ${token}`;
        headers.append("Authorization", bearer);
        headers.append("Content-Type", "application/json;charset=UTF-8")
        const options = {
            method: "POST",
            headers: headers,
            body: JSON.stringify(formData)
        };

        return fetch(networkConfig.serverEndpoint + "api/bereitschaft/SetBereitschaft", options)
            .then(response => response.json())
            .then(data => {
                if (data.success == true) {
                    setpopup_visible(false);
                    setformData({})
                    notify({
                        message: 'Bereitschaft wurde erfolgreich gespeichert',

                    }, 'success', 5000);
                } else {
                    notify({
                        message: 'Fehler :(',

                    }, 'error', 5000);
                }

            }
            )
            .catch(error => console.log(error));
    };
    const popup_oeffnen = function (buero_id, tag) {
        getToken_api().then(token => {
            setToken(token)

            const headers = new Headers();
            const bearer = `Bearer ${token}`;
            headers.append("Authorization", bearer);
            headers.append("Content-Type", "application/json;charset=UTF-8")
            var daten = { "buero_nr": buero_id, "tag": tag }
            const options = {
                method: "POST",
                headers: headers,
                body: JSON.stringify(daten)
            };

            return fetch(networkConfig.serverEndpoint + "api/bereitschaft/GetBereitschaft", options)
                .then(response => response.json())
                .then(data => {
                    if (data.success == true) {
                        console.log(data)
                       
                        const store3 = new DataSource({
                            store: new ODataStore({
                                type: 'odata',
                                url: networkConfig.dataEndpoint + 'dispo_monteur_view',
                                key: 'benutzerbuero_id',
                                withCredentials: true,
                                beforeSend: function (e) {
                                    e.headers = {
                                        "Authorization": 'Bearer ' + token
                                    };
                                }
                            }), filter: ["buero_id", "=", new Guid(data.result_set.buero_id)]
                        });
                        if (data.result_set.user1 != undefined){data.result_set.user1 = new Guid(data.result_set.user1)}
                        if (data.result_set.user2 != undefined){data.result_set.user2 = new Guid(data.result_set.user2)}
                        if (data.result_set.user3 != undefined){data.result_set.user3 = new Guid(data.result_set.user3)}
                        if (data.result_set.user4 != undefined){data.result_set.user4 = new Guid(data.result_set.user4)}
                        if (data.result_set.user5 != undefined){data.result_set.user5 = new Guid(data.result_set.user5)}
                        setformData(data.result_set);
                        setmonteur_select_options({ dataSource: store3, displayExpr: function(item) {return item && item.name + ' (' + item.title + ')'}, valueExpr: "benutzer_id", searchEnabled: true, showClearButton: true })
                        setpopup_visible(true);
                    } else {
                        notify({
                            message: 'Fehler :(',

                        }, 'error', 5000);
                    }

                }
                )
                .catch(error => console.log(error));


        })
    }
    useEffect(() => {
        getToken_api().then(token => {
            setToken(token)

            var roles = localStorage.getItem("roles")
            if (roles.indexOf('bereitschaft_bearbeiten') != -1){
                seteditable(true)
            }

            setkalender({
                store: {
                    type: 'odata',
                    url: networkConfig.dataEndpoint + 'bereitschaftstool_view',
                    key: 'bereitschaft_id',
                    withCredentials: true,
                    beforeSend: function (e) {
                        e.headers = {
                            "Authorization": 'Bearer ' + token
                        };
                    }
                }, sort: ["position"]
            })
            setbuero_auswahl(new DataSource({
                store: {
                    type: 'odata',
                    url: networkConfig.dataEndpoint + 'bueros_aksn',
                    key: 'buero_id',
                    withCredentials: true,
                    beforeSend: function (e) {
                        e.headers = {
                            "Authorization": 'Bearer ' + token
                        };
                    }
                }, sort: ["Name"]
            })
            )
            setbueros(new DataSource({
                store: {
                    type: 'odata',
                    url: networkConfig.dataEndpoint + 'bueros_aksn',
                    key: 'tourauftrag_id',
                    withCredentials: true,
                    beforeSend: function (e) {
                        e.headers = {
                            "Authorization": 'Bearer ' + token
                        };
                    }
                }
                , sort: ["Name"]
            })
            )


        })
    }, []);
    useEffect(() => {
      
            if (buero_id != null) {
                setbueros(new DataSource({
                    store: {
                        type: 'odata',
                        url: networkConfig.dataEndpoint + 'bueros_aksn',
                        key: 'tourauftrag_id',
                        withCredentials: true,
                        beforeSend: function (e) {
                            e.headers = {
                                "Authorization": 'Bearer ' + token
                            };
                        }
                    }, filter: ["buero_id", "=", buero_id]
                    , sort: ["Name"]
                })
                )
            }

        
    }, [buero_id]);
    var scheduler = {};

    return (

        <React.Fragment>

            <div className="dx-toolbar-items-container" style={{ height: "60px" }}>
                <div className="dx-toolbar-before">
                    <h2 className={'content-block'}>Bereitschaftskalender</h2>
                </div>

            </div>
            <div className={'content-block dx-card responsive-paddings'}  >
                <SelectBox dataSource={buero_auswahl}
                    displayExpr="Name"
                    valueExpr="buero_id"
                    placeholder="Büro filtern"
                    onValueChanged={function (e) { setbuero_id(e.value) }} />


                <Scheduler
                    // id="scheduler"
                    timeZone="Europe/Berlin"
                    dataSource={kalender}
                    views={["timelineWeek"]}
                    defaultCurrentView="timelineWeek"
                    defaultCurrentDate={new Date()}
                    height={"100%"}
                    recurrenceRuleExpr=""
                    editing={editable}
                    startDateExpr="startdatetime"
                    endDateExpr="enddatetime"
                    groups={['buero_nr']}
                    cellDuration={1440}
                    crossScrollingEnabled={true}
                    appointmentComponent={Appointment}
                    appointmentTooltipComponent={AppointmentTooltip}
                    showAllDayPanel={false}
                    remoteFiltering={true}
                    className={"bereitschaftstool"}
                    onAppointmentFormOpening={function (e) {
                        if (editable == true) {
                            popup_oeffnen(e.appointmentData.buero_nr, e.appointmentData.startdatetime)
                        }
                        e.cancel = true;
                    }}
                    ref={(ref) => { scheduler = ref }}
                >
                    <Resource
                        dataSource={bueros}
                        fieldExpr="buero_nr"
                        displayExpr="Name"
                        label="Name"

                    />

                </Scheduler>
            </div>

            <Popup
                visible={popup_visible}
                onHiding={function (e) { setpopup_visible(false) }}
                dragEnabled={false}
                closeOnOutsideClick={true}
                showCloseButton={true}
                showTitle={true}
                title="Bereitschaft"

                width={1200}
                height={800}
            >
                <Position
                    at="center"
                    my="center"

                />
                <ToolbarItem
                    widget="dxButton"
                    toolbar="bottom"
                    location="after"
                    options={{
                        text: 'auf nächsten Tag kopieren',
                        disabled: false,
                        onClick: copy
                    }}
                />
                <ToolbarItem
                    widget="dxButton"
                    toolbar="bottom"
                    location="after"
                    options={{
                        text: 'Speichern',
                        disabled: false,
                        onClick: speichern
                    }}
                />
                <Form
                    id={'form'}
                    formData={formData}
                    labelLocation={'top'}
                    colCount={2}
                >
                     <Item dataField="tag" editorType="dxDateBox" colSpan={1}>
                        <Label text="Tag" />
                    </Item>
                    <Item dataField="buero_nr" editorType="dxSelectBox" colSpan={1} editorOptions={{dataSource:buero_auswahl,
                    displayExpr:"Name",
                    valueExpr:"id",
                    placeholder:"Büro filtern",
                   }}>
                        <Label text="Büro" />
                    </Item>

                    <Item dataField="user1" editorType="dxSelectBox" colSpan={1} editorOptions={monteur_select_options}>
                        <Label text="Eintrag 1" />
                    </Item>
                    <Item dataField="bemerkung1" >
                        <Label text="Bemerkung" />
                    </Item>
                    <Item dataField="user2" editorType="dxSelectBox" colSpan={1} editorOptions={monteur_select_options}>
                        <Label text="Eintrag 2" />
                    </Item>
                    <Item dataField="bemerkung2" >
                        <Label text="Bemerkung" />
                    </Item>
                    <Item dataField="user3" editorType="dxSelectBox" colSpan={1} editorOptions={monteur_select_options}>
                        <Label text="Eintrag 3" />
                    </Item>
                    <Item dataField="bemerkung3" >
                        <Label text="Bemerkung" />
                    </Item>
                    <Item dataField="user4" editorType="dxSelectBox" colSpan={1} editorOptions={monteur_select_options}>
                        <Label text="Eintrag 4" />
                    </Item>
                    <Item dataField="bemerkung4" >
                        <Label text="Bemerkung" />
                    </Item>
                    <Item dataField="user5" editorType="dxSelectBox" colSpan={1} editorOptions={monteur_select_options}>
                        <Label text="Eintrag 5" />
                    </Item>
                    <Item dataField="bemerkung5" >
                        <Label text="Bemerkung" />
                    </Item>
                </Form>

            </Popup>



        </React.Fragment>
    );
};
