import React, { useState,useEffect } from 'react';

import './profile.scss';
import Form from 'devextreme-react/form';
import { callMsGraph } from '../../api/graph';
import { getToken_user } from '../../api/auth';

export default function ()  {
  const [notes, setNotes] = useState(
    'Sandra is a CPA and has been our controller since 2008. She loves to interact with staff so if you`ve not met her, be certain to say hi.\r\n\r\nSandra has 2 daughters both of whom are accomplished gymnasts.'
  );
  

  const [data, setData] = useState('')
  const [show_profilheader, setshow_profilheader] = useState(false)
useEffect(() => {

  getToken_user().then( token => {
    console.log(token)
   callMsGraph(token).then(value => {
     console.log(JSON.stringify(value))
    setData(value)

    })
  })
},[]);

  return (
  
    <React.Fragment>
      <h2 className={'content-block'}>Profile</h2>

  {show_profilheader &&  <div  className={'content-block dx-card responsive-paddings'}>
       <div className={'form-avatar'}>
          <img
            alt={''}
            
          />
        </div>
        <span>{notes}</span>
      </div>
    }
      <div className={'content-block dx-card responsive-paddings'}>
        <Form
          id={'form'}
          formData={data}
          labelLocation={'top'}
          colCountByScreen={colCountByScreen}
        />
      </div>
    </React.Fragment>
  );
};

const colCountByScreen = {
  xs: 1,
  sm: 2,
  md: 3,
  lg: 4
};
