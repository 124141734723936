import 'devextreme/dist/css/dx.common.css';
import './themes/generated/theme.base.css';
import './themes/generated/theme.additional.css';
//import './themes/import/dx.material.dka.css';
//import './themes/import/dx.generic.dka.css';
import React, { useState, useEffect } from 'react';
import { HashRouter as Router } from 'react-router-dom';
import './dx-styles.scss';
import { NavigationProvider } from './contexts/navigation';
import { AuthProvider, useAuth } from './contexts/auth';
import { useScreenSizeClass } from './utils/media-query';
import LoadPanel from 'devextreme-react/load-panel';
import Content from './Content';
import UnauthenticatedContent from './UnauthenticatedContent';
import deMessages from "devextreme/localization/messages/de.json";
import { locale, loadMessages } from "devextreme/localization";


function App() {
  const { user, loading } = useAuth();
  const [username, setUsername] = useState();


  if (loading) {
    return <LoadPanel visible={true} />;
  }

  if (user) {
    return <Content />;
  }

  return <UnauthenticatedContent />;
}


export default function () {
  const screenSizeClass = useScreenSizeClass();
  loadMessages(deMessages);
  locale(navigator.language);
  return (
    <Router>
      <AuthProvider>
        <NavigationProvider>
          <div className={`app ${screenSizeClass}`}>
            <App />
          </div>
        </NavigationProvider>
      </AuthProvider>
    </Router>
  );
}
