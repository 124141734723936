import React, { useState, useEffect } from 'react';
import { useHistory } from "react-router-dom";
import { getToken_api, getToken_user } from '../../api/auth';
import DataGrid, { Column, Button, Grouping, FilterRow, SearchPanel, HeaderFilter, Editing, Form, Lookup, Sorting } from 'devextreme-react/data-grid';
import { networkConfig } from '../../networkConfig';
import { Item, SimpleItem } from 'devextreme-react/form';
export default function () {

    const [token, setToken] = useState('')
    const [dataSource, setdataSource] = useState('')

    useEffect(() => {

        getToken_api().then(token => {
            setdataSource({
                store: {
                    type: 'odata',
                    url: networkConfig.dataEndpoint + 'svs',
                    key: 'svs_id',
                    withCredentials: true,
                    beforeSend: function (e) {
                        e.headers = {
                            "Authorization": 'Bearer ' + token
                        };
                    },

                },
            })


        })

    }, []);



    return (

        <React.Fragment>
            <h2 className={'content-block'}>Stundenverechnungssätze</h2>
            <DataGrid

                dataSource={dataSource}
                showBorders={true}
            >
                <Grouping autoExpandAll={false} />
                <Sorting mode="multiple" />
                <Editing
                    mode="row"
                    allowUpdating={true}
                    allowDeleting={true}
                    allowAdding={true}
                >

                </Editing>
                <FilterRow visible={true}
                    applyFilter="auto" />
                <HeaderFilter visible={false} />
                <SearchPanel visible={true}
                    width={240}
                    placeholder="Suchen..." />
                <Column
                    dataField="svs_ost"
                    dataType="number"
                    caption="SVS-Ost"

                />
                <Column
                    dataField="svs_west"
                    caption="SVS-West"
                    dataType="number"
                />
                <Column
                    dataField="km_ost"
                    caption="KM-Ost"
                    dataType="number"
                />
                <Column
                    dataField="km_west"
                    caption="KM-West"
                    dataType="number"
                />
                <Column
                    dataField="gueltig_ab"
                    caption="Gültig ab"
                    dataType="datetime"
                />
                <Column
                    dataField="gueltig_bis"
                    caption="Gültig bis"
                    dataType="datetime"
                />





            </DataGrid>
        </React.Fragment>
    );


};
