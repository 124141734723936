import React, { useState, useEffect } from 'react';
import { getToken_api } from '../../../api/auth';
import Form, { Item, SimpleItem, Label } from 'devextreme-react/form';
import ODataStore from 'devextreme/data/odata/store';
import DataGrid, { Column, Editing, Lookup } from 'devextreme-react/data-grid';
import { useParams } from 'react-router-dom'
import Guid from 'devextreme/core/guid';
import ResponsiveBox, {
    Row,
    Col,
    Item as Resitem,
    Location
} from 'devextreme-react/responsive-box';
import { networkConfig } from '../../../networkConfig';
export default function () {

    const [token, setToken] = useState('')
    const [dataSource_Bueros, setdataSource_Bueros] = useState('')
    const [formData, setformData] = useState('')
    const [buero_select_options, setbuero_select_options] = useState('')

    let { buero_id } = useParams()
    const saveButton = {
        icon: 'save',
        text: 'Speichern',
        onClick: () => {

            const dataSource = new ODataStore({
                type: 'odata',
                url: networkConfig.dataEndpoint + 'bueros',
                key: 'buero_id',
                withCredentials: true,
                beforeSend: function (e) {
                    e.headers = {
                        "Authorization": 'Bearer ' + token
                    };
                }
            });
            dataSource.update(new Guid(buero_id), formData)

        }
    };
    function buero_aendern(e) {
        console.log(e)
    };
    useEffect(() => {
        getToken_api().then(token => {
            setToken(token)
            setdataSource_Bueros({
                store: {
                    type: 'odata',
                    url: networkConfig.dataEndpoint + 'bueros',
                    key: 'buero_id',
                    withCredentials: true,
                    beforeSend: function (e) {
                        e.headers = {
                            "Authorization": 'Bearer ' + token
                        };
                    },
                },
            });

            const dataSource = new ODataStore({
                type: 'odata',
                url: networkConfig.dataEndpoint + 'bueros',
                key: 'buero_id',
                withCredentials: true,
                beforeSend: function (e) {

                    e.headers = {
                        "Authorization": 'Bearer ' + token
                    };
                }
            });
            dataSource.load({ filter: ['buero_id', '=', new Guid(buero_id)] })
                .then(
                    (data) => { setformData(data[0]); },
                    (error) => { /* Handle the "error" here */ }
                );

            const store = new ODataStore({
                type: 'odata',
                url: networkConfig.dataEndpoint + 'bueros',
                key: 'buero_id',
                withCredentials: true,
                beforeSend: function (e) {
                    e.headers = {
                        "Authorization": 'Bearer ' + token
                    };
                },
            });

            setbuero_select_options({ dataSource: { store }, displayExpr: "Name", valueExpr: "buero_id", searchEnabled: true})
        })
    }, []);


    return (

        <React.Fragment>
            <h2 className={'content-block'}>Standort bearbeiten</h2>

            <div className={'content-block dx-card responsive-paddings'}>
                <Form
                    id={'form'}
                    formData={formData}
                    labelLocation={'top'}
                    colCount={4}
                >
                    <Item dataField="Name" editorOptions={{}}>
                        <Label text="Name" />
                    </Item>
                    <Item dataField="EMailAddress" editorOptions={{}}>
                        <Label text="EMail-Adresse" />
                    </Item>
                    <Item dataField="Telefon1" editorOptions={{}}>
                        <Label text="Telefon" />
                    </Item>
                    <Item dataField="Strasse_Hausnummer" editorOptions={{}}>
                        <Label text="Str./HausNr" />
                    </Item>
                    <Item dataField="Postleitzahl" editorOptions={{}}>
                        <Label text="PLZ" />
                    </Item>
                    <Item dataField="Ort" editorOptions={{}}>
                        <Label text="Ort" />
                    </Item>
                    <Item dataField="Kostenstelle" editorOptions={{}}>
                        <Label text="Kostenstelle" />
                    </Item>
                    <Item dataField="buero_nr" editorOptions={{}}>
                        <Label text="Büro-Nr" />
                    </Item>

                    <Item dataField="parent_id" editorType="dxSelectBox"  editorOptions={buero_select_options}>
                        <Label text="Niederlassung" />
                    </Item>
                    <Item dataField="bereitschaftstool" editorType="dxCheckBox" editorOptions={{}}>
                        <Label text="Anzeige im Bereitschaftstool" />
                    </Item>
                    <Item dataField="IsDisabled" editorType="dxCheckBox" editorOptions={{}}>
                        <Label text="deaktiviert" />
                    </Item>
                    <SimpleItem itemType="button"
                        horizontalAlignment="left"
                        buttonOptions={saveButton}>
                    </SimpleItem>
                </Form>
            </div>



        </React.Fragment>
    );
};
