import { graphConfig } from "../authConfig";

/**
 * Attaches a given access token to a Microsoft Graph API call. Returns information about the user
 */
 export async function callMsGraph(accessToken) {
    const headers = new Headers();
    const bearer = `Bearer ${accessToken}`;

    headers.append("Authorization", bearer);

    const options = {
        method: "GET",
        headers: headers
    };

    return fetch(graphConfig.graphMeEndpoint, options)
        .then(response => response.json())
        .catch(error => console.log(error));
}

export async function callMsGraph_Users(accessToken,nachname) {
    const headers = new Headers();
    const bearer = `Bearer ${accessToken}`;

    headers.append("Authorization", bearer);

    const options = {
        method: "GET",
        headers: headers
    };

    return fetch(graphConfig.graphUsersEndpoint +"?$filter=startsWith(displayName,'" + nachname + "')", options)
        .then(response => response.json())
        .catch(error => console.log(error));
}

