import React, { useState, useEffect } from 'react';
import { useHistory } from "react-router-dom";
import { getToken_api } from '../../../api/auth';
import DataGrid, { Column, Button, Grouping, FilterRow, SearchPanel, HeaderFilter, Editing, Form, Lookup, Sorting } from 'devextreme-react/data-grid';
import { networkConfig } from '../../../networkConfig';
import { Item, SimpleItem } from 'devextreme-react/form';
export default function () {

  const [token, setToken] = useState('')
  const [dataSource, setdataSource] = useState('')
  const [dataSource_Buero, setdataSource_Buero] = useState('')
  const history = useHistory();

  useEffect(() => {

    getToken_api().then(token => {
      setdataSource({
        store: {
          type: 'odata',
          url: networkConfig.dataEndpoint + 'debitor',
          key: 'deb_id',
          withCredentials: true,
          beforeSend: function (e) {
            e.headers = {
              "Authorization": 'Bearer ' + token
            };
          },
          onInserted: function(values, key){
            history.push("/settings/debitoren/bearbeiten/" + values.deb_id);
          }

        },
      })
      setdataSource_Buero({
        store: {
          type: 'odata',
          url: networkConfig.dataEndpoint + 'bueros',
          key: 'buero_id',
          withCredentials: true,
          beforeSend: function (e) {
            e.headers = {
              "Authorization": 'Bearer ' + token
            };
          }
        },
      })

    })

  }, []);

  const debitor_oeffnen = function (e) {
    history.push("/settings/debitoren/bearbeiten/" + e.row.data.deb_id);
  };
       

  return (

    <React.Fragment>
      <h2 className={'content-block'}>Debitoren</h2>
      <DataGrid

        dataSource={dataSource}
        showBorders={true}
        onInitNewRow={function(info){
          info.data.sperrkennzeichen = false;
        }

        }
      >
        <Grouping autoExpandAll={false} />
        <Sorting mode="multiple" />
        <Editing
        mode="form"
        allowUpdating={false}
        allowDeleting={false}
        allowAdding={true}
      >
          <Form title="Debitor" showTitle={true}>

            <Item dataField="name"/>
            
          </Form>
        </Editing>
        <FilterRow visible={true}
          applyFilter="auto" />
        <HeaderFilter visible={false} />
        <SearchPanel visible={true}
          width={240}
          placeholder="Suchen..." />
        <Column
          dataField="nr"
          caption="Debitor-Nr"
          defaultSortOrder="asc"
        />
        <Column
          dataField="name"
          caption="Name"
        />
        <Column
          dataField="ort"
          caption="Ort"
        />
        <Column
          dataField="buero_id"
          caption="Büro"
        >
   <Lookup dataSource={dataSource_Buero} displayExpr="Name" valueExpr="buero_id" />
        </Column>
        <Column
          dataField="sperrkennzeichen"
          dataType="boolean"
          caption="gesperrt"
        />
<Column type="buttons">
          <Button
            text="bearbeiten"
            onClick={debitor_oeffnen}
          />
        </Column>




      </DataGrid>
    </React.Fragment>
  );


};
