import React, { useState, useEffect, useRef } from 'react';
import { getToken_api } from '../../api/auth';
import Form, { Item, ButtonItem, Label, RequiredRule, GroupItem, TabbedItem, TabPanelOptions, Tab } from 'devextreme-react/form';
import SelectBox from 'devextreme-react/select-box';
import ODataStore from 'devextreme/data/odata/store';
import 'devextreme-react/text-area';
import { TextBox, Button as TextBoxButton } from 'devextreme-react/text-box';
import { Button as Button_normal } from 'devextreme-react/button';
import DataGrid, { Column, Grouping, Sorting, Summary, TotalItem, Editing, Lookup, Button, Form as GridForm } from 'devextreme-react/data-grid';
import { Popup, Position, ToolbarItem } from 'devextreme-react/popup';
import { useParams } from 'react-router-dom'
import Guid from 'devextreme/core/guid';
import notify from 'devextreme/ui/notify';
import { networkConfig } from '../../networkConfig';
import { useHistory } from "react-router-dom";
import DateBox from 'devextreme-react/date-box';
import { NumberBox } from 'devextreme-react/number-box';
import { wegzeiten } from '../../api/wegzeit';
import SignatureCanvas from 'react-signature-canvas';
import ActionSheet from 'devextreme-react/action-sheet';
import DropDownButton from 'devextreme-react/drop-down-button';
import { confirm } from 'devextreme/ui/dialog';
import { alert } from "devextreme/ui/dialog"
import { usePhotoGallery, useCamera } from "../../api/camera";
import axios from 'axios';
import { isMobile } from "react-device-detect";
import { Browser } from '@capacitor/browser';
import { DropDownBox } from 'devextreme-react';
import DataSource from 'devextreme/data/data_source';
export default function () {
    let { moschein_id } = useParams()
    const [token, setToken] = useState('')
    const [formData, setformData] = useState({})
    const [benutzer_select_options, setbenutzer_select_options] = useState({})
    const [entfernungszone_select_options, setentfernungszone_select_options] = useState('')
    const [dataSource_molohn, setdataSource_molohn] = useState([])
    const [dataSource_momat, setdataSource_momat] = useState([])
    const [dataSource_momail, setdataSource_momail] = useState([])
    const [dataSource_dhp, setdataSource_dhp] = useState([])
    const [dataSource_mobasfkaelte, setdataSource_mobasfkaelte] = useState([])
    const [dataSource_mobasfschadenverdichter, setdataSource_mobasfschadenverdichter] = useState([])
    const [dataSource_mobilder, setdataSource_mobilder] = useState([])
    const [dataSource_moprotokollbilder, setdataSource_moprotokollbilder] = useState([])
    const [dataSource_mowartung, setdataSource_mowartung] = useState([])
    const [dataSource_wartung_vdma, setdataSource_wartung_vdma] = useState([])
    const [dataSource_gaswarn, setdataSource_gaswarn] = useState([])
    const [dataSource_splitklima, setdataSource_splitklima] = useState([])
    const [dataSource_arbeitsanweisungen, setdataSource_arbeitsanweisungen] = useState([])
    const [dataSource_perslager, setdataSource_perslager] = useState([])
    const [bezeichnung_mat, setbezeichnung_mat] = useState('')
    const [dataSource_moschein_historie, setdataSource_moschein_historie] = useState([])
    const [endeMin, setendeMin] = useState()
    const [begin_value, setbegin_value] = useState()
    const [ende_value, setende_value] = useState()
    const [formData_Mat, setformData_Mat] = useState({})
    const [arbeitsstunden_value, setarbeitsstunden_value] = useState(0)
    const [mat_disabled, setmat_disabled] = useState(false)
    const [datebox_options, setdatebox_options] = useState({})
    const [artikelnummer, setartikelnummer] = useState('')
    const [wartung, setWartung] = useState(false)
    const [wartung_VDMA, setWartung_VDMA] = useState(false)
    const [basf, setbasf] = useState(false)
    const [stoercode_pflicht, setstoercode_pflicht] = useState(false)
    const [stoercode_liste, setstoercode_liste] = useState([])
    const [stoercode_options, setstoercode_options] = useState({})
    const [mailadressen, setmailadressen] = useState([])
    const [arbeitsanweisungen, setarbeitsanweisungen] = useState([])
    const [last_az, setlast_az] = useState()
    const [freigegeben, setfreigegeben] = useState(false)
    const [abschluss, setabschluss] = useState(false)
    const [popup_moschein_historie, setpopup_moschein_historie] = useState(false)
    const [popup_unterschrift_monteur_visible, setpopup_unterschrift_monteur_visible] = useState(false)
    const [popup_unterschrift_kunde_visible, setpopup_unterschrift_kunde_visible] = useState(false)
    const [unterschrift_monteur_typ, setunterschrift_monteur_typ] = useState("normal")
    const [unterschrift_kunde_typ, setunterschrift_kunde_typ] = useState("normal")
    const [speichern_text, setspeichern_text] = useState("Speichern")
    const [kunde_text, setkunde_text] = useState("")
    const [status_visible, setstatus_visible] = useState(false)
    const [status_items, setstatus_items] = useState([])
    const [dataSource_material_namen, setdataSource_material_namen] = useState([
        {
            "artikelnummer": "0068100593",
            "preis": 791,
            "bezeichnung": "Bördelgerät S-MM kompl. im Holzkoffer",
            "einheit": "Stck"
        },
        {
            "artikelnummer": "0112361770",
            "preis": 515,
            "bezeichnung": "Küba -Luftkühler SPB 23-F21 4462.23-21",
            "einheit": "Stck"
        },
        {
            "artikelnummer": "0130000699",
            "preis": 0,
            "bezeichnung": "kalkulat.Gewährleistungsrückstellung",
            "einheit": "EURO"
        },
        {
            "artikelnummer": "0130001998",
            "preis": 0,
            "bezeichnung": "Material Elektro",
            "einheit": "Stck"
        }])

    const history = useHistory();

    const kundendatenabrufen = function (e) {

        const headers = new Headers();
        const bearer = `Bearer ${token}`;
        headers.append("Authorization", bearer);
        headers.append("Content-Type", "application/json;charset=UTF-8")

        const options = {
            method: "GET",
            headers: headers,

        };
        return fetch(networkConfig.serverEndpoint + "api/moschein/GetKunde?auftrag_id=" + formData.auftrag_id, options)
            .then(response => response.json())
            .then(data => {
                if (data.success == true) {
                    setformData({ ...formData, kundennummer: data.result_set.filiale_nr, kunden_name: data.result_set.name, kunden_strasse: data.result_set.strasse, kunden_plz: data.result_set.plz, kunden_ort: data.result_set.ort })
                } else {
                    notify({
                        message: 'Fehler :(',
                    }, 'error', 5000);
                }
            }
            )
            .catch(error => console.log(error));
    };
    useEffect(() => {
        if (unterschrift_monteur_typ == "success" && unterschrift_kunde_typ == "success") {
            setspeichern_text("Versenden")
        } else {
            setspeichern_text("Speichern")
        }
    }, [unterschrift_monteur_typ][unterschrift_kunde_typ]);
    useEffect(() => {

        var data = JSON.parse(localStorage.getItem('moschein:' + moschein_id));
        console.log(data)
        setdataSource_molohn(data.molohn);
        setdataSource_momat(data.momat);
        setdataSource_momail(data.momail);
        setdataSource_dhp(data.dhp);
        setdataSource_mobasfkaelte(data.mobasfkaelte);
        setdataSource_mobasfschadenverdichter(data.mobasfschadenverdichter);
        setdataSource_mobilder(data.mobilder);
        setdataSource_moprotokollbilder(data.moprotokollbilder);
        setdataSource_mowartung(data.mowartung);
        setdataSource_wartung_vdma(data.wartung_vdma);
        setdataSource_gaswarn(data.gaswarn);
        setdataSource_splitklima(data.splitklima);
        setdataSource_arbeitsanweisungen(data.arbeitsanweisungen);
        setformData(data.moschein);
        if (data.moschein.unterschrift_name != undefined) {
            if (data.moschein.unterschrift_name != '') {
                setkunde_text(data.moschein.unterschrift_name)
                setunterschrift_kunde_typ('success')
                setabschluss(true)
            }
        }
        if (data.basf == true) {
            setbasf(true)
        }
        if (data.stoercode_pflicht) {
            setstoercode_pflicht(true)
        }

        if (data.stoercode_liste.length != 0) {

            setstoercode_options({ "acceptCustomValue": false, "dataSource": data.stoercode_liste })
            setstoercode_liste(data.stoercode_liste)
        } else {

            setstoercode_options({ "acceptCustomValue": true, "dataSource": [] })
        }
        if (data.mailadresse.length != 0) {
            setmailadressen(data.mailadresse)
        }
        if (data.arbeitsanweisungen.length != 0) {
            setarbeitsanweisungen(data.arbeitsanweisungen)
        }
        if (data.last_az != undefined) {
            setlast_az(data.last_az)
        }

        if (data.moschein.unterschrift_monteur != undefined) {
            setabschluss(true)
            setunterschrift_monteur_typ('success')
        }
        if (data.arbeitsanweisungen.length > 0) {
            alert("Es sind Arbeitsanweisungen vorhanden", "Arbeitsanweisungen");
        }


        getToken_api().then(token => {
            setToken(token)
            const store2 = new ODataStore({
                type: 'odata',
                url: networkConfig.dataEndpoint + 'anfahrtszone',
                key: 'anfahrtszone_id',
                withCredentials: true,
                beforeSend: function (e) {
                    e.headers = {
                        "Authorization": 'Bearer ' + token
                    };
                },
            });

            setentfernungszone_select_options({ dataSource: { store: store2, sort: ["zone"] }, displayExpr: "zone", valueExpr: "zone" })


            setdataSource_moschein_historie(new DataSource({


                store: {
                    type: 'odata',
                    url: networkConfig.dataEndpoint + 'moschein',
                    key: 'moschein_id',
                    withCredentials: true,
                    beforeSend: function (e) {
                        e.headers = {
                            "Authorization": 'Bearer ' + token
                        };
                    }, filter: ["auftrag_id","=", data.moschein.auftrag_id]
                }
            }));

            const headers = new Headers();
            const bearer = `Bearer ${token}`;
            headers.append("Authorization", bearer);
            headers.append("Content-Type", "application/json;charset=UTF-8")
            const options = {
                method: "GET",
                headers: headers,

            };

            return fetch(networkConfig.serverEndpoint + "api/moschein/GetLagerbestandMonteur", options)
                .then(response => response.json())
                .then(data => {
                    if (data.success == true) {
                        console.log(data.result_set);
                        setdataSource_perslager(data.result_set);
                    }

                }
                )
                .catch(error => console.log(error));

        })
    }, []);

    let menge_change = function (newData, value, currentRowData) {
        newData.menge = value
        newData.preis_gesamt = (currentRowData.preis_einzel_ohneRabatt * (1 - currentRowData.rabatt / 100)) * value
        newData.preis_gesamt_ohneRabatt = (currentRowData.preis_einzel_ohneRabatt) * value
    };
    let einzel_preis_change = function (newData, value, currentRowData) {
        newData.preis_einzel_ohneRabatt = value
        newData.preis_gesamt_ohneRabatt = (currentRowData.menge) * value
        newData.preis_einzel = (value * (1 - currentRowData.rabatt / 100))
        newData.preis_gesamt = (value * (1 - currentRowData.rabatt / 100)) * currentRowData.menge
    };
    let gesamt_preis_change = function (newData, value, currentRowData) {
        newData.preis_gesamt_ohneRabatt = value
        newData.preis_einzel_ohneRabatt = value / (currentRowData.menge)
        newData.preis_einzel = ((value / (currentRowData.menge)) * (currentRowData.rabatt / 100))
        newData.preis_gesamt = (value * (1 - currentRowData.rabatt / 100))
    };
    let rabatt_change = function (newData, value, currentRowData) {
        newData.rabatt = value
        newData.preis_einzel = ((currentRowData.preis_einzel_ohneRabatt) * (1 - value / 100))
        newData.preis_gesamt = ((currentRowData.preis_einzel_ohneRabatt) * (1 - value / 100)) * currentRowData.menge

    };

    let versenden = function (e) {

        var result = confirm("Soll der MoSchein wirklich abgesendet werden?")
        result.then((dialogResult) => {

            if (dialogResult == true) {
                var data = JSON.parse(localStorage.getItem('moschein:' + moschein_id))

                //mowartung Tags umwandelns
                data.mowartung.forEach(function (part, index, theArray) {
                    var stringelemente = "";
                    theArray[index].elemente.forEach(element => stringelemente += element + ",");
                    theArray[index].elemente = stringelemente;
                });
                console.log(data.mowartung)
                const headers = new Headers();
                const bearer = `Bearer ${token}`;
                headers.append("Authorization", bearer);
                headers.append("Content-Type", "application/json;charset=UTF-8")
                const options = {
                    method: "POST",
                    headers: headers,
                    body: JSON.stringify(data)
                };
                return fetch(networkConfig.serverEndpoint + "api/moschein/AddMoschein_Tablet", options)
                    .then(response => response.json())
                    .then(data => {
                        if (data.success == true) {
                            console.log(data)
                            if (data.result_set.tourauftrag_id != undefined) {
                                setstatus_items([{ "text": "Erledigt", "id": 6, "tourauftrag_id": data.result_set.tourauftrag_id, "auftrag_id": data.result_set.aufrag_id, "moschein_id": data.result_set.moschein_id }, { "text": "Teilerledigt", "id": 10, "tourauftrag_id": data.result_set.tourauftrag_id, "auftrag_id": data.result_set.auftrag_id, "moschein_id": data.result_set.moschein_id }, { "text": "Kunde abwesend", "id": 7, "tourauftrag_id": data.result_set.tourauftrag_id, "auftrag_id": data.result_set.auftrag_id, "moschein_id": data.result_set.moschein_id }, { "text": "Abgebrochen", "id": 9, "tourauftrag_id": data.result_set.tourauftrag_id, "auftrag_id": data.result_set.auftrag_id, "moschein_id": data.result_set.moschein_id }])
                                setstatus_visible(true)


                            } else {
                                notify({
                                    message: 'MoSchein wurde erfolgreich versendet',
                                }, 'success', 5000);
                                history.push("/Moscheine/Mobil/anzeigen");
                            }

                        } else {
                            notify({
                                message: 'Fehler :(',
                            }, 'error', 5000);
                        }
                    }
                    )
                    .catch(error => console.log(error));
            }
        })



    };
    let unterschreiben_monteur = function (e) {
        if (speichern() == true) {
            setpopup_unterschrift_monteur_visible(true)
        }

    }
    let unterschreiben_kunde = function (e) {
        if (speichern() == true) {
            setpopup_unterschrift_kunde_visible(true)
        }
    }
    let speichern = function (e) {

        var data = {};
        data.moschein = formData;
        data.molohn = dataSource_molohn;
        data.momat = dataSource_momat;
        data.momail = dataSource_momail;
        data.dhp = dataSource_dhp;
        data.mobasfkaelte = dataSource_mobasfkaelte;
        data.mobasfschadenverdichter = dataSource_mobasfschadenverdichter;
        data.mobilder = dataSource_mobilder;
        data.moprotokollbilder = dataSource_moprotokollbilder;
        data.mowartung = dataSource_mowartung;
        data.wartung_vdma = dataSource_wartung_vdma;
        data.gaswarn = dataSource_gaswarn;
        data.splitklima = dataSource_splitklima;
        data.basf = basf;
        data.stoercode_pflicht = stoercode_pflicht;
        data.stoercode_liste = stoercode_liste;
        data.mailadresse = mailadressen;
        data.arbeitsanweisungen = arbeitsanweisungen;
        data.last_az = last_az;

        if (data.moschein.unterschrift_monteur != undefined) {
            setabschluss(true)
            setunterschrift_monteur_typ('success')
        }

        localStorage.setItem("moschein:" + moschein_id, JSON.stringify(data));

        if (stoercode_pflicht == true && (formData.stoercode == '' || formData.stoercode == null || formData.stoercode == undefined)) {
            notify({
                message: 'Störcode fehlt',
            }, 'error', 3000);
            return false
        }
        if (formData.auftragsdatum == null || formData.auftragsdatum == undefined) {
            notify({
                message: 'Einsatzdatum fehlt',
            }, 'error', 3000);
            return false
        }
        if (formData.kunden_name == null || formData.kunden_name == "" || formData.kunden_name == undefined || formData.kunden_strasse == null || formData.kunden_strasse == "" || formData.kunden_strasse == undefined || formData.kunden_ort == null || formData.kunden_ort == "" || formData.kunden_ort == undefined || formData.kunden_plz == null || formData.kunden_plz == "" || formData.kunden_plz == undefined) {
            notify({
                message: 'Kundendaten fehlen',
            }, 'error', 3000);
            return false
        }
        if (formData.arbeitsbericht == null || formData.arbeitsbericht == undefined || formData.arbeitsbericht == "") {
            notify({
                message: 'Arbeitsbericht fehlt',
            }, 'error', 3000);
            return false
        }
        if (formData.leistung_beendet != true && formData.nachfolgeauftrag_notwendig != true) {
            notify({
                message: 'Leistung beendet (B-Schein) oder Nachfolgeauftrag notwendig (A-Schein) auswählen',
            }, 'error', 3000);
            return false
        }
        console.log(dataSource_momail)
        if (dataSource_momail.length == 0 && formData.kein_versand != true) {
            notify({
                message: 'Kein Versand ausgewählt',
            }, 'error', 3000);
            return false
        }
        notify({
            message: 'Erfolgreich gespeichert',
        }, 'success', 5000);
        if (e != undefined) {
            if (e.element.innerText == "VERSENDEN") {
                if (formData.unterschrift_kunde != undefined && formData.unterschrift_monteur != undefined) {
                    versenden();
                }
            }
        }


        return true

    };
    const begin_aendern = function (e) {
        const zeiteintrag = new Date(e.value);
        if (zeiteintrag != undefined) {
            if (zeiteintrag.getMinutes() != 0 && zeiteintrag.getMinutes() != 15 && zeiteintrag.getMinutes() != 30 && zeiteintrag.getMinutes() != 45) {
                if (zeiteintrag.getMinutes() != 59 || zeiteintrag.getHours() != 23) {
                    if (Math.round((zeiteintrag.getMinutes() / 15)) == 4) {
                        zeiteintrag.setMinutes(0);
                        zeiteintrag.setHours(zeiteintrag.getHours() + 1);
                        e.component.option("value", zeiteintrag);
                    };
                    if (Math.round((zeiteintrag.getMinutes() / 15)) == 3) {
                        zeiteintrag.setMinutes(45);
                        e.component.option("value", zeiteintrag);
                    };
                    if (Math.round((zeiteintrag.getMinutes() / 15)) == 2) {
                        zeiteintrag.setMinutes(30);
                        e.component.option("value", zeiteintrag);

                    };

                    if (Math.round((zeiteintrag.getMinutes() / 15)) == 1) {
                        zeiteintrag.setMinutes(15);
                        e.component.option("value", zeiteintrag);

                    };
                    if (Math.round((zeiteintrag.getMinutes() / 15)) == 0) {
                        zeiteintrag.setMinutes(0);
                        e.component.option("value", zeiteintrag);

                    };
                };

            };
        };
        if (ende_value != null) {
            var diffInhour = (((ende_value - zeiteintrag) / (60 * 60 * 1000)));
            setarbeitsstunden_value((Math.round((diffInhour) * 4)) / 4);
        }

        setbegin_value(zeiteintrag);
        setendeMin(zeiteintrag);

    };
    const ende_aendern = function (e) {
        const zeiteintrag = new Date(e.value);
        if (zeiteintrag != undefined) {
            if (zeiteintrag.getMinutes() != 0 && zeiteintrag.getMinutes() != 15 && zeiteintrag.getMinutes() != 30 && zeiteintrag.getMinutes() != 45) {
                if (zeiteintrag.getMinutes() != 59 || zeiteintrag.getHours() != 23) {
                    if (Math.round((zeiteintrag.getMinutes() / 15)) == 4) {
                        zeiteintrag.setMinutes(0);
                        zeiteintrag.setHours(zeiteintrag.getHours() + 1);
                        e.component.option("value", zeiteintrag);
                    };
                    if (Math.round((zeiteintrag.getMinutes() / 15)) == 3) {
                        zeiteintrag.setMinutes(45);
                        e.component.option("value", zeiteintrag);
                    };
                    if (Math.round((zeiteintrag.getMinutes() / 15)) == 2) {
                        zeiteintrag.setMinutes(30);
                        e.component.option("value", zeiteintrag);

                    };

                    if (Math.round((zeiteintrag.getMinutes() / 15)) == 1) {
                        zeiteintrag.setMinutes(15);
                        e.component.option("value", zeiteintrag);

                    };
                    if (Math.round((zeiteintrag.getMinutes() / 15)) == 0) {
                        zeiteintrag.setMinutes(0);
                        e.component.option("value", zeiteintrag);

                    };
                };

            };
        };
        if (begin_value != null) {
            var diffInhour = (((zeiteintrag - begin_value) / (60 * 60 * 1000)));
            setarbeitsstunden_value((Math.round((diffInhour) * 4)) / 4);
        }

        setende_value(zeiteintrag);


    };
    var kunde_unterschrift_text = useRef(null)
    var sigPad_monteur = {};
    var sigPad_kunde = {};
    const status_visible_changed = function (e) {
        if (e !== status_visible) {
            setstatus_visible(e)
        }
    }
    const status_click = function (e) {
        setstatus_visible(false)

        var tourauftrag = { "tourauftrag_id": e.itemData.tourauftrag_id, "statecode": e.itemData.id, "auftrag_id": e.itemData.auftrag_id }
        const headers = new Headers();
        const bearer = `Bearer ${token}`;
        headers.append("Authorization", bearer);
        headers.append("Content-Type", "application/json;charset=UTF-8")
        const options = {
            method: "POST",
            headers: headers,
            body: JSON.stringify(tourauftrag)
        };
        console.log(tourauftrag)
        return fetch(networkConfig.serverEndpoint + "api/moschein/UpdateTourauftrag", options)
            .then(response => response.json())
            .then(data => {
                if (data.success == true) {
                    notify({
                        message: 'MoSchein wurde erfolgreich versendet',
                    }, 'success', 5000);
                    history.push("/Moscheine/Mobil/anzeigen");


                } else {

                    history.push("/Moscheine/Mobil/anzeigen");
                }

            }
            )
            .catch(error => history.push("/Moscheine/Mobil/anzeigen"));


    }
    const get_preis = function (e) {
        var netto = matgrid.current.instance.getTotalSummaryValue("preis") + lohngrid.current.instance.getTotalSummaryValue("preis")
        var brutto = netto * 1.19
        var MwSt = brutto - netto
        setformData({ ...formData, preis_summe_netto: netto, preis_summe_brutto: brutto, preis_summe_mwst: MwSt })

    }
    const matgrid = useRef(null);
    const lohngrid = useRef(null);
    const bildergrid = useRef(null);
    const protokollbildergrid = useRef(null);
    const form = useRef(null);
    return (

        <React.Fragment>
            <ActionSheet
                title="Statusänderung für Tourauftrag"
                visible={status_visible}
                items={status_items}
                onItemClick={status_click}
                onVisibleChange={status_visible_changed}
                showCancelButton={false}
            />
            <div className="dx-toolbar-items-container" style={{ height: "60px" }}>

                <div className="dx-toolbar-before">
                    <h2 className={'content-block'}>MoSchein {formData.moschein_nr}</h2>
                </div>
                <div className="dx-toolbar-after" style={{ "paddingTop": "20px", "paddingRight": "40px" }}>

                    <Button_normal
                        text="Kunde Unterschrift"
                        icon="edit"
                        // disabled={abschluss}
                        type={unterschrift_kunde_typ}
                        onClick={unterschreiben_kunde}

                    />
                    <Button_normal
                        text="Monteur Unterschrift"
                        icon="edit"
                        //  disabled={abschluss}
                        type={unterschrift_monteur_typ}
                        onClick={unterschreiben_monteur}

                    />
                    <Button_normal
                        text={speichern_text}
                        icon="save"
                        type='normal'
                        onClick={speichern}
                    />

                </div>




            </div>

            <div className={'content-block dx-card responsive-paddings'}>
                <Form
                    id={'form'}
                    formData={formData}
                    labelLocation={'left'}
                    readOnly={abschluss}
                    alignItemLabelsInAllGroups={false}
                    validationGroup="AuftragData"
                    ref={form}
                >
                    <TabbedItem>
                        <TabPanelOptions deferRendering={false} />
                        <Tab title="Allgemein" colCount={2}>
                            <GroupItem caption="Allgemeine Informationen">
                                <Item dataField="moschein_nr" editorOptions={{ disabled: true }}>
                                    <Label text="MoSchein-Nr" />
                                </Item>
                                <Item dataField="auftragsdatum" editorType="dxDateBox" editorOptions={{ value: new Date() }}>
                                    <Label text="Einsatzdatum" />
                                    <RequiredRule message="Einsatzdatum wird benötigt" />
                                </Item>
                                <Item dataField="festpreis" editorType="dxCheckBox" editorOptions={{}}>
                                    <Label text="Festpreisauftrag" />
                                </Item>
                                <Item dataField="wartung" editorType="dxCheckBox" editorOptions={{
                                    onValueChanged: function (e) {

                                        if (e.value != null || e.value == '') {
                                            setWartung(e.value)
                                            if (e.value == true) { setdataSource_momat(dataSource_momat.slice(10)); };
                                            if (formData.wartung_vdma == true) {
                                                setWartung_VDMA(true)
                                            }
                                        }

                                    }
                                }}>
                                    <Label text="Wartung" />
                                </Item>
                                <Item dataField="wartung_vdma" visible={wartung} editorType="dxCheckBox" editorOptions={{
                                    onValueChanged: function (e) {

                                        if (e.value != null || e.value == '') {

                                            setWartung_VDMA(e.value)
                                        }

                                    }
                                }}>
                                    <Label text="Wartung nach VDMA 24186" />
                                </Item>
                                <Item dataField="wartungsdatun" visible={wartung} editorType="dxDateBox" editorOptions={{ value: new Date() }}>
                                    <Label text="Wartungsdatum" />

                                </Item>
                                <Item dataField="objektbez" visible={wartung} >
                                    <Label text="Objektbezeichnung" />
                                </Item>
                            </GroupItem>

                            <GroupItem caption="Sonstige Informationen" >
                                <Item dataField="kostentraeger" >
                                    <Label text="Kostenträger" />
                                </Item>
                                <Item dataField="vertragsnummer" >
                                    <Label text="Vertragsnummer" />
                                </Item>
                                <Item dataField="stoercode" editorType="dxSelectBox" editorOptions={stoercode_options}>
                                    <Label text="Störcode" />
                                </Item>
                                <Item dataField="kkl_objekt_nummer" visible={wartung ? false : true} >
                                    <Label text="KKL Objekt-Nummer" />
                                </Item>
                                <Item dataField="kuehlstellen_standortnummer" visible={wartung ? false : true}>
                                    <Label text="Kühlstellenstandortnummer" />
                                </Item>
                                <Item dataField="akg" visible={wartung ? false : true}>
                                    <Label text="AKG" />
                                </Item>
                                <Item dataField="lieferant_dka" visible={wartung ? false : true}>
                                    <Label text="Lieferant DKA" />
                                </Item>
                                <Item dataField="hersteller" visible={wartung ? false : true}>
                                    <Label text="Hersteller" />
                                </Item>
                                <Item dataField="anlagen_moebel_type" visible={wartung ? false : true}>
                                    <Label text="Anlagen-Möbel-Typ" />
                                </Item>


                            </GroupItem>

                        </Tab>
                        <Tab title="Wartung" visible={wartung == true && wartung_VDMA == false}>
                            <DataGrid
                                dataSource={dataSource_mowartung}
                                showBorders={true}
                                onEditorPreparing={function (e) {
                                    if (e.dataField == "datum" && e.parentType === "dataRow") {

                                    };
                                }}

                                onInitNewRow={function (info) {
                                    info.data.moschein_id = moschein_id;
                                    info.data.position = info.component.getVisibleRows().length + 1;
                                }}
                                onRowInserting={function (e) {

                                }}
                                onRowUpdating={function (e) {
                                }}
                            >
                                <Sorting mode="multiple" />
                                <Editing
                                    mode="form"
                                    allowUpdating={abschluss ? false : true}
                                    allowDeleting={abschluss ? false : true}
                                    allowAdding={abschluss ? false : true}
                                >
                                    <GridForm title="Wartung" showTitle={false} labelLocation={'left'} colCount={3} >
                                        <GroupItem caption="Allgemein" >
                                            <Item dataField="objektbezeichnung">
                                                <Label text="Anlagenbezeichnung" />
                                            </Item>
                                            <Item dataField="kkl_objekt_nummer">
                                                <Label text="KKL/Objekt-Nr" />
                                            </Item>
                                            <Item dataField="kuehlstellen_standprtnummer">
                                                <Label text="Kühlstellen-Standort-Nr" />
                                            </Item>
                                            <Item dataField="akg">
                                                <Label text="AKG" />
                                            </Item>
                                        </GroupItem>
                                        <GroupItem caption="Sicherheitsdruckwächter" >
                                            <Item dataField="sicherheitsdruckwaechter_ein" editorType="dxNumberBox" editorOptions={{

                                                placeholder: "in bar",
                                                format: "#0.## bar"
                                            }}>
                                                <Label text="Sicherheitsdruckwächter-Ein" />
                                            </Item>
                                            <Item dataField="sicherheitsdruckwaechter_aus" editorType="dxNumberBox" editorOptions={{

                                                placeholder: "in bar",
                                                format: "#0.## bar"
                                            }}>
                                                <Label text="Sicherheitsdruckwächter-Aus" />
                                            </Item>
                                        </GroupItem>
                                        <GroupItem caption="Saugdruckwächter" >
                                            <Item dataField="saugdruckwaechter_ein" editorType="dxNumberBox" editorOptions={{

                                                placeholder: "in bar",
                                                format: "#0.## bar"
                                            }}>
                                                <Label text="Saugdruckwächter-Ein" />
                                            </Item>
                                            <Item dataField="saugdruckwaechter_aus" editorType="dxNumberBox" editorOptions={{

                                                placeholder: "in bar",
                                                format: "#0.## bar"
                                            }}>
                                                <Label text="Saugdruckwächter-Aus" />
                                            </Item>
                                        </GroupItem>
                                        <GroupItem caption="Elemente" colSpan={3} >
                                            <Item dataField="elemente" editorType="dxTagBox" editorOptions={{
                                                items: [" Verdichter", " Rohrleitungen", " Steuerung, Regelung", " Wassergekühlte Verflüssiger", " Armaturen", " Elektromotoren", " Luftgekühlte Verflüssiger", " Rückkühlanlagen", " Riementriebe", " Verdampfer", " Ventilator", " Dichtheit überprüft", " MSR, Sicherheitseinrichtungen", " Meß- u. Anzeigegeräte", " Schutzleiter überprüft"]
                                                , showSelectionControls: true,
                                                //deferRendering: false,
                                                applyValueMode: "useButtons",
                                                acceptCustomValue: true,
                                                onCustomItemCreating: function (args) {
                                                    var newValue = args.text,
                                                        component = args.component,
                                                        currentItems = component.option("items");
                                                    currentItems.unshift(newValue);
                                                    component.option("items", currentItems);
                                                    return newValue;
                                                }
                                            }}>
                                                <Label text="Elemente" />
                                            </Item>
                                        </GroupItem>

                                    </GridForm>
                                </Editing>

                                <Column
                                    dataField="position"
                                    caption="Position"
                                    defaultSortOrder="asc"

                                />
                                <Column
                                    dataField="objektbezeichnung"
                                    caption="Anlagenbezeichnung"

                                />

                                <Column
                                    dataField="kkl_objekt_nummer"
                                    caption="KKL/Objekt-Nr"
                                />
                                <Column
                                    dataField="kuehlstellen_standprtnummer"
                                    caption="Kühlstellen-Standort-Nr"

                                />
                                <Column
                                    dataField="akg"
                                    caption="AKG"
                                />
                                <Column
                                    dataField="elemente"
                                    caption="Elemente"
                                />
                                <Column
                                    dataField="sicherheitsdruckwaechter_ein"
                                    caption="Sicherheitsdruckwächter-Ein"
                                //format="#0.## bar"
                                />
                                <Column
                                    dataField="sicherheitsdruckwaechter_aus"
                                    caption="Sicherheitsdruckwächter-Aus"
                                    format="#0.## bar"
                                />
                                <Column
                                    dataField="saugdruckwaechter_ein"
                                    caption="Saugdruckwächter-Ein"
                                    format="#0.## bar"
                                />
                                <Column
                                    dataField="saugdruckwaechter_aus"
                                    caption="Saugdruckwächter-Aus"
                                    format="#0.## bar"
                                />

                            </DataGrid>
                        </Tab>
                        <Tab title="Wartung VDMA" visible={wartung == true && wartung_VDMA == true}>
                            <DataGrid
                                dataSource={dataSource_wartung_vdma}
                                showBorders={true}
                                onEditorPreparing={function (e) {
                                    if (e.dataField == "datum" && e.parentType === "dataRow") {

                                    };
                                }}

                                onInitNewRow={function (info) {
                                    localStorage.removeItem("wartung_vdma_allgemein")
                                    localStorage.removeItem("wartung_vdma_details")
                                    localStorage.removeItem("wartung_vdma_grid")
                                    history.push("/moschein/protokolle/wartung_vdma/" + moschein_id);
                                }}
                                onRowInserting={function (e) {

                                }}
                                onRowUpdating={function (e) {
                                }}
                            >
                                <Sorting mode="multiple" />
                                <Editing
                                    mode="cell"
                                    allowUpdating={false}
                                    allowDeleting={true}
                                    allowAdding={abschluss ? false : true}
                                />



                                <Column
                                    dataField="anlage"
                                    caption="Anlage"


                                />
                                <Column type="buttons">
                                    <Button
                                        text="kopieren"
                                        visible={abschluss ? false : true}
                                        onClick={function (e) {
                                            console.log(e)

                                            localStorage.setItem("wartung_vdma_allgemein", JSON.stringify(e.row.data))
                                            localStorage.setItem("wartung_vdma_details", JSON.stringify(e.row.data.wartung_vdma_details))
                                            history.push("/moschein/protokolle/wartung_vdma/" + moschein_id);
                                        }}
                                    />
                                </Column>
                                <Column type="buttons">
                                    <Button
                                        text="bearbeiten"
                                        visible={abschluss ? false : true}
                                        onClick={function (e) {


                                            localStorage.setItem("wartung_vdma_allgemein", JSON.stringify(e.row.data))
                                            localStorage.setItem("wartung_vdma_details", JSON.stringify(e.row.data.wartung_vdma_details))

                                            history.push("/moschein/protokolle/wartung_vdma/" + moschein_id);
                                        }}
                                    />
                                </Column>
                                <Column type="buttons">
                                    <Button
                                        text="löschen"
                                        visible={abschluss ? false : true}
                                        onClick={function (e) {

                                            e.component.deleteRow(e.row.rowIndex)

                                        }}
                                    />
                                </Column>




                            </DataGrid>
                        </Tab>
                        <Tab title="BASF" visible={basf}>

                        </Tab>
                        <Tab title="Kunde" colCount={3}>
                            <GroupItem caption="Kunde" >
                                <Item dataField="kundennummer" >
                                    <Label text="Kundennummer" />
                                    <TextBox
                                        value={formData.kundennummer}
                                        onValueChanged={function (e) { setformData({ ...formData, kundennummer: e.value }) }}
                                    >
                                        <TextBoxButton
                                            name="kundendatenabrufen_button"
                                            location="after"
                                            options={{
                                                text: 'Abrufen',
                                                type: 'default',
                                                onClick: kundendatenabrufen
                                            }}
                                        />
                                    </TextBox>

                                </Item>
                                <Item dataField="kunden_name" >
                                    <Label text="Name" />
                                </Item>
                                <Item editorType="dxButton" editorOptions={ {text:"MoSchein Historie anzeigen",
                                    onClick:function(e){setpopup_moschein_historie(true)}}} >
                                 
                                </Item>
                                
                            </GroupItem>
                            <GroupItem caption="Anschrift" >
                                <Item dataField="kunden_strasse" >
                                    <Label text="Straße" />
                                </Item>
                                <Item dataField="kunden_plz" >
                                    <Label text="PLZ" />
                                </Item>
                                <Item dataField="kunden_ort" >
                                    <Label text="ORT" />
                                </Item>

                            </GroupItem>
                            <GroupItem caption="Sonstiges" >
                                <Item dataField="kundeninformation" editorType="dxTextArea" editorOptions={{ height: '200' }} >
                                    <Label text="Kundeninformation" />
                                </Item>
                                <Item dataField="entfernungszone">
                                    <Label text="Entfernungszone" />
                                </Item>

                            </GroupItem>
                        </Tab>
                        <Tab title="Zeiten" colCount={1}>
                            <DataGrid
                                ref={lohngrid}
                                dataSource={dataSource_molohn}
                                showBorders={true}
                                onEditingStart={function (e) {
                                
                                    setbegin_value(new Date(e.data.beginn))
                                    setende_value(new Date(e.data.ende))
                                    var diffInhour = (((new Date(e.data.ende) - new Date(e.data.beginn)) / (60 * 60 * 1000)));
                                    setarbeitsstunden_value((Math.round((diffInhour) * 4)) / 4);
                                }}
                                onEditorPreparing={function (e) {
                                    if (e.dataField == "datum" && e.parentType === "dataRow") {
                                      
                                        if (e.component.getVisibleRows().length > 1) {
                                            if (e.value == undefined) {
                                                e.setValue(e.component.getVisibleRows()[1].data.datum)

                                                var jetzt = new Date(e.component.getVisibleRows()[1].data.datum);
                                                var jetzt1 = new Date(e.component.getVisibleRows()[1].data.datum);
                                                var jetzt2 = new Date(e.component.getVisibleRows()[1].data.datum);

                                                e.editorOptions.min = jetzt
                                                e.editorOptions.max = new Date(jetzt1.setDate(jetzt2.getDate() + 1))
                                                e.editorOptions.value = jetzt
                                            }



                                            ;
                                        }
                                        else {
                                            if (e.value == undefined) {
                                            e.setValue(formData.auftragsdatum)
                                            //Beginn nach letztem Moschein festlegen
                                            e.editorOptions.value = formData.auftragsdatum;
                                            }
                                        };


                                    };


                                }}

                                onInitNewRow={function (info) {
                                    info.data.moschein_id = moschein_id;
                                    info.data.wegzeit = 0;
                                    info.data.kilometer = 0;

                                    setbegin_value(null)
                                    setende_value(null)
                                }}

                                onRowInserting={function (e) {
                                    debugger;
                                    e.data.beginn = new Date(e.data.datum.getFullYear(), e.data.datum.getMonth(), e.data.datum.getDate(), begin_value.getHours(), begin_value.getMinutes())
                                    e.data.ende = new Date(e.data.datum.getFullYear(), e.data.datum.getMonth(), e.data.datum.getDate(), ende_value.getHours(), ende_value.getMinutes())
                                    e.data.arbeitsstunden = arbeitsstunden_value
                                    try {
                                        if (arbeitsstunden_value > 6) { alert("Ihre Arbeitszeit ist größer als 6 Stunden, bitte tragen Sie Ihre Pausenzeit ein. Hinweis: Es sind 2 Arbeitszeitzeilen notwendig.", "Achtung") }
                                        if (Math.round((e.data.beginn.getTime() - (e.data.wegzeit * 60000)) / 60000) < (Math.round(new Date(last_az).getTime() / 60000) - 1)) {
                                            var result = confirm("Beginn der Arbeitszeit (abzgl. Reisezeit) liegt vor der letzten Eintragung eines anderen MoScheins. Trotzdem fortfahren?")
                                            result.then((dialogResult) => {

                                                if (dialogResult == false) {
                                                    e.cancel = true;

                                                }

                                            })
                                        }
                                    }
                                    catch { }

                                }}
                                onRowUpdating={function (e) {
                                    console.log(e)
                                    if (e.newData.datum != undefined) {
                                        e.newData.beginn = new Date(e.newData.datum.getFullYear(), e.newData.datum.getMonth(), e.newData.datum.getDate(), begin_value.getHours(), begin_value.getMinutes())
                                        e.newData.ende = new Date(e.newData.datum.getFullYear(), e.newData.datum.getMonth(), e.newData.datum.getDate(), ende_value.getHours(), ende_value.getMinutes())

                                    } else {
                                        e.newData.beginn = new Date(e.oldData.datum.getFullYear(), e.oldData.datum.getMonth(), e.oldData.datum.getDate(), begin_value.getHours(), begin_value.getMinutes())
                                        e.newData.ende = new Date(e.oldData.datum.getFullYear(), e.oldData.datum.getMonth(), e.oldData.datum.getDate(), ende_value.getHours(), ende_value.getMinutes())

                                    }
                                    e.newData.arbeitsstunden = arbeitsstunden_value
                                }}

                            >
                                <Summary>
                                    <TotalItem
                                        column="preis"
                                        summaryType="sum"
                                        name="preis" />

                                </Summary>
                                <Sorting mode="multiple" />
                                <Editing
                                    mode="form"
                                    allowUpdating={abschluss ? false : true}
                                    allowDeleting={abschluss ? false : true}
                                    allowAdding={abschluss ? false : true}
                                >
                                    <GridForm title="Zeiten" showTitle={false} labelLocation={'left'} colCount={3} >
                                        <GroupItem caption="Zeit" >
                                            <Item dataField="datum" editorType="dxDateBox">
                                                <Label text="Datum" />
                                                <RequiredRule message="Datum wird benötigt" />
                                            </Item>
                                            <Item dataField="beginn" >
                                                <Label text="Beginn" />
                                                <DateBox type="time"
                                                    value={begin_value}
                                                    interval={15} onValueChanged={begin_aendern}>
                                                </DateBox>
                                                <RequiredRule message="Beginn wird benötigt" />
                                            </Item>
                                            <Item dataField="ende" value={ende_value}>
                                                <DateBox type="time"
                                                    value={ende_value}
                                                    interval={15} min={endeMin} onValueChanged={ende_aendern}>
                                                </DateBox>
                                                <Label text="Ende" />
                                                <RequiredRule message="Ende wird benötigt" />
                                            </Item>
                                        </GroupItem>
                                        <GroupItem caption="Anfahrt" >
                                            <Item dataField="wegzeit" editorType="dxSelectBox" editorOptions={{
                                                dataSource: wegzeiten,
                                                valueExpr: "min",
                                                displayExpr: "anzeige"
                                            }} >
                                                <Label text="Wegzeit" />
                                            </Item>
                                            <Item dataField="kilometer" editorType="dxNumberBox" >
                                                <Label text="Kilometer" />
                                            </Item>
                                        </GroupItem>
                                        <GroupItem caption="Summen" >
                                            <Item dataField="arbeitsstunden" value={arbeitsstunden_value}>
                                                <NumberBox value={arbeitsstunden_value}>
                                                </NumberBox>
                                                <Label text="Arbeitsstunden" />
                                            </Item>
                                            <Item dataField="ueberstunden" editorType="dxNumberBox" >
                                                <Label text="dav. Überstunden" />
                                            </Item>
                                            <Item dataField="erschwaernis_h" editorType="dxNumberBox" >
                                                <Label text="Erschwernis-h" />
                                            </Item>
                                            <Item dataField="erschwaernis_preis" editorType="dxNumberBox">
                                                <Label text="Erschwernis-Preis" />
                                            </Item>
                                            <Item dataField="eilzuschlag" editorType="dxNumberBox">
                                                <Label text="Eilzuschlag" />
                                            </Item>
                                            <Item dataField="preis" editorType="dxNumberBox">
                                                <Label text="Gesamtpreis" />
                                            </Item>
                                        </GroupItem>
                                    </GridForm>
                                </Editing>

                                <Column
                                    dataField="datum"
                                    caption="Datum"
                                    dataType="date"
                                    format="shortdate"
                                    caption="Datum"
                                    width="12%"

                                />
                                <Column
                                    dataField="beginn"
                                    caption="Beginn"
                                    dataType="datetime"
                                    format="HH:mm 'Uhr'"
                                    defaultSortOrder="asc"
                                    width="12%"
                                />

                                <Column
                                    dataField="ende"
                                    caption="Ende"
                                    dataType="datetime"
                                    format="HH:mm 'Uhr'"
                                    width="12%"
                                />
                                <Column
                                    dataField="wegzeit"
                                    caption="Wegzeit"
                                    dataType="number"
                                    format="#0.## min"
                                    width="8%"
                                />
                                <Column
                                    dataField="kilometer"
                                    caption="Kilometer"
                                    dataType="number"
                                    format="#0.## km"
                                    width="8%"
                                />
                                <Column
                                    dataField="arbeitsstunden"
                                    caption="Arbeitsstunden"
                                    dataType="number"
                                    format="#0.## h"
                                    width="8%"
                                />
                                <Column
                                    dataField="ueberstunden"
                                    caption="davon Überst."
                                    dataType="number"
                                    format="#0.## h"
                                    width="8%"
                                />
                                <Column
                                    dataField="erschwaernis_h"
                                    caption="Erschwer.-h"
                                    dataType="number"
                                    format="#0.## h"
                                    width="8%"
                                />
                                <Column
                                    dataField="erschwaernis_preis"
                                    caption="Erschwer.-€"
                                    dataType="number"
                                    format="#0.## €"
                                    width="8%"
                                />
                                <Column
                                    dataField="eilzuschlag"
                                    caption="Eilzuschlag"
                                    dataType="number"
                                    format="#0.## h"
                                    width="8%"
                                />
                                <Column
                                    dataField="preis"
                                    caption="Gesamt-Preis"
                                    dataType="number"
                                    format="#0.## €"
                                    width="8%"
                                />

                            </DataGrid>
                        </Tab>
                        <Tab title="Material" colCount={1}>
                            <DataGrid
                                ref={matgrid}
                                dataSource={dataSource_momat}
                                showBorders={true}
                                onEditingStart={function (e) {
                                    debugger;
                                    if (e.data.artikelnummer != '0130019999' && e.data.artikelnummer != '' && e.data.artikelnummer != null) {
                                        setmat_disabled(true)
                                    } else {
                                        setmat_disabled(false)
                                    }

                                }}
                                onInitNewRow={function (info) {
                                    setmat_disabled(false)
                                    info.data.moschein_id = new Guid(moschein_id);
                                    info.data.rabatt = 0;
                                    info.data.preis_einzel_ohneRabatt = 0;
                                    info.data.preis_einzel = 0;
                                    info.data.preis_gesamt = 0;
                                    info.data.preis_gesamt_ohneRabatt = 0;
                                    info.data.position = info.component.getVisibleRows().length + 1;
                                }}
                            >

                                <Editing
                                    mode="form"
                                    allowUpdating={abschluss ? false : true}
                                    allowDeleting={abschluss ? false : true}
                                    allowAdding={abschluss ? false : true}
                                >
                                    <GridForm title="Artikel" showTitle={false} colCount={2} >
                                        <GroupItem caption="Artikel" >
                                            <Item dataField="position" dataType="number" >
                                                <Label text="Posistion" />
                                            </Item>
                                            <Item dataField="artikelnummer" >
                                                <Label text="Artikelnummer" />
                                            </Item>
                                            <Item dataField="kurzbezeichnung" editorType="dxAutocomplete" editorOptions={{ dataSource: dataSource_material_namen, displayExpr: "bezeichnung", valueExpr: "bezeichnung", disabled: mat_disabled, readOnly: mat_disabled }} >
                                                <Label text="Kurzbezeichnung" />
                                                <RequiredRule message="Kurzbezeichnung wird benötigt" />
                                            </Item>
                                        </GroupItem>
                                        <GroupItem caption="Menge/Preis" >
                                            <Item dataField="menge" editorOptions={{
                                            }} >
                                                <Label text="Menge" />
                                            </Item>
                                            <Item dataField="einheit" editorType="dxSelectBox" editorOptions={{
                                                dataSource: ['Kg', 'm', 'Pack', 'Pal', 'Stck'], disabled: mat_disabled, readOnly: mat_disabled
                                            }} >
                                                <Label text="Einheit" />
                                            </Item>

                                            <Item dataField="preis_einzel_ohneRabatt" editorType="dxNumberBox">
                                                <Label text="Einzel-Preis o.Rabatt" />
                                            </Item>

                                            <Item dataField="preis_gesamt_ohneRabatt" editorType="dxNumberBox" >
                                                <Label text="Gesamt-Preis o. Rabatt" />
                                            </Item>
                                            <Item dataField="rabatt" editorType="dxNumberBox" >
                                                <Label text="Rabatt" />
                                            </Item>
                                            <Item dataField="preis_einzel" editorType="dxNumberBox" editorOptions={{ readOnly: true, disabled: true }}>
                                                <Label text="Einzel-Preis" />
                                            </Item>
                                            <Item dataField="preis_gesamt" editorType="dxNumberBox" editorOptions={{ readOnly: true, disabled: true }}>
                                                <Label text="Gesamt-Preis" />
                                            </Item>

                                        </GroupItem>
                                    </GridForm>
                                </Editing>

                                <Column
                                    width="7%"
                                    dataField="position"
                                    caption="Position"
                                    defaultSortOrder="asc"
                                />
                                <Column
                                    width="35%"
                                    dataField="kurzbezeichnung"
                                    caption="Kurzbezeichnung"
                                    setCellValue={function (newData, value, currentRowData) {
                                        newData.kurzbezeichnung = value
                                        if (newData.kurzbezeichnung != null) {

                                            const headers = new Headers();
                                            const bearer = `Bearer ${token}`;
                                            headers.append("Authorization", bearer);
                                            headers.append("Content-Type", "application/json;charset=UTF-8")

                                            const options = {
                                                method: "POST",
                                                headers: headers,

                                            };


                                            return fetch(networkConfig.serverEndpoint + "api/moschein/getArtikelbyName?artikelname=" + newData.kurzbezeichnung, options)
                                                .then(response => response.json())
                                                .then(data => {

                                                    newData.artikelnummer = data.result_set.artikelnummer;
                                                    newData.einheit = data.result_set.einheit;

                                                    if (data.result_set.artikelnummer != undefined) {
                                                        setmat_disabled(true)
                                                    }
                                                }
                                                )
                                                .catch(error => console.log(error));


                                        }

                                    }}
                                />

                                <Column
                                    width="20%"
                                    dataField="artikelnummer"
                                    caption="Artikelnummer"
                                    setCellValue={function (newData, value, currentRowData) {
                                        newData.artikelnummer = value
                                        if (value != '0130019999' && value != '') {
                                            setmat_disabled(true)

                                            if (newData.artikelnummer.length >= 10) {

                                                const headers = new Headers();
                                                const bearer = `Bearer ${token}`;
                                                headers.append("Authorization", bearer);
                                                headers.append("Content-Type", "application/json;charset=UTF-8")

                                                const options = {
                                                    method: "POST",
                                                    headers: headers,

                                                };


                                                return fetch(networkConfig.serverEndpoint + "api/moschein/getArtikel?artikelnummer=" + newData.artikelnummer, options)
                                                    .then(response => response.json())
                                                    .then(data => {

                                                        newData.kurzbezeichnung = data.result_set.bezeichnung;
                                                        newData.einheit = data.result_set.einheit;
                                                    }
                                                    )
                                                    .catch(error => console.log(error));


                                            }
                                        } else {
                                            setmat_disabled(false)
                                        }



                                    }}
                                />
                                <Column
                                    dataField="menge"
                                    caption="menge"
                                    setCellValue={menge_change}
                                    width="7%"
                                />
                                <Column
                                    dataField="einheit"
                                    caption="Einheit"
                                    width="7%"
                                />
                                <Column
                                    dataField="preis_einzel_ohneRabatt"
                                    caption="Einzel-Preis o. Rabatt"
                                    setCellValue={einzel_preis_change}
                                    width="7%"
                                />
                                <Column
                                    dataField="preis_gesamt_ohneRabatt"
                                    caption="Einzel-Preis o. Rabatt"
                                    setCellValue={gesamt_preis_change}
                                    visible={false}

                                />
                                <Column
                                    dataField="rabatt"
                                    caption="Rabatt"
                                    setCellValue={rabatt_change}
                                    width="7%"
                                />
                                <Column
                                    dataField="preis_einzel"
                                    caption="Einzel-Preis"
                                    width="7%"
                                />
                                <Column
                                    dataField="preis_gesamt"
                                    caption="Gesamt-Preis"
                                    width="7%"
                                />


                            </DataGrid>
                        </Tab>
                        <Tab title="Arbeitsbericht" colCount={3}>

                            <Item dataField="arbeitsbericht" editorType="dxTextArea" colSpan={3}>
                                <Label text="Arbeitsbericht" />
                            </Item>
                            <Item dataField="auslagen" colSpan={3}>
                                <Label text="Auslagen" />
                            </Item>


                            <GroupItem caption="Dichtheitsprüfung" >
                                <Item dataField="dichtheitspruefung_erfolgt" editorType="dxCheckBox"  >
                                    <Label text="ausgeführt" />
                                </Item>
                                <Item dataField="dichtheitspruefung_undicht" >
                                    <Label text="undichte Stellen" />
                                </Item>

                            </GroupItem>
                            <GroupItem caption="Material" >
                                <Item dataField="material_bestellungen_erfolgt" editorType="dxCheckBox" >
                                    <Label text="bestellt" />
                                </Item>
                                <Item dataField="material_bestellungen_bezeichnung" >
                                    <Label text="bestelltes Material" />
                                </Item>

                            </GroupItem>
                            <GroupItem caption="Nachfolgeauftrag/Vertrieb"  >
                                <Item dataField="leistung_beendet" editorType="dxCheckBox" editorOptions={{
                                    onValueChanged: function (e) {

                                        if (e.value != null) {
                                            form.current.instance.getEditor('nachfolgeauftrag_notwendig').option('value', false)
                                        }
                                    }
                                }} >
                                    <Label text="Leistung beendet (B-Schein)" />
                                </Item>
                                <Item dataField="nachfolgeauftrag_notwendig" editorType="dxCheckBox" editorOptions={{
                                    onValueChanged: function (e) {

                                        if (e.value != null) {
                                            form.current.instance.getEditor('leistung_beendet').option('value', false)
                                        }
                                    }
                                }} >
                                    <Label text="Nachfolgeauftrag notwendig (A-Schein)" />
                                </Item>
                                <Item dataField="vertrieb_gewuenscht" editorType="dxCheckBox" >
                                    <Label text="Vertrieb gewünscht" />
                                </Item>

                            </GroupItem>
                        </Tab>
                        <Tab title="Barzahler" colCount={1}>
                            <Item>
                                <Button_normal
                                    text="Preis aktualisieren"
                                    disabled={abschluss}
                                    onClick={get_preis}
                                />
                            </Item>
                            <Item dataField="barzahlung" editorType="dxCheckBox" >
                                <Label text="Barzahlung" />
                            </Item>

                            <Item dataField="preis_summe_netto" disabled={true}>
                                <Label text="Summe Netto" />
                            </Item>
                            <Item dataField="preis_summe_mwst" disabled={true}>
                                <Label text="Summe MwSt." />
                            </Item>
                            <Item dataField="preis_summe_brutto" disabled={true}>
                                <Label text="Summe Brutto" />
                            </Item>


                            <Item dataField="barzahlung_summe">
                                <Label text="erhaltener Betrag" />
                            </Item>


                        </Tab>

                        <Tab title="Versand" colCount={2}>
                            <Item >
                                <DataGrid
                                    dataSource={dataSource_momail}
                                    showBorders={true}


                                >

                                    <Editing
                                        mode="row"
                                        allowAdding={true}
                                        allowUpdating={true}
                                        allowDeleting={true}
                                    />



                                    <Column
                                        dataField="mailadresse"
                                        caption="Mailadresse"
                                        editorType="dxSelectBox" editorOptions={{ items: mailadressen, acceptCustomValue: true }}

                                    />





                                </DataGrid>
                            </Item>
                            <Item dataField="kein_versand" editorType="dxCheckBox">
                                <Label text="Kein Versand" />
                            </Item>
                        </Tab>
                        <Tab title="Protokolle">
                            <TabbedItem>

                                <Tab title="DHP">
                                    <DataGrid
                                        dataSource={dataSource_dhp}
                                        showBorders={true}
                                        onInitNewRow={function (info) {
                                            speichern()
                                            localStorage.removeItem("dhp")
                                            history.push("/moschein/protokolle/dhp/" + moschein_id);
                                        }}

                                    >
                                        <Sorting mode="multiple" />
                                        <Editing
                                            mode="cell"
                                            allowUpdating={false}
                                            allowDeleting={abschluss ? false : true}
                                            allowAdding={abschluss ? false : true}
                                        />


                                        <Column
                                            dataField="bezeichnung"
                                            caption="Bezeichnung"


                                        />
                                        <Column type="buttons">
                                            <Button
                                                text="kopieren"
                                                visible={abschluss ? false : true}
                                                onClick={function (e) {
                                                    console.log(e)
                                                    speichern()
                                                    localStorage.setItem("dhp", JSON.stringify(e.row.data))
                                                    history.push("/moschein/protokolle/dhp/" + moschein_id)
                                                }}
                                            />
                                        </Column>
                                        <Column type="buttons">
                                            <Button
                                                text="bearbeiten"
                                                visible={abschluss ? false : true}
                                                onClick={function (e) {

                                                    speichern()
                                                    localStorage.setItem("dhp", JSON.stringify(e.row.data))
                                                    history.push("/moschein/protokolle/dhp/" + moschein_id)
                                                }}
                                            />
                                        </Column>
                                        <Column type="buttons">
                                            <Button
                                                text="löschen"
                                                visible={abschluss ? false : true}
                                                onClick={function (e) {

                                                    e.component.deleteRow(e.row.rowIndex)
                                                    speichern()
                                                }}
                                            />
                                        </Column>

                                    </DataGrid>
                                </Tab>
                                <Tab title="Splitklima">
                                    <DataGrid
                                        dataSource={dataSource_splitklima}
                                        showBorders={true}
                                        onInitNewRow={function (info) {
                                            speichern()
                                            localStorage.removeItem("splitklima")
                                            history.push("/moschein/protokolle/splitklima/" + moschein_id);
                                        }}

                                    >
                                        <Sorting mode="multiple" />
                                        <Editing
                                            mode="cell"
                                            allowUpdating={false}
                                            allowDeleting={abschluss ? false : true}
                                            allowAdding={abschluss ? false : true}
                                        />


                                        <Column
                                            dataField="anlage"
                                            caption="Anlage"


                                        />
                                        <Column type="buttons">
                                            <Button
                                                text="kopieren"
                                                visible={abschluss ? false : true}
                                                onClick={function (e) {
                                                    console.log(e)
                                                    speichern()
                                                    localStorage.setItem("splitklima", JSON.stringify(e.row.data))
                                                    history.push("/moschein/protokolle/splitklima/" + moschein_id)
                                                }}
                                            />
                                        </Column>
                                        <Column type="buttons">
                                            <Button
                                                text="bearbeiten"
                                                visible={abschluss ? false : true}
                                                onClick={function (e) {

                                                    speichern()
                                                    localStorage.setItem("splitklima", JSON.stringify(e.row.data))
                                                    history.push("/moschein/protokolle/splitklima/" + moschein_id)
                                                }}
                                            />
                                        </Column>
                                        <Column type="buttons">
                                            <Button
                                                text="löschen"
                                                visible={abschluss ? false : true}
                                                onClick={function (e) {

                                                    e.component.deleteRow(e.row.rowIndex)
                                                    speichern()
                                                }}
                                            />
                                        </Column>

                                    </DataGrid>
                                </Tab>
                                <Tab title="Gaswarn">

                                    <DataGrid
                                        dataSource={dataSource_gaswarn}
                                        showBorders={true}
                                        onInitNewRow={function (info) {
                                            speichern()
                                            localStorage.removeItem("gaswarn")
                                            localStorage.removeItem("gaswarn_detail")
                                            history.push("/moschein/protokolle/gaswarn/" + moschein_id);
                                        }}

                                    >
                                        <Sorting mode="multiple" />
                                        <Editing
                                            mode="cell"
                                            allowUpdating={false}
                                            allowDeleting={abschluss ? false : true}
                                            allowAdding={abschluss ? false : true}
                                        />


                                        <Column
                                            dataField="gaswarngeraet"
                                            caption="Gaswarngerät"


                                        />
                                        <Column type="buttons">
                                            <Button
                                                text="kopieren"
                                                visible={abschluss ? false : true}
                                                onClick={function (e) {
                                                    console.log(e)
                                                    speichern()
                                                    localStorage.setItem("gaswarn", JSON.stringify(e.row.data))
                                                    localStorage.setItem("gaswarn_detail", JSON.stringify(e.row.data.gaswarn_detail))
                                                    history.push("/moschein/protokolle/gaswarn/" + moschein_id)
                                                }}
                                            />
                                        </Column>
                                        <Column type="buttons">
                                            <Button
                                                text="bearbeiten"
                                                visible={abschluss ? false : true}
                                                onClick={function (e) {

                                                    speichern()
                                                    localStorage.setItem("gaswarn", JSON.stringify(e.row.data))
                                                    localStorage.setItem("gaswarn_detail", JSON.stringify(e.row.data.gaswarn_detail))
                                                    history.push("/moschein/protokolle/gaswarn/" + moschein_id)
                                                }}
                                            />
                                        </Column>
                                        <Column type="buttons">
                                            <Button
                                                text="löschen"
                                                visible={abschluss ? false : true}
                                                onClick={function (e) {

                                                    e.component.deleteRow(e.row.rowIndex)
                                                    speichern()
                                                }}
                                            />
                                        </Column>

                                    </DataGrid>

                                </Tab>

                                <Tab title="Sonstige Protokolle">
                                    <Item>
                                        <Button_normal
                                            text="neues Bild"
                                            onClick={function (e) {
                                                const { takePhoto } = useCamera();
                                                takePhoto().then(uri => {


                                                    getToken_api().then(token => {
                                                        setToken(token)
                                                        const data = new FormData()
                                                        fetch(uri).then(r => r.blob()).then(blob => {
                                                            data.append('file', blob);
                                                            console.log(data)
                                                            axios.post(networkConfig.serverEndpoint + "api/moschein/UploadBild_Protokoll", data, {
                                                                headers: {
                                                                    'Authorization': `Bearer ${token}`
                                                                }
                                                            })
                                                                .then(res => { // then print response status
                                                                    console.log(res)
                                                                    var dataSource_mobilder_neu = dataSource_moprotokollbilder

                                                                    dataSource_mobilder_neu.push({ "moprotokollbilder_id": res.data.result_set.moprotokollbilder_id, "bild_uri": uri });
                                                                    setdataSource_moprotokollbilder(dataSource_mobilder_neu);

                                                                    protokollbildergrid.current.instance.refresh();
                                                                })

                                                        }

                                                        )





                                                    })


                                                })



                                            }}
                                        />
                                        <Button_normal
                                            text="neues Bild aus Galerie"
                                            onClick={function (e) {
                                                const { takePhoto } = usePhotoGallery();
                                                takePhoto().then(uri => {


                                                    getToken_api().then(token => {
                                                        setToken(token)
                                                        const data = new FormData()
                                                        fetch(uri).then(r => r.blob()).then(blob => {
                                                            data.append('file', blob);
                                                            console.log(data)
                                                            axios.post(networkConfig.serverEndpoint + "api/moschein/UploadBild_Protokoll", data, {
                                                                headers: {
                                                                    'Authorization': `Bearer ${token}`
                                                                }
                                                            })
                                                                .then(res => { // then print response status
                                                                    console.log(res)
                                                                    var dataSource_mobilder_neu = dataSource_moprotokollbilder

                                                                    dataSource_mobilder_neu.push({ "moprotokollbilder_id": res.data.result_set.moprotokollbilder_id, "bild_uri": uri });
                                                                    setdataSource_moprotokollbilder(dataSource_mobilder_neu);

                                                                    protokollbildergrid.current.instance.refresh();
                                                                })

                                                        }

                                                        )





                                                    })


                                                })



                                            }}
                                        /> </Item>
                                    <Item>

                                        <DataGrid
                                            dataSource={dataSource_moprotokollbilder}
                                            showBorders={true}
                                            ref={protokollbildergrid}
                                        >
                                            <Editing
                                                mode="row"
                                                allowAdding={false}
                                                allowUpdating={abschluss ? false : true}
                                                allowDeleting={abschluss ? false : true}
                                            />
                                            <Column
                                                dataField="bild_uri"
                                                caption="Bild"
                                                width={400}
                                                cellRender={function (e) {
                                                    return <img src={e.value} />
                                                }}
                                            />
                                            <Column
                                                dataField="bemerkung"
                                                caption="Bemerkung"
                                            />


                                        </DataGrid>

                                    </Item>
                                </Tab>

                                <Tab visible={basf} title="BASF-Kälte">
                                </Tab>
                                <Tab visible={basf} title="BASF-Schadenverdicht">

                                </Tab>
                            </TabbedItem>
                        </Tab>
                        <Tab title="Arbeitsanweisungen" colCount={2}>
                            <DataGrid
                                dataSource={dataSource_arbeitsanweisungen}
                                showBorders={true}
                            >
                                <Editing
                                    mode="row"
                                    allowAdding={false}
                                    allowUpdating={false}
                                    allowDeleting={false}
                                />
                                <Column
                                    dataField="bezeichnung"
                                    caption="Bezeichnung"
                                />

                                <Column type="buttons">
                                    <Button
                                        text="öffnen"
                                        onClick={function (e) {
                                            speichern();
                                            //  e.component.deleteRow(e.row.rowIndex)
                                            //  history.push("/pdf/GetAW/" + e.row.data.dokumentenname.toString());
                                            if (isMobile) {
                                                //history.push("/pdf/GetAW/" + e.row.data.dokumentenname.toString());
                                            } else {
                                                getToken_api().then(token => {
                                                    setToken(token)
                                                    const url = networkConfig.serverEndpoint + "api/moschein/GetAW?file=" + e.row.data.dokumentenname.toString()
                                                    const options2 = {
                                                        headers: {
                                                            'Authorization': `Bearer ${token}`
                                                        }
                                                    };
                                                    fetch(url, options2)
                                                        .then(res => res.blob())
                                                        .then(blob => {
                                                            var file = window.URL.createObjectURL(blob);
                                                            //  window.location.assign(file);
                                                            // Browser.open({ url: file });
                                                            window.open(file, '_blank').focus();
                                                        });
                                                })


                                            }
                                        }}
                                    />
                                </Column>
                            </DataGrid>
                        </Tab>
                        <Tab title="Bilder">
                            <Item>
                                <Button_normal
                                    text="neues Bild"
                                    onClick={function (e) {
                                        const { takePhoto } = useCamera();
                                        takePhoto().then(uri => {


                                            getToken_api().then(token => {
                                                setToken(token)
                                                const data = new FormData()
                                                fetch(uri).then(r => r.blob()).then(blob => {
                                                    data.append('file', blob);
                                                    console.log(data)
                                                    axios.post(networkConfig.serverEndpoint + "api/moschein/UploadBild", data, {
                                                        headers: {
                                                            'Authorization': `Bearer ${token}`
                                                        }
                                                    })
                                                        .then(res => { // then print response status
                                                            console.log(res)
                                                            var dataSource_mobilder_neu = dataSource_mobilder

                                                            dataSource_mobilder_neu.push({ "mobilder_id": res.data.result_set.mobilder_id, "bild_uri": uri });
                                                            setdataSource_mobilder(dataSource_mobilder_neu);
                                                            console.log(dataSource_mobilder_neu)
                                                            bildergrid.current.instance.refresh();
                                                        })

                                                }

                                                )





                                            })


                                        })



                                    }}
                                />
                                <Button_normal
                                    text="neues Bild aus Galerie"
                                    onClick={function (e) {
                                        const { takePhoto } = usePhotoGallery();
                                        takePhoto().then(uri => {


                                            getToken_api().then(token => {
                                                setToken(token)
                                                const data = new FormData()
                                                fetch(uri).then(r => r.blob()).then(blob => {
                                                    data.append('file', blob);
                                                    console.log(data)
                                                    axios.post(networkConfig.serverEndpoint + "api/moschein/UploadBild", data, {
                                                        headers: {
                                                            'Authorization': `Bearer ${token}`
                                                        }
                                                    })
                                                        .then(res => { // then print response status
                                                            console.log(res)
                                                            var dataSource_mobilder_neu = dataSource_mobilder

                                                            dataSource_mobilder_neu.push({ "mobilder_id": res.data.result_set.mobilder_id, "bild_uri": uri });
                                                            setdataSource_mobilder(dataSource_mobilder_neu);
                                                            console.log(dataSource_mobilder_neu)
                                                            bildergrid.current.instance.refresh();
                                                        })

                                                }

                                                )





                                            })


                                        })



                                    }}
                                />
                            </Item>
                            <Item>

                                <DataGrid
                                    dataSource={dataSource_mobilder}
                                    showBorders={true}
                                    ref={bildergrid}
                                >
                                    <Editing
                                        mode="row"
                                        allowAdding={false}
                                        allowUpdating={abschluss ? false : true}
                                        allowDeleting={abschluss ? false : true}
                                    />
                                    <Column
                                        dataField="bild_uri"
                                        caption="Bild"
                                        width={400}
                                        cellRender={function (e) {
                                            return <img src={e.value} />
                                        }}
                                    />
                                    <Column
                                        dataField="bemerkung"
                                        caption="Bemerkung"
                                    />


                                </DataGrid>

                            </Item>

                        </Tab>

                    </TabbedItem>
                </Form>

            </div>
            <Popup
                visible={popup_unterschrift_monteur_visible}
                onHiding={function (e) { setpopup_unterschrift_monteur_visible(false) }}
                dragEnabled={false}
                closeOnOutsideClick={false}
                showCloseButton={true}
                showTitle={false}
                title="Unterschrift"
                width={1060}
                height={600}
            >
                <Position
                    at="center"
                    my="center"
                />
                <ToolbarItem
                    widget="dxButton"
                    toolbar="bottom"
                    location="after"
                    options={{
                        text: 'Speichern',
                        disabled: false,
                        onClick: function (e) {
                            if (sigPad_monteur.isEmpty()) {
                                setformData({ ...formData, unterschrift_monteur: null, datum_unterschrift: null })
                                setunterschrift_monteur_typ("normal")
                                setpopup_unterschrift_monteur_visible(false)
                                setabschluss(false)
                            } else {
                                setformData({ ...formData, unterschrift_monteur: sigPad_monteur.toDataURL("image/svg+xml"), datum_unterschrift: new Date() })
                                setunterschrift_monteur_typ("success")
                                setpopup_unterschrift_monteur_visible(false)
                                setabschluss(true)
                            }
                        }
                    }}
                />
                <table style={{ marginBottom: "30px" }} width="100%">
                    <tbody>
                        <tr>
                            <th>Monteur</th>
                            <th> <Button_normal
                                text="Löschen"
                                icon="clear"
                                onClick={function (e) {
                                    sigPad_monteur.clear();
                                }}

                            /></th>
                        </tr>
                    </tbody>
                </table>
                <SignatureCanvas penColor='blue' backgroundColor='rgba(244, 244, 244)' canvasProps={{ width: 1000, height: 400, className: 'sigCanvas' }} ref={(ref) => { sigPad_monteur = ref }} />
            </Popup>


            <Popup
                visible={popup_unterschrift_kunde_visible}
                onHiding={function (e) { setpopup_unterschrift_kunde_visible(false) }}
                dragEnabled={false}
                closeOnOutsideClick={false}
                showCloseButton={true}
                showTitle={false}
                title="Unterschrift"
                width={1060}
                height={600}
            >
                <Position
                    at="center"
                    my="center"
                />
                <ToolbarItem
                    widget="dxButton"
                    toolbar="bottom"
                    location="after"
                    options={{
                        text: 'Speichern',
                        disabled: false,
                        onClick: function (e) {

                            if (kunde_unterschrift_text.current.instance.option('value') == "") {
                                var result = confirm("Name des Kunden bzw. Abwesenheitsgrund fehlt. MoSchein kann nicht versendet werden. Trotzdem fortfahren?", "Fehlende Angabe")
                                result.then((dialogResult) => {

                                    if (dialogResult == true) {
                                        setformData({ ...formData, unterschrift_name: null, unterschrift_kunde: null, datum_unterschrift: null })
                                        setunterschrift_kunde_typ("normal")
                                        setpopup_unterschrift_kunde_visible(false)
                                        setabschluss(false)

                                    }

                                })

                            } else {
                                setformData({ ...formData, unterschrift_name: kunde_unterschrift_text.current.instance.option('value'), unterschrift_kunde: sigPad_kunde.toDataURL("image/svg+xml"), datum_unterschrift: new Date() })
                                setunterschrift_kunde_typ("success")
                                setpopup_unterschrift_kunde_visible(false)
                                setabschluss(true)
                            }
                        }
                    }}
                />
                <table style={{ marginBottom: "30px" }} width="100%">
                    <tbody>
                        <tr>
                            <th><TextBox ref={kunde_unterschrift_text} placeholder="Name des Kunden oder Abwesenheitsgrund" /></th>
                            <th> <Button_normal
                                text="Löschen"
                                icon="clear"
                                onClick={function (e) {
                                    kunde_unterschrift_text.current.instance.option('value', '')
                                    sigPad_kunde.clear();
                                }}

                            /></th>
                        </tr>
                    </tbody>
                </table>
                <SignatureCanvas penColor='blue' backgroundColor='rgba(244, 244, 244)' canvasProps={{ width: 1000, height: 400, className: 'sigCanvas' }} ref={(ref) => { sigPad_kunde = ref }} />
            </Popup>

            <Popup
                visible={popup_moschein_historie}
                onHiding={function (e) { setpopup_moschein_historie(false) }}
                dragEnabled={false}
                closeOnOutsideClick={true}
                showCloseButton={true}
                showTitle={false}
              
                width={1060}
                height={600}
            >

                <Position
                    at="center"
                    my="center"
                />
                <ToolbarItem
                    widget="dxButton"
                    toolbar="bottom"
                    location="after"
                    options={{
                        text: 'Schließen',
                        disabled: false,
                        onClick: function (e) {
                            setpopup_moschein_historie(false);
                        }
                    }}
                />
                <DataGrid
                    dataSource={dataSource_moschein_historie}
                    showBorders={true}
                    height={500}
                >
                    <Editing

                        allowAdding={false}
                        allowUpdating={false}
                        allowDeleting={false}
                    />
                    <Column
                        dataField="auftragsdatum"
                        caption="Auftragsdatum"
                        width="15%"
                        sortIndex={0}
                        sortOrder="desc"
                    />

                    <Column
                        dataField="name_monteur"
                        caption="Monteur"
                        width="15%"
                    />

                    <Column
                        dataField="arbeitsbericht"
                        caption="Arbeitsbericht"
                        width="55%"
                    />

                    <Column
                        dataField="leistung_beendet"
                        caption="Leistung beendet"
                        width="10%"
                    />
                    <Column type="buttons">
                        <Button
                            text="PDF anzeigen"
                            onClick={function (e) {

                                if (isMobile) {
                                    //history.push("/pdf/GetAW/" + e.row.data.dokumentenname.toString());
                                } else {
                                    getToken_api().then(token => {
                                        setToken(token)
                                        const url = networkConfig.serverEndpoint + "api/moschein/GetMoschein?file=" + e.row.data.moschein_nr.toString()
                                        const options2 = {
                                            headers: {
                                                'Authorization': `Bearer ${token}`
                                            }
                                        };
                                        fetch(url, options2)
                                            .then(res => res.blob())
                                            .then(blob => {
                                                var file = window.URL.createObjectURL(blob);
                                                //  window.location.assign(file);
                                                // Browser.open({ url: file });
                                                window.open(file, '_blank').focus();
                                            });
                                    })
                                }
                        
                            }}
                        />
                    </Column>


                </DataGrid>
            </Popup>




        </React.Fragment>
    );
};
