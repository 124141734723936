export const wegzeiten = [{ "min": 0, "anzeige": "0 min" },
{ "min": 15, "anzeige": "15 min" },
{ "min": 30, "anzeige": "30 min" },
{ "min": 45, "anzeige": "45 min" },
{ "min": 60, "anzeige": "60 min" },
{ "min": 75, "anzeige": "75 min / 1 h 15 min" },
{ "min": 90, "anzeige": "90 min / 1 h 30 min" },
{ "min": 105, "anzeige": "105 min / 1 h 45 min" },
{ "min": 120, "anzeige": "120 min / 2 h" },
{ "min": 135, "anzeige": "135 min / 2 h 15 min" },
{ "min": 150, "anzeige": "150 min / 2 h 30 min" },
{ "min": 165, "anzeige": "165 min / 2 h 45 min" },
{ "min": 180, "anzeige": "180 min / 3 h" },
{ "min": 195, "anzeige": "195 min / 3 h 15 min" },
{ "min": 210, "anzeige": "210 min / 3 h 30 min" },
{ "min": 225, "anzeige": "225 min / 3 h 45 min" },
{ "min": 240, "anzeige": "240 min / 4 h" },
{ "min": 255, "anzeige": "255 min / 4 h 15 min" },
{ "min": 270, "anzeige": "270 min / 4 h 30 min" },
{ "min": 285, "anzeige": "285 min / 4 h 45 min" },
{ "min": 300, "anzeige": "300 min / 5 h" },
{ "min": 315, "anzeige": "315 min / 5 h 15 min" },
{ "min": 330, "anzeige": "330 min / 5 h 30 min" },
{ "min": 345, "anzeige": "345 min / 5 h 45 min" },
{ "min": 360, "anzeige": "360 min / 6 h" },
{ "min": 375, "anzeige": "375 min / 6 h 15 min" },
{ "min": 390, "anzeige": "390 min / 6 h 30 min" },
{ "min": 405, "anzeige": "405 min / 6 h 45 min" },
{ "min": 420, "anzeige": "420 min / 7 h" },
{ "min": 435, "anzeige": "435 min / 7 h 15 min" },
{ "min": 450, "anzeige": "450 min / 7 h 30 min" },
{ "min": 465, "anzeige": "465 min / 7 h 45 min" },
{ "min": 480, "anzeige": "480 min / 8 h" }];