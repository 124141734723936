import React, { useState, useEffect, useRef } from 'react';
import { useHistory } from "react-router-dom";
import { getToken_api, getToken_user } from '../../api/auth';

import { networkConfig } from '../../networkConfig';
import Form, { Item, GroupItem, Label , SimpleItem } from 'devextreme-react/form';
import { useParams } from 'react-router-dom'
import { confirm } from 'devextreme/ui/dialog';
import { CheckBox, NumberBox, TextArea, TextBox } from 'devextreme-react';
import { Button } from 'devextreme-react/button';
import { Popup, Position, ToolbarItem } from 'devextreme-react/popup';
import SignatureCanvas from 'react-signature-canvas';
import { alert } from "devextreme/ui/dialog"
export default function () {
    let { moschein_id } = useParams()
    const [popup_unterschrift_visible, setpopup_unterschrift_visible] = useState(false)
    const [formData, setformData] = useState({})
    const [moscheindaten, setmoscheindaten] = useState({})
    const [daten_wartung_vdma_details, setdaten_wartung_vdma_details] = useState('')
    const history = useHistory();
    var sigPad = {};
    var maengel = {};
    useEffect(() => {
        var moschein = JSON.parse(localStorage.getItem("moschein:" + moschein_id))
        if (localStorage.getItem("dhp") == undefined) {
           setformData({ kundenname: moschein.moschein.kunden_name, kundennummer: moschein.moschein.kundennummer, datumpruefung: new Date() })
        } else {
            setformData(JSON.parse(localStorage.getItem("dhp")))
        }



    }, []);
    var kaeltemittelliste = [
        "R12",
        "R1234yf",
        "R1234ze",
        "R134a",
        "R22",
        "R290 (Propan)",
        "R32",
        "R401A",
        "R402A",
        "R404A",
        "R407A",
        "R407C",
        "R407F",
        "R410A",
        "R413A",
        "R417A",
        "R417B",
        "R422A",
        "R422D",
        "R437A",
        "R444B",
        "R447B",
        "R448A",
        "R449A",
        "R450A",
        "R452A",
        "R452B",
        "R454B",
        "R502",
        "R507A",
        "R513A",
        "R600a (Isobutan)",
        "R717 (NH3)",
        "R723",
        "R744 (CO2)"

    ];
    var kaeltemittelliste_gwp = [
        "2400",
        "4",
        "7",
        "1430",
        "1810",
        "3",
        "675",
        "1100",
        "2600",
        "3922",
        "2107",
        "1774",
        "1825",
        "2088",
        "2418",
        "2346",
        "2920",
        "3145",
        "2729",
        "1680",
        "295",
        "714",
        "1386",
        "1397",
        "547",
        "2140",
        "676",
        "467",
        "4657",
        "3985",
        "573",
        "3",
        "0",
        "8",
        "1"

    ];
    var kaeltemittelliste_merkmal = [
        "synthetisch",
        "synthetisch",
        "synthetisch",
        "synthetisch",
        "synthetisch",
        "natuerlich",
        "synthetisch",
        "synthetisch",
        "synthetisch",
        "synthetisch",
        "synthetisch",
        "synthetisch",
        "synthetisch",
        "synthetisch",
        "synthetisch",
        "synthetisch",
        "synthetisch",
        "synthetisch",
        "synthetisch",
        "synthetisch",
        "synthetisch",
        "synthetisch",
        "synthetisch",
        "synthetisch",
        "synthetisch",
        "synthetisch",
        "synthetisch",
        "synthetisch",
        "synthetisch",
        "synthetisch",
        "synthetisch",
        "natuerlich",
        "natuerlich",
        "natuerlich",
        "natuerlich"
    ];
    var berechnen = function () {

        var gwp = formData.gwp;

        var merkmal = kaeltemittelliste_merkmal[kaeltemittelliste.indexOf(formData.kaeltemittel)]

        var fuellmenge = formData.fuellgewicht;
        var co2 = (fuellmenge * gwp / 1000)
        var pruefungsdatum = new Date(formData.datumpruefung);
        var monate
        var hinweis
        console.log(co2)
    
        if (co2 != 0) {

            if (merkmal == "synthetisch") {

                if (co2 >= 500) {
                    monate = 3;
                    hinweis = "Dichtheitsprüfung bei >500t CO2-ÄQ alle 3 Monate."
                }
                else if (co2 >= 50) {
                    monate = 6;
                    hinweis = "Dichtheitsprüfung bei >50t CO2-ÄQ alle 6 Monate."
                }
                else if (co2 >= 5) {
                    monate = 12;
                    hinweis = "Dichtheitsprüfung bei >5t CO2-ÄQ alle 12 Monate."
                }
                else {
                    monate = 0;
                    hinweis = "Keine regelmäßige Dichtheitsprüfung bei <5t CO2-ÄQ vorgeschrieben"
                };

            };
            if (merkmal == "natuerlich") {
                if (fuellmenge >= 300) {
                    monate = 3;
                    hinweis = "Dichtheitsprüfung bei >300kg Fuellmenge alle 3 Monate."
                }
                else if (fuellmenge >= 30) {
                    monate = 6;
                    hinweis = "Dichtheitsprüfung bei >30kg Fuellmenge alle 6 Monate."
                }
                else if (fuellmenge >= 3) {
                    monate = 12;
                    hinweis = "Dichtheitsprüfung bei >3kg Fuellmenge alle 12 Monate."
                }
                else {
                    monate = 0;
                    hinweis = "Keine regelmäßige Dichtheitsprüfung bei <3kg Fuellmenge vorgeschrieben."
                };

            };

            if (monate != 0 && monate != null) {
                if (pruefungsdatum != null){
                var months = pruefungsdatum.getMonth() + monate

                    setformData({ ...formData, naechstepruefung:new Date(pruefungsdatum.setMonth(months)), hinweis: hinweis, co2aequivalent: co2 })
       
                }
                    }else{
                setformData({ ...formData, co2aequivalent: co2, hinweis: hinweis })
            }
          


        };


    }
    const speichern = function () {

        var moscheindaten = localStorage.getItem("moschein:" + moschein_id)
        moscheindaten = JSON.parse(moscheindaten);
        var data = formData
        data.unterschrift = sigPad.toDataURL("image/svg+xml")
        data.datum = new Date();
data.naechstepruefung = new Date(data.naechstepruefung);
        moscheindaten.dhp.push(data)
        localStorage.setItem("moschein:" + moschein_id, JSON.stringify(moscheindaten))
        localStorage.removeItem("dhp")
        history.push("/moschein/bearbeitenmobil/" + moschein_id);
    }

    const form = useRef(null);

    return (

        <React.Fragment>


            <div className="dx-toolbar-items-container" style={{ height: "60px" }}>
                <div className="dx-toolbar-before">
                    <h2 className={'content-block'}>DHP</h2>
                </div>
                <div className="dx-toolbar-after" style={{ "paddingTop": "20px", "paddingRight": "40px" }}>
                    <Button
                        text="Unterschreiben"
                        icon="sign"
                        onClick={function (e) {
                            //prüfen ob bei nicht in Ordnung auch bemerkung vorhanden ist

                            setpopup_unterschrift_visible(true)
                        }}
                    />
                </div>
            </div>


            <div className={'content-block dx-card responsive-paddings'}>
                <Form
                    id={'form'}
                    formData={formData}
                    labelLocation={'left'}
                    colCount={2}
                    ref={form}
                >
                    
                    <GroupItem caption="Angaben zum Kunden" >
                        <Item dataField="kundenname" editorOptions={{}}>
                            <Label text="Kundenname" />
                        </Item>
                        <Item dataField="kundennummer" editorOptions={{}}>
                            <Label text="Kundennummer" />
                        </Item>
                    </GroupItem>
                    <GroupItem caption="Angaben zum Projekt" >
                        <Item dataField="betriebsort" editorOptions={{}}>
                            <Label text="Betriebsort" />
                        </Item>
                        <Item dataField="bezeichnung" editorOptions={{}}>
                            <Label text="Bezeichnung" />
                        </Item>
                        <Item dataField="maschinen_baugruppen_nr" editorOptions={{}}>
                            <Label text="Maschinen-Baugruppen-Nr" />
                        </Item>
                        <Item dataField="mobilec" editorOptions={{}}>
                            <Label text="MobiLec/VDKFLec-Nr." />
                        </Item>
                    </GroupItem>
                    <GroupItem caption="Technische Daten" >
                        <Item dataField="kaeltemittel" editorType="dxSelectBox" editorOptions={{
                            items: kaeltemittelliste,
                            acceptCustomValue: true,
                            searchEnabled: true,
                            onValueChanged: function (data) {
                                var index = kaeltemittelliste.indexOf(data.value);
                                setformData({ ...formData, gwp: kaeltemittelliste_gwp[index] })

                            },
                            onCustomItemCreating: function (data) {
                                var newItem = data.text;
                                kaeltemittelliste.push(newItem);
                                form.current.instance.getEditor('kaeltemittel').option("items", kaeltemittelliste);
                                return newItem;
                            },
                        }}>
                            <Label text="Kältemittel" />
                        </Item>
                        <Item dataField="gwp" editorType="dxNumberBox" editorOptions={{
                            onValueChanged: function (data) {
                                berechnen();
                            }
                        }}>
                            <Label text="GWP-Wert" />
                        </Item>
                        <Item dataField="fuellgewicht" editorType="dxNumberBox" editorOptions={{
                            onValueChanged: function (data) {
                                berechnen();
                            }
                        }}>
                            <Label text="Füllgewicht (in kg)" />
                        </Item>
                        <Item dataField="co2aequivalent" editorType="dxNumberBox" editorOptions={{
                        }}>
                            <Label text="CO2-Äquvivalent (in t)" />
                        </Item>
                        <Item dataField="betriebsdruck_hd" editorType="dxNumberBox" editorOptions={{}}>
                            <Label text="Max. Betriebsdruck HD-Seite (PS Anlage) (in bar)" />
                        </Item>
                        <Item dataField="betriebsdruck_nd" editorType="dxNumberBox" editorOptions={{}}>
                            <Label text="Max. Betriebsdruck ND-Seite (PS Anlage) (in bar)" />
                        </Item>
                    </GroupItem>

                    <GroupItem caption="Prüfung" >
                        <Item dataField="datumpruefung" editorType="dxDateBox" editorOptions={{
                            format: "date",

                            displayFormat: 'monthAndYear',
                            maxZoomLevel: 'year',
                            minZoomLevel: 'century',
                            onValueChanged: function (data) {
                                berechnen();
                            }
                        }}>
                            <Label text="Prüfung" />
                        </Item>
                        <Item dataField="hinweis" editorOptions={{
                        }}>
                            <Label text="Hinweis" />
                        </Item>
                        <Item dataField="naechstepruefung" editorType="dxDateBox" editorOptions={{
                            format: "date",

                            displayFormat: 'monthAndYear',
                            maxZoomLevel: 'year',
                            minZoomLevel: 'century',
                        }}>
                            <Label text="Nächste Prüfung am" />
                        </Item>
                        <Item dataField="pruefung_leckage" editorType="dxSelectBox" editorOptions={{
                            items: ["durchgeführt", "nicht durchgeführt", "nicht vorhanden"]

                        }}>
                            <Label text="Funktionsprüfung Leckageerkennungssytem" />
                        </Item>
                        <Item dataField="maengel_erkannt" editorType="dxCheckBox" editorOptions={{
                        }}>
                            <Label text="keine Mängel erkannt" />
                        </Item>
                        <Item dataField="maengel_behoben" editorType="dxCheckBox" editorOptions={{
                        }}>
                            <Label text="Mängel laut MoSchein behoben" />
                        </Item>

                    </GroupItem>

                </Form>
            </div>
            <Popup
                visible={popup_unterschrift_visible}
                onHiding={function (e) { setpopup_unterschrift_visible(false) }}
                dragEnabled={false}
                closeOnOutsideClick={true}
                showCloseButton={true}
                showTitle={false}
                title="Unterschrift"
                width={1060}
                height={700}
            >
                <Position
                    at="center"
                    my="center"
                />
                <ToolbarItem
                    widget="dxButton"
                    toolbar="bottom"
                    location="after"
                    options={{
                        text: 'Speichern',
                        disabled: false,
                        onClick: function (e) {

                            speichern()
                            setpopup_unterschrift_visible(false)

                        }
                    }}
                />
                <table style={{ marginBottom: "30px" }} width="100%">
                    <tbody>

                        <tr>
                            <th></th>
                            <th> <Button
                                text="Löschen"
                                icon="clear"
                                onClick={function (e) {
                                    maengel.current.instance.option('value', '')
                                    sigPad.clear();
                                }}

                            /></th>
                        </tr>
                    </tbody>
                </table>
                <SignatureCanvas penColor='blue' backgroundColor='rgba(244, 244, 244)' canvasProps={{ width: 1000, height: 400, className: 'sigCanvas' }} ref={(ref) => { sigPad = ref }} />
            </Popup>
        </React.Fragment>
    );


};
