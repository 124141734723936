import React, { useState, useEffect } from 'react';
import { useHistory } from "react-router-dom";
import { getToken_api } from '../../api/auth';
import DataGrid, { Column, Grouping, FilterRow, SearchPanel, HeaderFilter, Editing, Lookup, Sorting } from 'devextreme-react/data-grid';
import { Button } from 'devextreme-react/button';
import { networkConfig } from '../../networkConfig';
import ODataStore from 'devextreme/data/odata/store';
import { Popup, Position, ToolbarItem } from 'devextreme-react/popup';
import Form, { Item, TabbedItem, TabPanelOptions, Tab, Label, GroupItem } from 'devextreme-react/form';
import ActionSheet from 'devextreme-react/action-sheet';
import notify from 'devextreme/ui/notify';
import Guid from 'devextreme/core/guid';
export default function () {

    const [token, setToken] = useState('')
    const [dataSource, setdataSource] = useState('')
    const [dataSource_deb, setdataSource_deb] = useState('')
    const [popup_details_visible, setpopup_details_visible] = useState(false)
    const [popup_moschein_visible, setpopup_moschein_visible] = useState(false)
    const [popup_details_datasource, setpopup_details_datasource] = useState({})
    const [popup_moschein_datasource, setpopup_moschein_datasource] = useState({})
    const [status_visible, setstatus_visible] = useState(false)
    const [status_target, setstatus_target] = useState()
    const [status_items, setstatus_items] = useState([])

    const history = useHistory();

    useEffect(() => {

        getToken_api().then(token => {

            setToken(token);
            const dataSource_load = new ODataStore({
                type: 'odata',
                url: networkConfig.dataEndpoint + 'moschein_tourauftrag_view',
                key: 'tourauftrag_id',
                withCredentials: true,
                beforeSend: function (e) {

                    e.headers = {
                        "Authorization": 'Bearer ' + token
                    };
                }
            });
            dataSource_load.load({})
                .then(
                    (data) => {
                        console.log(data)
                        setdataSource(data);
                        localStorage.setItem('moschein_liste', JSON.stringify(data))
                    },
                    (error) => {
                        console.log(error)
                        var data = JSON.parse(localStorage.getItem('moschein_liste'))
                        console.log(data)
                        setdataSource(data)
                    }
                );



        })

    }, []);

    const moschein_oeffnen = function (e) {

        if (localStorage.getItem('moschein:' + e.data.Moschein_id) != undefined) {
            history.push("/moschein/bearbeitenmobil/" + e.data.Moschein_id);
        } else {
            const headers = new Headers();
            const bearer = `Bearer ${token}`;
            headers.append("Authorization", bearer);
            const options = {
                method: "GET",
                headers: headers,
            };

            return fetch(networkConfig.serverEndpoint + "api/moschein/GetMoschein_Tablet?moschein_id=" + e.data.Moschein_id, options)
                .then(response => response.json())
                .then(data => {
                    if (data.success == true) {
                        data.result_set.moschein.tourauftrag_id = new Guid(e.data.Tourauftrag_id);
                        localStorage.setItem('moschein:' + e.data.Moschein_id, JSON.stringify(data.result_set))
                        history.push("/moschein/bearbeitenmobil/" + e.data.Moschein_id);
                    } else {
                        if (localStorage.getItem('moschein:' + e.data.Moschein_id) != undefined) {
                            notify({
                                message: 'OFFLINE-MODUS',
                            }, 'success', 5000);
                            history.push("/moschein/bearbeitenmobil/" + e.data.Moschein_id);
                        }
                        notify({
                            message: 'MoSchein kann nicht gefunden werden',
                        }, 'error', 5000);
                    }

                }
                )
                .catch(error => {
                    console.log(error)
                    if (localStorage.getItem('moschein:' + e.data.Moschein_id) != undefined) {
                        notify({
                            message: 'OFFLINE-MODUS',
                        }, 'success', 5000);
                        history.push("/moschein/bearbeitenmobil/" + e.data.Moschein_id);
                    }
                });
        };

    };
    const details_anzeigen = function (e) {
        // history.push("/moschein/bearbeiten/" + e.row.data.moschein_id);
        console.log(e)
        setpopup_details_datasource(e.data)
        setpopup_details_visible(true)
    };
    const status_aendern = function (u, e) {
        // history.push("/moschein/bearbeiten/" + e.row.data.moschein_id);
        console.log(e)
        var neuesdatum = new Date()
        if (e.data.ErwBeginn.getDate() == neuesdatum.getDate()){
            switch (e.data.Statecode) {
                case 1:
                    setstatus_items([{ "text": "Anfahrt", "id": 4, "tourauftrag_id": e.data.Tourauftrag_id, "auftrag_id": e.data.auftrag_id, "moschein_id": e.data.moschein_id }])
                    break;
                case 2:
                    setstatus_items([{ "text": "Anfahrt", "id": 4, "tourauftrag_id": e.data.Tourauftrag_id, "auftrag_id": e.data.auftrag_id, "moschein_id": e.data.moschein_id }, { "text": "Abgelehnt", "id": 8, "tourauftrag_id": e.data.Tourauftrag_id, "auftrag_id": e.data.auftrag_id, "moschein_id": e.data.moschein_id }])
                    break;
                case 3:
                    setstatus_items([{ "text": "Anfahrt", "id": 4, "tourauftrag_id": e.data.Tourauftrag_id, "auftrag_id": e.data.auftrag_id, "moschein_id": e.data.moschein_id }, { "text": "Abgelehnt", "id": 8, "tourauftrag_id": e.data.Tourauftrag_id, "auftrag_id": e.data.auftrag_id, "moschein_id": e.data.moschein_id }])
                    break;
                case 4:
                    setstatus_items([{ "text": "Ankunft", "id": 5, "tourauftrag_id": e.data.Tourauftrag_id, "auftrag_id": e.data.auftrag_id, "moschein_id": e.data.moschein_id }, { "text": "Abgebrochen", "id": 9, "tourauftrag_id": e.data.Tourauftrag_id, "auftrag_id": e.data.auftrag_id, "moschein_id": e.data.moschein_id }, { "text": "Kunde abwesend", "id": 7, "tourauftrag_id": e.data.Tourauftrag_id, "auftrag_id": e.data.auftrag_id, "moschein_id": e.data.moschein_id }])
                    break;
                case 5:
                    setstatus_items([{ "text": "Erledigt", "id": 6, "tourauftrag_id": e.data.Tourauftrag_id, "auftrag_id": e.data.auftrag_id, "moschein_id": e.data.moschein_id }, { "text": "Teilerledigt", "id": 10, "tourauftrag_id": e.data.Tourauftrag_id, "auftrag_id": e.data.auftrag_id, "moschein_id": e.data.moschein_id }, { "text": "Kunde abwesend", "id": 7, "tourauftrag_id": e.data.Tourauftrag_id, "auftrag_id": e.data.auftrag_id, "moschein_id": e.data.moschein_id }, { "text": "Abgebrochen", "id": 9, "tourauftrag_id": e.data.Tourauftrag_id, "auftrag_id": e.data.auftrag_id, "moschein_id": e.data.moschein_id }])
                    break;
                default:
                    setstatus_items([])
                    break;
    
            }
    
        }else{
            setstatus_items([{ "text": "Abgelehnt", "id": 8, "tourauftrag_id": e.data.Tourauftrag_id, "auftrag_id": e.data.auftrag_id, "moschein_id": e.data.moschein_id }])
                  
        }
      
        setstatus_target(u.element)
        setstatus_visible(true)

        //popup öffnen mit tourauftrag_id und statecode
    };
    const moschein_loeschen = function (e) {
        var tourauftrag = { "moschein_id": e.data.Moschein_id }
        const headers = new Headers();
        const bearer = `Bearer ${token}`;
        headers.append("Authorization", bearer);
        headers.append("Content-Type", "application/json;charset=UTF-8")
        const options = {
            method: "POST",
            headers: headers,
            body: JSON.stringify(tourauftrag)
        };
        console.log(tourauftrag)
        return fetch(networkConfig.serverEndpoint + "api/moschein/DeleteMoschein", options)
            .then(response => response.json())
            .then(data => {
                if (data.success == true) {
                    notify({
                        message: 'Moschein wurde erfolgreich entfernt',

                    }, 'success', 5000);
                    const dataSource_load = new ODataStore({
                        type: 'odata',
                        url: networkConfig.dataEndpoint + 'moschein_tourauftrag_view',
                        key: 'tourauftrag_id',
                        withCredentials: true,
                        beforeSend: function (e) {

                            e.headers = {
                                "Authorization": 'Bearer ' + token
                            };
                        }
                    });
                    dataSource_load.load({})
                        .then(
                            (data) => {
                                console.log(data)
                                setdataSource(data);
                                localStorage.setItem('moschein_liste', JSON.stringify(data))
                            },
                            (error) => {
                                console.log(error)
                                var data = JSON.parse(localStorage.getItem('moschein_liste'))
                                console.log(data)
                                setdataSource(data)
                            }
                        );
                } else {
                    notify({
                        message: 'Fehler :(',
                    }, 'error', 5000);
                }

            }
            )
            .catch(error => console.log(error));

    };

    const dataRow = function (e) {

        var data = e.data;
        var status = '';
        var moschein_vorhanden = false;
        var selbsterstellt = false;
        if (data.Statecode == 1) {
            status = 'Erfasst'
        }
        if (data.Statecode == 2) {
            status = 'Erstellt'
        }
        if (data.Statecode == 3) {
            status = 'Geplant'
        }
        if (data.Statecode == 4) {
            status = 'Anfahrt'
        }
        if (data.Statecode == 5) {
            status = 'Ankunft'
        }
        if (data.Statecode == 6) {
            status = 'Erledigt'
        }
        if (data.Statecode == 10) {
            status = 'Teilerledigt'
        }
        if (data.Statecode == 7) {
            status = 'Kunde abwesend'
        }
        if (data.Statecode == 8) {
            status = 'Abgelehnt'
        }
        if (data.Statecode == 9) {
            status = 'Abgebrochen'
        }
        if (data.Moschein_id != null) {
            moschein_vorhanden = true
        }
        if (data.Status == "selbsterstellt") {
            selbsterstellt = true;
            status = "selbsterstellt"
        }
        if (e.data.ErwBeginn) {
            e.data.ErwBeginn = new Date(e.data.ErwBeginn)
        }
        if (e.data.ErwEnde) {
            e.data.ErwEnde = new Date(e.data.ErwEnde)
        }
        return (<tbody>
            <tr>
                <td colSpan='6'><b>{e.data.Auftragsnr}</b></td>
            </tr>
            <tr>
                <td>{e.data.Name}</td>
                <td>{e.data.Strasse1}</td>
                <td>{e.data.Plz} {e.data.Stadt}</td>
                <td>  <Button
                    text="Details"
                    visible={selbsterstellt ? false : true}
                    onClick={function (u) {
                        details_anzeigen(e)
                    }}
                /><Button
                        text="löschen"
                        visible={selbsterstellt}
                        onClick={function (u) {
                            moschein_loeschen(e)
                        }}
                    /></td>
                <td> <Button
                    text="Status"
                    visible={selbsterstellt ? false : true}
                    onClick={function (u) {
                        status_aendern(u, e)
                    }}
                /></td>
                <td> <Button
                    text="öffnen"
                    disabled={moschein_vorhanden ? false : true}
                    onClick={function (u) {
                        moschein_oeffnen(e)
                    }}
                /></td>
            </tr>
            <tr>
                <td>{e.data.Moschein_nr}</td>
                <td>{e.data.ErwBeginn && e.data.ErwBeginn.toLocaleDateString()} {e.data.ErwBeginn && e.data.ErwBeginn.toLocaleTimeString()} : {e.data.ErwEnde && e.data.ErwEnde.toLocaleDateString()} {e.data.ErwEnde && e.data.ErwEnde.toLocaleTimeString()} </td>
                <td colSpan='4'>{status}</td>
            </tr>
        </tbody>
        )
    }

    const status_visible_changed = function (e) {
        if (e !== status_visible) {
            setstatus_visible(e)
        }
    }
    const status_click = function (e) {
        setstatus_visible(false)

        var tourauftrag = { "tourauftrag_id": e.itemData.tourauftrag_id, "statecode": e.itemData.id, "auftrag_id": e.itemData.auftrag_id }
        const headers = new Headers();
        const bearer = `Bearer ${token}`;
        headers.append("Authorization", bearer);
        headers.append("Content-Type", "application/json;charset=UTF-8")
        const options = {
            method: "POST",
            headers: headers,
            body: JSON.stringify(tourauftrag)
        };
        console.log(tourauftrag)
        return fetch(networkConfig.serverEndpoint + "api/moschein/UpdateTourauftrag", options)
            .then(response => response.json())
            .then(data => {
                if (data.success == true) {
                    notify({
                        message: 'Status wurde erfolgreich geändert',

                    }, 'success', 5000);
                    const dataSource_load = new ODataStore({
                        type: 'odata',
                        url: networkConfig.dataEndpoint + 'moschein_tourauftrag_view',
                        key: 'tourauftrag_id',
                        withCredentials: true,
                        beforeSend: function (e) {

                            e.headers = {
                                "Authorization": 'Bearer ' + token
                            };
                        }
                    });
                    dataSource_load.load({})
                        .then(
                            (data) => {
                                console.log(data)
                                setdataSource(data);
                                localStorage.setItem('moschein_liste', JSON.stringify(data))
                            },
                            (error) => {
                                console.log(error)
                                var data = JSON.parse(localStorage.getItem('moschein_liste'))
                                console.log(data)
                                setdataSource(data)
                            }
                        );
                } else {
                    notify({
                        message: 'Fehler :(',
                    }, 'error', 5000);
                }

            }
            )
            .catch(error => console.log(error));


    }
    return (

        <React.Fragment>
            <div className="dx-toolbar-items-container" style={{ height: "60px" }}>

                <div className="dx-toolbar-before">
                    <h2 className={'content-block'}>MoSchein/Tourauftrag Übersicht</h2>
                </div>
                <div className="dx-toolbar-after" style={{ "paddingTop": "20px", "paddingRight": "40px" }}>


                    <Button
                        text="Neuer MoSchein"
                        icon="add"
                        type='normal'
                        onClick={function (e) {
                            setpopup_moschein_visible(true)
                        }}
                    />

                </div>




            </div>
            <div>

                <DataGrid

                    dataSource={dataSource}
                    showBorders={true}
                    rowRender={dataRow}
                >

                    <Editing

                        allowUpdating={false}
                        allowDeleting={false}
                        allowAdding={false}
                    >

                    </Editing>

                    <Column
                        dataField="erwBeginn"
                        defaultSortOrder="asc"
                        width="25%"
                    />
                    <Column
                        width="25%"
                    />
                    <Column
                        width="15%"
                    />
                    <Column
                        width="10%" alignment="center"
                    />
                    <Column
                        width="10%" alignment="center"
                    />
                    <Column
                        width="10%" alignment="center"
                    />

                </DataGrid>

                <Popup
                    visible={popup_details_visible}
                    onHiding={function (e) { setpopup_details_visible(false) }}
                    dragEnabled={false}
                    closeOnOutsideClick={true}
                    showCloseButton={true}
                    showTitle={true}
                    title="Details"
                    width={1000}
                    height={600}
                >
                    <Position
                        at="center"
                        my="center"

                    />


                    <Form
                        id={'form'}
                        formData={popup_details_datasource}
                        labelLocation={'left'}
                        readOnly={true}
                        colCount={3}
                    >
                        <Item dataField="Tourauftrag_nr" colSpan={1}>
                            <Label text="Nr" />
                        </Item>

                        <Item dataField="Name" colSpan={2}>
                            <Label text="Name" />
                        </Item>
                        <Item dataField="stoermelder_tel" >
                            <Label text="Telefon" />
                        </Item>
                        <Item dataField="Beginn" editorType="dxDateBox" colSpan={1} editorOptions={{}}>
                            <Label text="Beginn" />
                        </Item>
                        <Item dataField="erwDauer" editorType="dxNumberBox" colSpan={1} editorOptions={{ format: '#0min' }} >
                            <Label text="erw. Dauer" />
                        </Item>
                        <Item dataField="Strasse1">
                            <Label text="Strasse" />
                        </Item>
                        <Item dataField="plz" >
                            <Label text="PLZ" />
                        </Item>

                        <Item dataField="Stadt" >
                            <Label text="Stadt" />
                        </Item>
                        <Item dataField="extTicketnr">
                            <Label text="ext. TicketNr." />
                        </Item>
                        <Item dataField="extAuftragsnr">
                            <Label text="ext. AuftragsNr." />
                        </Item>
                        <Item dataField="kostentraeger" colSpan={3} >
                            <Label text="Konstenträger" />
                        </Item>
                        <Item dataField="Bemerkung" editorType={"dxTextArea"} colSpan={3} >
                            <Label text="Bemerkung" />
                        </Item>
                        <Item dataField="fehlerbeschreibung" editorType={"dxTextArea"} colSpan={3} >
                            <Label text="Fehlerbeschreibung" />
                        </Item>
                    </Form>

                </Popup>
                <Popup
                    visible={popup_moschein_visible}
                    onHiding={function (e) { setpopup_moschein_visible(false) }}
                    dragEnabled={false}
                    closeOnOutsideClick={true}
                    showCloseButton={true}
                    showTitle={true}
                    title="Neuer MoSchein"
                    width={500}
                    height={200}
                >
                    <Position
                        at="center"
                        my="center"
                    />
                    <ToolbarItem
                        widget="dxButton"
                        toolbar="bottom"
                        location="after"
                        options={{
                            text: 'MoSchein erstellen',
                            disabled: false,
                            onClick: function (e) {
                                const headers = new Headers();
                                const bearer = `Bearer ${token}`;
                                headers.append("Authorization", bearer);
                                headers.append("Content-Type", "application/json;charset=UTF-8")
        
                                const options = {
                                    method: "POST",
                                    headers: headers,
                                    body: JSON.stringify(popup_moschein_datasource)
                                };
                        
                                return fetch(networkConfig.serverEndpoint + "api/moschein/AddMoschein_selbsterstellt", options)
                                    .then(response => response.json())
                                    .then(data => {
                                        if (data.success == true) {
                                            console.log(data)
                                            localStorage.setItem('moschein:' + data.result_set.moschein.moschein_id, JSON.stringify(data.result_set))
                                            history.push("/moschein/bearbeitenmobil/" + data.result_set.moschein.moschein_id);


                                        
                        
                                        } else {
                                            notify({
                                                message: 'Leider wurde der Auftrag nicht gefunden. Bitte überprüfen Sie die Auftragsnummer',
                        
                                            }, 'error', 5000);
                                        }
                        
                                    }
                                    )
                                    .catch(error => console.log(error));
                            }
                        }}
                    />
                    <Form
                
                        formData={popup_moschein_datasource}
                        labelLocation={'left'}
                      
              
                    >
                        <Item dataField="auftrag_nr" colSpan={1}>
                            <Label text="Auftrags-Nr" />
                        </Item>

                    </Form>

                </Popup>
                <ActionSheet
                    title="Status ändern"
                    usePopover={true}
                    visible={status_visible}
                    target={status_target}
                    items={status_items}
                    onItemClick={status_click}
                    onVisibleChange={status_visible_changed}
                />
            </div>
        </React.Fragment>
    );


};
